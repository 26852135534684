import { Box } from "@mui/material";
import { useEffect } from "react";

export const DataTableHeader = ({ column, handleColumnSelect }) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "Enter") {
        handleColumnSelect(e, column);
      }
    };
    const header = document.querySelector(
      `[role="columnheader"][data-field="${column?.headerName}"]`
    );

    header?.addEventListener("keydown", handleKeyDown);
    return () => header?.removeEventListener("keydown", handleKeyDown);
  }, [column, handleColumnSelect]);

  return (
    <Box
      data-testid={`column-${column?.headerName}`}
      sx={{ display: "flex", alignItems: "left", width: "100%" }}
      onClick={(e) => handleColumnSelect(e, column)}
      aria-label={`Column ${column?.headerName}. Press Enter to sort by ascending order. Press Alt + Enter (Windows) or Option + Enter (macOS) to open the column menu. Press Ctrl + Shift + Enter (Windows) or Command + Shift + Enter (macOS) to selec/diselect the column.`}
    >
      <Box sx={{ flexGrow: 1 }}>{column?.headerName}</Box>
    </Box>
  );
};
