import { Img, View } from "@instructure/ui";
import { FC } from "react";
import { PandaOnboardingType } from "../../../types";
import { useLocaleContext } from "../../../../context/locale";

interface PandaOnboardingProps {
  imageSrc: string;
  text: string;
  type: PandaOnboardingType;
}

export const PandaOnboarding: FC<PandaOnboardingProps> = ({ type, imageSrc, text }) => {
  const { isRTL } = useLocaleContext();

  return (
    <div className="flex h-full flex-col">
      <div className="mx-24 my-auto flex">
        <div
          className={
            type === PandaOnboardingType.Initial ? " h-40 w-36 flex-none" : " h-56 w-48 flex-none"
          }
        >
          <Img src={imageSrc} alt="Panda" />
        </div>

        <View position="relative">
          <div
            className={`${type === PandaOnboardingType.Loading ? "mt-3" : ""}
          relative flex grow justify-between rounded-lg border border-[#6B7780]`}
          >
            <div className={`grow text-base`}>
              <div className="p-3">{text}</div>
            </div>
          </div>

          <svg
            width="12"
            height="11.146"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              position: "absolute",
              top: type === PandaOnboardingType.Initial ? "55px" : "80px",
              [isRTL ? "right" : "left"]: "-11px",
              transform: isRTL ? "scaleX(-1)" : "none",
            }}
          >
            <path
              d="M 13.93 2.322 L 13.784 13.468 L 2.637 2.322 L 13.93 2.322 Z"
              stroke={"#6B7780"}
              strokeWidth={1}
              fill={"#fff"}
            />
          </svg>
        </View>
      </div>
    </div>
  );
};
