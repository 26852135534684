import { useQuery } from "@tanstack/react-query";
import { fetchChartData } from "../api/retriever";
import { FetchChartDataParams } from "../api/retriever.i";
import { SINGLE_METRIC } from "../Constants/constants";

const useChartQuery = ({
  sqlQuery,
  chartType,
  selectedColumns,
  docId,
  chartConfig,
  parentDocId,
  parentDocType,
}: FetchChartDataParams & { sqlQuery: string }) => {
  return useQuery({
    queryKey: [
      "chartData",
      selectedColumns,
      chartType,
      docId,
      parentDocId,
      parentDocType,
      sqlQuery,
    ],
    queryFn: () => {
      if (!chartConfig || !docId) {
        return Promise.reject(new Error("Invalid query parameters"));
      }
      return fetchChartData({
        docId,
        parentDocId,
        parentDocType,
        chartType,
        selectedColumns,
        chartConfig,
      });
    },
    enabled:
      Boolean(chartConfig) && Boolean(docId) && Boolean(chartType) && Boolean(selectedColumns) && chartType !== SINGLE_METRIC,
  });
};

export { useChartQuery };
