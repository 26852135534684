import React, { memo, useCallback, useMemo } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  Edge,
  Handle,
  MarkerType,
  Position,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";

const CANVAS_SCHEMA_BASE_URL = process.env.REACT_APP_CANVAS_SCHEMA_BASE_URL;
const getCanvasUrl = (tableName: string) => `${CANVAS_SCHEMA_BASE_URL}${tableName}`;

// TableNode Component
type TableNodeProps = {
  data: {
    label: string;
    columns: string[];
  };
};

const TableNode: React.FC<TableNodeProps> = memo(({ data }) => (
  <div style={styles.nodeContainer} data-testid="table-node">
    <div style={{ ...styles.nodeHeader, textAlign: "center" as const }}>
      <a href={getCanvasUrl(data.label)} target="_blank" rel="noopener noreferrer">
        {data.label}
      </a>
    </div>
    <div style={styles.nodeBody}>
      {data.columns.map((column, index) => (
        <div
          key={column}
          style={{
            ...styles.nodeColumn,
            ...(index < data.columns.length - 1 ? styles.nodeColumn : {}),
            textAlign: "left" as const,
          }}
        >
          <span>{column}</span>
          {index === 0 && <span style={styles.pkIndicator}>PK</span>}
        </div>
      ))}
    </div>
    <Handle type="source" position={Position.Right} style={styles.handle} />
    <Handle type="target" position={Position.Left} style={styles.handle} />
  </div>
));

const nodeTypes = {
  tableNode: TableNode,
};

// SQLDiagram Component
type SQLDiagramProps = {
  tables: string[];
  columns: { [key: string]: string[] };
  joins: {
    sourceTable: string;
    sourceColumn: string;
    targetTable: string;
    targetColumn: string;
  }[];
};

const SQLDiagram: React.FC<SQLDiagramProps> = ({ tables, columns, joins }) => {
  const initialNodes = useMemo(
    () =>
      tables.map((table, index) => ({
        id: table,
        type: "tableNode",
        data: {
          label: table,
          columns: columns[table],
        },
        position: { x: index * 300, y: index * 100 },
      })),
    [tables, columns]
  );

  const initialEdges = useMemo(
    () =>
      joins.map((join, index) => ({
        id: `e${index}`,
        source: join.sourceTable,
        target: join.targetTable,
        label: `${join.targetColumn} → ${join.sourceColumn}`,
        type: "smoothstep",
        animated: true,
        style: { stroke: "#4a90e2" },
        markerEnd: { type: MarkerType.ArrowClosed, color: "#4a90e2" },
      })),
    [joins]
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    (params: Edge | Connection) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );
  return (
    <div style={styles.diagramContainer}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        nodesConnectable={false}
        elementsSelectable={false}
        fitView
      >
        <Controls />
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      </ReactFlow>
    </div>
  );
};

export default SQLDiagram;

// Styles
const styles = {
  diagramContainer: {
    width: "100%",
    height: "100%",
  },
  nodeContainer: {
    border: "1px solid #777",
    borderRadius: "5px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    fontSize: "12px",
    fontFamily: "Arial, sans-serif",
  },
  nodeHeader: {
    backgroundColor: "#4a90e2",
    color: "white",
    padding: "8px 10px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    fontWeight: "bold",
    textAlign: "center",
  },
  nodeBody: {
    padding: "10px",
  },
  nodeColumn: {
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 0",
    borderBottom: "1px solid #eee",
  },
  pkIndicator: {
    color: "#888",
    marginLeft: "10px",
  },
  handle: {
    background: "#555",
  },
};
