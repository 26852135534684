import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import { env } from "../../env";

const firebaseConfig = JSON.parse(env.REACT_APP_FIREBASE_CONFIG);

const firebaseApp = initializeApp(firebaseConfig);

const db =
  env.REACT_APP_ENV === "core-prod"
    ? initializeFirestore(firebaseApp, { experimentalForceLongPolling: true })
    : getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const auth = getAuth(firebaseApp);
const analytics = getAnalytics(firebaseApp);

export { analytics, auth, db, firebaseApp, storage };
