import { Checkbox, List } from "@instructure/ui";

interface PinboardListProps {
  boards: Map<string, any>;
  selectedBoards: string[];
  setSelectedBoards: any;
  // setSelectedBoards: Dispatch<SetStateAction<(keyof Board)[]>>;
}

export const PinboardList = ({ boards, selectedBoards, setSelectedBoards }: PinboardListProps) => {
  const handleMenuItemClick = (key: string) => {
    setSelectedBoards((prevSelectedBoards: any) => {
      if (selectedBoards.includes(key)) {
        return prevSelectedBoards.filter((selectedKey: any) => selectedKey !== key);
      }

      return [...prevSelectedBoards, key];
    });
  };

  return (
    <div className="mt-6 h-[400px] overflow-auto chat-desktop:mt-auto chat-desktop:h-auto chat-desktop:overflow-visible">
      <List isUnstyled itemSpacing="small">
        {Array.from(boards.keys()).map((key, index) => (
          <List.Item key={index} data-testid={`pinboard-${boards.get(key).name}`}>
            <Checkbox
              label={boards.get(key).name}
              value="medium"
              checked={selectedBoards.includes(key)}
              onClick={() => handleMenuItemClick(key)}
              color="primary"
            />
          </List.Item>
        ))}
      </List>
    </div>
  );
};
