import { DateTime } from "luxon";

import { MutableRefObject } from "react";
import { Doowii } from "../../../../../../../search/Doowii";
import { TextArea } from "./TextArea/TextArea";
import { getRelativeDateAndTime } from "./time-helper";
import { useLocaleContext } from "../../../../../../../context/locale";

interface BubbleTextAreaProps {
  index: number;
  text: string;
  timestamp: DateTime;
  isUser: boolean;
  single: boolean;
  loading?: boolean;
  first?: boolean;
  selected?: boolean;
  doowii: MutableRefObject<Doowii | null>;
  onSelectChat?: () => void;
  setQuery?: (value: string) => void;
  followUpQuestions?: string[];
}
export const BubbleTextArea = ({
  index,
  text,
  timestamp,
  selected,
  isUser,
  loading,
  onSelectChat,
  first,
  setQuery,
  followUpQuestions,
  single,
  doowii,
}: BubbleTextAreaProps) => {
  const { locale } = useLocaleContext();
  const relativeDate = getRelativeDateAndTime(timestamp, locale);

  return (
    <div className="w-full grow chat-desktop:w-auto lg:ml-4">
      <div className="mb-1 hidden text-[12px] font-bold text-[#6B7780] lg:block">
        {relativeDate}
      </div>
      <TextArea
        index={index}
        text={text}
        selected={selected}
        isUser={isUser}
        loading={loading}
        onSelectChat={onSelectChat}
        first={first}
        setQuery={setQuery}
        followUpQuestions={followUpQuestions}
        single={single}
        doowii={doowii}
      />
    </div>
  );
};
