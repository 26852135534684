import {
  IconAnalyticsLine,
  IconEmptyLine,
  IconTableTopHeaderLine,
  IconTargetLine,
} from "@instructure/ui-icons";
import { yellow } from "@mui/material/colors";
import { toast } from "react-hot-toast";

import {
  BAR_CHART,
  FAVORITE_VIEW,
  LINE_CHART,
  PIE_CHART,
  SCATTER_CHART,
  TABLE,
  USER_MANAGEMENT_VIEW,
} from "../Constants/constants";
import { authErrorMessagesMap } from "../Constants/errorMessages";

export function formatErrorMessageAndAlert(errorCode) {
  const message = authErrorMessagesMap[errorCode];
  toast.error(message);
}

export function showWhiteBoard(allResults) {
  return allResults.length > 0;
}

export function showNavLogo(view, allResults) {
  return view === FAVORITE_VIEW || view === USER_MANAGEMENT_VIEW || showWhiteBoard(allResults);
}

const getEquallyDistributedSubstring = (str: string, substring: string) => {
  if (!str || !substring) {
    return "";
  }

  const index = str.toLowerCase().indexOf(substring.toLowerCase());
  if (index === -1) {
    return "";
  }

  const substringLength = substring.length;
  const totalLength = 26;
  let start, end;

  // Calculate the number of characters to include before and after the substring
  const charsBefore = Math.floor((totalLength - substringLength) / 2);
  const charsAfter = totalLength - substringLength - charsBefore;

  if (index - charsBefore < 0) {
    // If the substring is near the start of the string
    start = 0;
    end = totalLength;
  } else if (index + substringLength + charsAfter > str.length) {
    // If the substring is near the end of the string
    end = str.length;
    start = end - totalLength;
  } else {
    // If the substring is somewhere in the middle
    start = index - charsBefore;
    end = index + substringLength + charsAfter;
  }

  // Adjust start and end indices if they go out of the string's bounds
  start = Math.max(0, start);
  end = Math.min(str.length, end);

  return str.slice(start, end);
};

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

export const highlightSubstring = (text: string, substring: string) => {
  if (!text || !substring) return text;

  const escapedSubstring = escapeRegExp(substring);
  const relevantString = getEquallyDistributedSubstring(text, escapedSubstring);
  const parts = relevantString.split(new RegExp(`(${escapedSubstring})`, "gi"));

  return parts.map((part, index) => {
    if (part.toLowerCase() === escapedSubstring.toLowerCase()) {
      return (
        <span
          key={index}
          style={{ backgroundColor: yellow[700], fontWeight: "bold", padding: "2px" }}
        >
          {part}
        </span>
      );
    }
    return part;
  });
};

export const round_numbers_in_object = (data) => {
  if (data === "number") return;
  for (let key in data) {
    if (typeof data[key] === "number") {
      data[key] = Number(data[key].toFixed(2));
    } else if (typeof data[key] === "object" && data[key] !== null) {
      round_numbers_in_object(data[key]);
    }
  }
};

export const looksLikeDate = (str: string) => {
  const datePatterns = [
    // Match ISO 8601 date and time with timezone (e.g., 2021-12-31T23:59:59+02:00 or 2021-12-31T23:59:59Z)
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)?(Z|[+-]\d{2}:\d{2})?$/,
    // Match ISO 8601 date without time (e.g., 2021-12-31)
    /^\d{4}-\d{2}-\d{2}$/,
    // Match dates like MM/DD/YYYY
    /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/,
    // Match dates like DD/MM/YYYY
    /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/\d{4}$/,
    // Match dates like YYYY/MM/DD
    /^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/,
    // Additional date formats can be added here
  ];

  return datePatterns.some((pattern) => pattern.test(str));
};

export const availableCharts = (columns) => {
  const columnDetails = Object.values(columns);
  // @ts-ignore
  const numerical = columnDetails.filter((col) => col.type === "numerical");
  // @ts-ignore
  const categorical = columnDetails.filter((col) => col.type === "categorical");
  // @ts-ignore
  const temporal = columnDetails.filter((col) => col.type === "temporal");

  const charts = [TABLE]; // Table can always be plotted

  if (numerical.length === 2 && categorical.length <= 1) {
    charts.push(SCATTER_CHART);
  }
  if (numerical.length >= 1 && categorical.length === 1) {
    charts.push(BAR_CHART);
  }
  if (numerical.length === 1 && categorical.length === 1) {
    charts.push(PIE_CHART);
  }
  if (temporal.length + categorical.length === 1 && numerical.length >= 1) {
    charts.push(LINE_CHART);
  }
  return charts;
};

export const availableChartsForCanvas = (columns, allowDoubleInt = false) => {
  let hasNumber = 0;
  let hasString = 0;
  let hasTimestamp = 0;
  let length = 0;

  // Determine the types of columns present
  for (let key in columns) {
    if (columns.hasOwnProperty(key)) {
      let type = columns[key].type;
      if (type === "number") hasNumber++;
      if (type === "string") hasString++;
      if (type === "timestamp") hasTimestamp++;
      length = columns[key].length;
    }
  }

  const charts = [TABLE]; // Table can always be plotted

  // Line chart rules
  if (
    (hasNumber > 0 && hasTimestamp === 1) ||
    (hasNumber === 2 && hasTimestamp === 0 && hasString === 0 && allowDoubleInt)
  ) {
    charts.push(LINE_CHART);
  }

  // Bar chart rules
  if (
    hasNumber > 0 &&
    ((hasString === 1 && hasTimestamp === 0) ||
      (hasString === 0 && hasTimestamp === 1) ||
      (hasNumber === 2 && hasTimestamp === 0 && hasString === 0 && allowDoubleInt))
  ) {
    charts.push(BAR_CHART);
  }

  // Pie chart rules
  if (hasNumber === 1 && hasString === 1 && hasTimestamp === 0 && length < 200) {
    charts.push(PIE_CHART);
  }

  if (
    (hasNumber === 1 && hasTimestamp === 1 && hasString === 0) ||
    (hasNumber === 2 && hasString === 0 && hasTimestamp === 0)
  ) {
    charts.push(SCATTER_CHART);
  }
  return charts;
};

export function renderChartIcon(type) {
  switch (type) {
    case TABLE:
      return <IconTableTopHeaderLine />;
    case BAR_CHART:
      return <IconAnalyticsLine />;
    case PIE_CHART:
      return <IconEmptyLine />;
    case LINE_CHART:
      return <IconTargetLine />;
  }
}

export const handleAnalyticsHubNavigation = ({
  baseUrl,
  accountId,
  navigateToAnalyticsHub = false,
}) => {
  // eslint-disable-next-line no-restricted-globals
  const normalizedBaseUrl = baseUrl.endsWith("/") ? baseUrl : `${baseUrl}/`;

  if (navigateToAnalyticsHub) return `${normalizedBaseUrl}accounts/${accountId}/analytics_hub`;
  return `${normalizedBaseUrl}accounts/${accountId}`;
};
