import React, { useEffect, useMemo, useState } from "react";
import { Tabs, View } from "@instructure/ui";
import { DataTableWrapper } from "../DataTable/DataTableWrapper";
import {
  allCanvasCharts,
  ChartBuilder,
  ChartProps,
  PlottableChartTypes,
} from "../ChartBuilder/ChartBuilder";
import { Pin } from "../../../context/pinboard/pinboard.i";
import { Result } from "../../../context/chat/chat.i";
import { Trans } from "@lingui/macro";
import { ViewEnum } from "../../../context/ui/ui.i";

interface DataVisualizationTabsProps {
  result: Result | Pin;
  setTotalRows?: (rows: number) => void;
  chartType: any;
  setChartType: (type: any) => void;
  loadingText?: string;
  doowii?: any;
  didCreateChart?: (chartProps: ChartProps) => void;
  setPageData?: React.Dispatch<
    React.SetStateAction<{
      rows: {
        [key: string]: string;
      }[];
      pageInfo: {
        headers: string[];
      };
    }>
  >;
  view: ViewEnum.CHAT_VIEW | ViewEnum.PINBOARD_VIEW;
}

const DataVisualizationTabs: React.FC<DataVisualizationTabsProps> = ({
  result,
  setTotalRows,
  chartType,
  setChartType,
  loadingText,
  doowii,
  didCreateChart,
  setPageData,
  view,
}) => {
  const [activeTab, setActiveTab] = useState("table");

  useEffect(() => {
    const chatVisualisation = (result as Pin)?.visualisation;
    setChartType(chatVisualisation);
    if (!allCanvasCharts.includes(chatVisualisation as unknown as PlottableChartTypes)) {
      setActiveTab("table");
    } else {
      setActiveTab("chartBuilder");
    }
  }, [result?.id, setChartType]);

  const handleTabChange = (event, { index }) => {
    setActiveTab(index === 0 ? "table" : "chartBuilder");
  };

  const memoizedTabs = useMemo(() => {
    return (
      <div className="relative flex flex-1">
        <Tabs
          onRequestTabChange={handleTabChange}
          width="100%"
          style={{ border: "1px solid red" }}
          className="Tabs-wrapper"
          height="100%"
        >
          <Tabs.Panel
            renderTitle={<Trans>Table View</Trans>}
            isSelected={activeTab === "table"}
            padding="none"
            className="Tabs-panel"
            style={{ border: "1px solid blue" }}
            unmountOnExit={false}
          >
            <View as="div" padding="none" className="view-as-div" height="100%" display="flex">
              <DataTableWrapper
                setTotalRows={setTotalRows}
                result={result}
                loadingText={loadingText}
                doowii={doowii}
                chartType={chartType}
                setPageData={setPageData}
              />
            </View>
          </Tabs.Panel>
          <Tabs.Panel
            renderTitle={<Trans>Chart Builder</Trans>}
            isSelected={activeTab === "chartBuilder"}
            padding="none"
            height="100%"
            unmountOnExit={false}
          >
            <View as="div" padding="none" className="view-as-div" height="100%" display="flex">
              <ChartBuilder result={result} didCreateChart={didCreateChart} view={view} />
            </View>
          </Tabs.Panel>
        </Tabs>
      </div>
    );
  }, [activeTab, result?.id, setTotalRows, setChartType, didCreateChart]);

  return memoizedTabs;
};

export { DataVisualizationTabs };
