import { Tray } from "@instructure/ui";
import { FC, useState } from "react";

import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useAuth } from "../../../../../../context";
import { SQLDiagramHandler } from "./SQLDiagramHandler";
import { SQLEditor } from "./SQLEditor";
import { SQLTrayCloseButton } from "./SQLTrayCloseButton";
import { Tabs, Tab, Box } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  selectedIndex: number;
}

interface SQLTrayProps {
  results: any;
  sqlTrayOpen: boolean;
  setSqlTrayOpen: (open: boolean) => void;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, selectedIndex, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`sql-tabpanel-${index}`}
      aria-labelledby={`sql-tab-${index}`}
      {...other}
      style={{
        width: "100%",
        position: "absolute",
        left: 0,
        display: index === selectedIndex ? "block" : "none",
        opacity: index === selectedIndex ? 1 : 0,
      }}
    >
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
};

export const SQLTray: FC<SQLTrayProps> = ({ results, sqlTrayOpen, setSqlTrayOpen }) => {
  const { _ } = useLingui();
  const { featureFlags } = useAuth();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const a11yProps = (index: number) => {
    return {
      id: `sql-tab-${index}`,
      "aria-controls": `sql-tab-${index}`,
    };
  };

  return (
    <Tray
      label={_(msg`SQL Tray`)}
      open={sqlTrayOpen}
      onDismiss={() => setSqlTrayOpen(false)}
      size="large"
      placement="start"
      height="100vh"
    >
      <div className="flex flex-col p-4 pt-16 chat-desktop:pt-4">
        <div className="relative">
          <SQLTrayCloseButton onClose={() => setSqlTrayOpen(false)} />
        </div>
        {results && (
          <div>
            <Tabs value={selectedIndex} onChange={(_, index) => setSelectedIndex(index)}>
              <Tab label={_(msg`SQL Editor`)} {...a11yProps(0)} />
              <Tab label={_(msg`SQL Diagram`)} {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={selectedIndex} index={0} selectedIndex={selectedIndex}>
              <SQLEditor
                sql={results.sql}
                originalSQL={results.originalSql}
                setSqlTrayOpen={setSqlTrayOpen}
              />
            </CustomTabPanel>
            <CustomTabPanel value={selectedIndex} index={1} selectedIndex={selectedIndex}>
              <SQLDiagramHandler sql={results.sql} />
            </CustomTabPanel>
          </div>
        )}
      </div>
    </Tray>
  );
};
