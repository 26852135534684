import { Flex, IconButton, IconFullScreenLine, IconMoreLine, Menu, Tooltip } from "@instructure/ui";

import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC } from "react";
import { ParentDocTypeEnum } from "../../../../../../../api/retriever.i";
import { useAuth } from "../../../../../../../context";
import { useAlert } from "../../../../../../../context/alert";
import { useChart } from "../../../../../../../context/chart";
import { useChatData } from "../../../../../../../context/chat";
import { Pin } from "../../../../../../../context/pinboard/pinboard.i";
import { downloadCSV } from "../../../../../../../helpers/downloadCSV";
import { withSentry } from "../../../../../../../helpers/wrapper";
import { Analytics } from "../../../../../../../services/analytics/Analytics";
import { IconSQL } from "./IconSQL";

interface DropdownMenuProps {
  setSqlTrayOpen: (open: boolean) => void;
  disabled: boolean;
  invalidState: boolean;
  setFullScreenOpen: (open: boolean) => void;
}
export const DropdownMenu: FC<DropdownMenuProps> = ({
  setSqlTrayOpen,
  disabled,
  invalidState,
  setFullScreenOpen,
}) => {
  const { _ } = useLingui();
  const { chartInstances, exportChart } = useChart();
  const { featureFlags, baseURL } = useAuth();
  const { currentResult: results, currentThread } = useChatData();
  const { alertPromise } = useAlert();
  const chartInstance = chartInstances.find((c) => c.id === results?.id);

  const handleDownloadPNG = withSentry(() => {
    alertPromise({
      promise: () =>
        exportChart({
          pins: [results as unknown as Pin],
          type: "PNG",
          pinId: results?.id,
          onComplete: () => {
            Analytics.track("Downloaded Chart PNG");
          },
        }),
      loading: _(msg`Preparing PNG...`),
      success: _(msg`PNG prepared successfully`),
      error: _(msg`PNG preparation failed`),
    });
  });

  const handleDownloadCSV = withSentry(() => {
    alertPromise({
      promise: () =>
        downloadCSV({
          doc_id: results?.id,
          parent_doc_id: currentThread,
          parent_doc_type: ParentDocTypeEnum.THREAD,
          filename: `${results?.title}.csv`,
        }),
      loading: _(msg`Preparing CSV...`),
      success: _(msg`CSV prepared successfully`),
      error: _(msg`CSV preparation failed`),
    });
  });

  const handleDownloadPDF = withSentry(() => {
    alertPromise({
      promise: () =>
        exportChart({
          pins: [results as unknown as Pin],
          baseURL,
          fileName: `${results?.title}.pdf`,
          type: "PDF",
          onComplete: () => {
            Analytics.track("Downloaded Chart PDF");
          },
        }),
      loading: _(msg`Preparing PDF...`),
      success: _(msg`PDF prepared successfully`),
      error: _(msg`PDF preparation failed`),
    });
  });

  return (
    <Menu
      placement="bottom"
      trigger={
        <Tooltip renderTip={<Trans>More Options</Trans>} placement="top">
          <IconButton
            renderIcon={IconMoreLine}
            withBackground={false}
            withBorder={false}
            screenReaderLabel={_(msg`Open menu`)}
            disabled={disabled}
          />
        </Tooltip>
      }
      disabled={!results?.sql}
      mountNode={() => document.getElementById("main")}
    >
      <Menu.Item onSelect={() => setSqlTrayOpen(true)}>
        <Flex alignItems="center" gap="small">
          <IconSQL /> <Trans>Show SQL</Trans>
        </Flex>
      </Menu.Item>
      <Menu.Item onSelect={() => setFullScreenOpen(true)}>
        <Flex alignItems="center" gap="small">
          <IconFullScreenLine /> <Trans>Show Full Screen</Trans>
        </Flex>
      </Menu.Item>
      <Menu label={_(msg`Download as`)} disabled={invalidState}>
        <Menu.Item onSelect={handleDownloadCSV} data-testid="download-csv">
          <Trans>Comma Separated values (.csv)</Trans>
        </Menu.Item>
        {chartInstance && (
          <Menu.Item onSelect={handleDownloadPNG} data-testid="download-png">
            <Trans>PNG image (.png)</Trans>
          </Menu.Item>
        )}

        <Menu.Item onSelect={handleDownloadPDF} data-testid="download-pdf">
          <Trans>PDF Document (.pdf)</Trans>
        </Menu.Item>
      </Menu>
    </Menu>
  );
};
