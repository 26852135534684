import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ReactNode, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Loader } from "../canvasApp/components/UI/Loader/Loader";
import { useAuth as useAppAuth } from "../context";
import { withSentry } from "../helpers/wrapper";

const CanvasSignIn = () => {
  const { _ } = useLingui();
  const urlParams = new URLSearchParams(window.location.search);
  const authApp = useAppAuth();
  const { authenticateWithToken, setAccountId, setAccountName } = authApp;

  const handleCanvasTokenAuth = withSentry(async () => {
    const ref = urlParams.get("ref");
    if (ref) {
      try {
        const base64Url = ref;
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        const { account_id, account_name, token } = JSON.parse(jsonPayload);

        if (account_id) setAccountId(account_id);
        if (account_name) setAccountName(account_name);

        if (token) {
          await authenticateWithToken(token);
        }
      } catch (error) {
        console.error("Error during Canvas authentication:", error);
        throw error;
      }
    }
  });

  useEffect(() => {
    handleCanvasTokenAuth();
  }, []);

  return (
    <div className="flex h-full w-full items-center justify-center">
      <Loader message={_(msg`Loading...`)} />
    </div>
  );
};

interface ProtectedRouteLayoutProps {
  children: ReactNode;
  isProtected?: boolean;
  isDeveloper?: boolean;
}

export const ProtectedRouteLayout = ({
  children,
  isProtected = true,
  isDeveloper = false,
}: ProtectedRouteLayoutProps) => {
  const authApp = useAppAuth();
  const { authUser } = authApp;
  const isAuthenticated = !!authUser;

  const currentPath = window.location.pathname;
  const isStatus = currentPath.startsWith("/status");

  return (
    <>
      {!isProtected ? (
        isAuthenticated && !isStatus ? (
          <Navigate to="/" />
        ) : (
          <>{children}</>
        )
      ) : !isAuthenticated ? (
        <CanvasSignIn />
      ) : (
        <>{children}</>
      )}
    </>
  );
};
