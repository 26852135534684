/* eslint-disable lingui/no-unlocalized-strings */
import { Typography, Box, Divider } from "@mui/material";
import { Incidents } from "./interface";

interface PastIncidentsProps {
  incidents: Incidents;
}

const PastIncidents: React.FC<PastIncidentsProps> = ({ incidents }) => {
  const pastFifteenDays = (): string[] => {
    let dates: string[] = [];
    for (let i = 0; i <= 14; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      const dateStr = date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      dates.push(dateStr);
    }
    return dates;
  };
  return (
    <>
      {" "}
      <Typography variant="h5" sx={{ mt: 4 }} fontWeight={"600"} color={"#153946"}>
        Past Incidents
      </Typography>
      <Box sx={{ my: 2 }}>
        {pastFifteenDays().map((date, index) => (
          <Box key={index} sx={{ padding: "1rem" }}>
            <Typography variant="h6">{date}</Typography>
            <Divider sx={{ my: 1 }} />
            {incidents[date] ? (
              incidents[date].map((incident, i) => (
                <Box key={i}>
                  <Typography variant="h6" fontWeight={"600"} color={"#F4AC36"}>
                    {incident.title}
                  </Typography>
                  {incident.resolved && (
                    <Typography>
                      <strong>Resolved:</strong> {incident.resolved}
                    </Typography>
                  )}

                  {incident.identified && (
                    <Typography>
                      <strong>Identified:</strong> {incident.identified}
                    </Typography>
                  )}
                  {incident.update &&
                    incident.update.map((update, j) => (
                      <Typography key={j}>
                        <strong>Update:</strong> {update}
                      </Typography>
                    ))}
                  {incident.investigating && (
                    <Typography>
                      <strong>Investigating:</strong> {incident.investigating}
                    </Typography>
                  )}
                </Box>
              ))
            ) : (
              <Typography color="GrayText">No incidents reported.</Typography>
            )}
          </Box>
        ))}{" "}
      </Box>
    </>
  );
};

export default PastIncidents;
