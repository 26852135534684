import { fetchEventSource } from "@microsoft/fetch-event-source";
import { getCurrentUserAuthorization } from "../api/base";
import { env } from "../env";
export const fetchStreamEvent = async ({
  setAnswer,
  thread_id,
  question_id,
  index,
  recent_messages = [],
  setStreamLoading,
}) => {
  const token = await getCurrentUserAuthorization();
  return new Promise<string>((resolve, reject) => {
    const request_body = {
      thread_id: thread_id,
      question_id: question_id,
      recent_messages: recent_messages,
    };

    fetchEventSource(`${env.REACT_APP_SEQUALIZER_API_URL}/explanation/stream`, {
      method: "POST",
      headers: {
        Accept: "text/event-stream",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(request_body),
      onmessage(event) {
        setStreamLoading(false);
        const token = JSON.parse(event.data)["content"];
        setAnswer((prev) => {
          const updatedAnswers = [...prev];
          updatedAnswers[index] = (updatedAnswers[index] || "") + token;
          return updatedAnswers;
        });
      },
      onclose() {
        setAnswer((prev) => {
          resolve(prev[index]);
          return prev;
        });
      },
      onerror(err) {
        reject("agent_failure");
        throw err;
      },
    });
  });
};
