import { Button, Flex, IconChatLine, ScreenReaderContent, Select, Text } from "@instructure/ui";
import { useEffect, useMemo, useState } from "react";

import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useChatData } from "../../../../../context/chat";
import { withSentry } from "../../../../../helpers/wrapper";
import { ChatBubbleIcon } from "./ChatBubbleIcon";
import { PlusIcon } from "./PlusIcon";
import { useLocaleContext } from "../../../../../context/locale";

export default function ThreadSelector() {
  const { locale } = useLocaleContext();
  const { _, i18n } = useLingui();
  const { threads, selectThread, newChat, currentThread, loading } = useChatData();
  const [isShowingOptions, setIsShowingOptions] = useState(false);
  const [highlightedOptionId, setHighlightedOptionId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const [announcement, setAnnouncement] = useState("");

  const options = useMemo(() => {
    return threads.map((thread) => {
      const formattedDateTime = i18n.date(new Date(thread.created_at));
      return {
        ...thread,
        title: `${formattedDateTime} - ${thread.title}`,
      };
    });
  }, [threads, i18n, locale]);

  const filteredOptions = useMemo(() => {
    if (!searchTerm) return options;
    return options.filter((option) =>
      option.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [options, searchTerm]);

  const createNewChatLabel = () => {
    const formattedDateTime = i18n.date(new Date());
    return _(msg`${formattedDateTime} - New Chat`);
  };

  const currentThreadTitle = options.find((option) => option.id === currentThread)?.title;
  useEffect(() => {
    if (!isUserTyping) {
      let title = getOptionById(currentThread)?.title || createNewChatLabel();
      setPlaceholder(title);
      setInputValue(title);
    }
  }, [currentThreadTitle, isUserTyping]);

  const startNewChat = () => {
    newChat();
    setPlaceholder(createNewChatLabel());
    setInputValue(createNewChatLabel());
    setSearchTerm("");
    setIsUserTyping(false);
    setAnnouncement(_(msg`New chat started. Previous chat history cleared.`));
  };

  const getOptionById = (queryId) => {
    return options.find((option) => option.id === queryId);
  };

  const handleShowOptions = () => {
    setIsShowingOptions(true);
  };

  const handleHideOptions = () => {
    if (!isUserTyping) {
      const selectedOption = getOptionById(currentThread)?.title || createNewChatLabel();
      setPlaceholder(selectedOption);
      setInputValue(selectedOption);
    }
    setIsShowingOptions(false);
    setHighlightedOptionId(null);
    setSearchTerm("");
  };

  const handleBlur = () => {
    setHighlightedOptionId(null);
    setSearchTerm("");
    setIsUserTyping(false);
  };

  const handleHighlightOption = (event, { id }) => {
    event.persist();
    const option = getOptionById(id)?.title;
    setHighlightedOptionId(id);
    if (event.type === "keydown") {
      setInputValue(option);
      setIsUserTyping(false);
    }
  };

  const handleSelectOption = withSentry(async (event, { id }) => {
    if (id === "new-chat" || id === "separator") {
      setIsShowingOptions(false);
      return startNewChat();
    }

    const option = getOptionById(id)?.title;
    setPlaceholder(option);
    setInputValue(option);
    setIsShowingOptions(false);
    setSearchTerm("");
    setIsUserTyping(false);
    await selectThread(id);
    setAnnouncement(_(msg`${option} loaded`));
  });

  const handleInputChange = (event, value) => {
    if (!isUserTyping) {
      setInputValue("");
    }
    setSearchTerm(value);
    setInputValue(value);
    setIsUserTyping(true);
  };

  const handleFocus = () => {
    if (!isUserTyping) {
      setInputValue("");
    }
  };

  const isNewChatOptionHighlighted = highlightedOptionId === "new-chat";

  const renderSelect = (size) => (
    <Select
      renderLabel={
        <ScreenReaderContent>
          <Trans>Select thread by title</Trans>
        </ScreenReaderContent>
      }
      renderBeforeInput={ChatBubbleIcon}
      assistiveText={_(msg`Use arrow keys to navigate options. Type to search.`)}
      inputValue={inputValue}
      placeholder={placeholder}
      isShowingOptions={isShowingOptions}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onRequestShowOptions={handleShowOptions}
      onRequestHideOptions={handleHideOptions}
      onRequestHighlightOption={handleHighlightOption}
      onRequestSelectOption={handleSelectOption}
      onInputChange={handleInputChange}
      size={size}
      disabled={loading}
    >
      {filteredOptions.map((option) => {
        const isHighlighted = option?.id === highlightedOptionId;
        const isSelected = option?.id === currentThread;

        return (
          <Select.Option
            data-testid="thread-option"
            id={option?.id}
            key={option?.id}
            renderBeforeLabel={
              <ChatBubbleIcon fill={isHighlighted || isSelected ? "white" : "#425F6A"} />
            }
            isHighlighted={isHighlighted}
            isSelected={option?.id === currentThread}
          >
            <Text size="small">{option?.title}</Text>
          </Select.Option>
        );
      })}
      <Select.Group renderLabel="">
        <Select.Option
          id="new-chat"
          renderBeforeLabel={
            <div className="ml-[2.5px]">
              <PlusIcon fill={isNewChatOptionHighlighted ? "white" : "#425F6A"} />
            </div>
          }
          isHighlighted={isNewChatOptionHighlighted}
        >
          <Text size="small">
            <Trans>Start New Chat</Trans>
          </Text>
        </Select.Option>
      </Select.Group>
    </Select>
  );

  const renderNewChatButton = (size) => {
    return (
      <Flex.Item margin="0 0 0 small">
        <Button
          onClick={startNewChat}
          disabled={loading}
          renderIcon={<IconChatLine />}
          size={size}
          aria-label="New Chat"
        >
          <Trans>New</Trans>
        </Button>
      </Flex.Item>
    );
  };

  return (
    <>
      <div 
        aria-live="polite" 
        className="sr-only" 
        role="status"
        aria-atomic="true"
      >
        {announcement || ' '}
      </div>
      <div className="hidden chat-desktop:block">
        <div className="bg-[#F5F5F5] p-[13px]">
          <Flex direction="row" justifyItems="space-between">
            <Flex.Item shouldGrow>
              <Flex direction="row" gap="small" alignItems="center">
                <Flex.Item>
                  <Text size="x-small" weight="bold">
                    <Trans>My Chats:</Trans>
                  </Text>
                </Flex.Item>
                <Flex.Item shouldGrow>{renderSelect("small")}</Flex.Item>
              </Flex>
            </Flex.Item>
            {renderNewChatButton("small")}
          </Flex>
        </div>
      </div>
      <div className="chat-desktop:hidden">
        <Flex direction="column" gap="x-small">
          <Flex.Item>
            <Text size="medium" weight="bold">
              <Trans>My Chats:</Trans>
            </Text>
          </Flex.Item>
          <Flex direction="row" gap="small" alignItems="center">
            <Flex.Item overflowY="hidden" overflowX="hidden" shouldGrow>
              {renderSelect("medium")}
            </Flex.Item>
            {renderNewChatButton("medium")}
          </Flex>
        </Flex>
      </div>
    </>
  );
}
