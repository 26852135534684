import { Button, Flex, Text } from "@instructure/ui";
import { Trans } from "@lingui/macro";
import * as Sentry from "@sentry/react";
import { useAuth } from "../../../context";
import { useChatData } from "../../../context/chat";
import { getRecentMessageRegenerate } from "../../../helpers/getRecentMessage";
import { QuestionTypeEnum } from "../../../search/Doowii.i";

export default function CancelDisplay({ results, doowii }) {
  const { userDocument } = useAuth();
  const { allResults, answer } = useChatData();

  const handleRegenerate = () => {
    if (doowii) {
      Sentry.withScope((scope) => {
        scope.setTag("log-tag", "RegenerateButtonClicked");
        scope.setTag("user_id", userDocument?.email);
        Sentry.captureMessage(
          `User: ${userDocument?.email} clicked regenerate question: ${results?.query}`
        );
      });
      const index = allResults?.findIndex((result) => result?.id === results?.id);
      doowii.current.chat({
        query: results?.query,
        index: index,
        questionType: QuestionTypeEnum.REGENERATE,
        recent_messages: getRecentMessageRegenerate({ allResults, answer, index }),
      });
    }
  };

  return (
    <Flex
      direction="column"
      justifyItems="center"
      alignItems="center"
      gap="medium"
      height="100%"
      margin="large"
    >
      <Flex.Item textAlign="center">
        <Text>
          <Trans>You have successfully canceled this query.</Trans>
        </Text>
      </Flex.Item>
      <Flex.Item>
        <Button margin="small" onClick={handleRegenerate}>
          <Trans>Regenerate</Trans>
        </Button>
      </Flex.Item>
    </Flex>
  );
}
