import { Button, CloseButton, Heading, Modal, TextInput } from "@instructure/ui";
import { FC, FormEvent, useEffect, useState } from "react";

import { useAlert } from "../../../../context/alert";
import { withSentry } from "../../../../helpers/wrapper";
import { ButtonLoader } from "../ButtonLoader/ButtonLoader";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";

interface CreateModalProps {
  open: boolean;
  name: string;
  maxLength?: number;
  setOpen: (value: boolean) => void;
  action: (value: string) => Promise<string>;
}

export const CreateModal: FC<CreateModalProps> = ({ open, setOpen, name, maxLength, action }) => {
  const { _ } = useLingui();
  const { showAlert } = useAlert();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, [value]);

  const handleButtonClick = () => {
    setOpen(!open);
  };

  const handleFormSubmit: any = withSentry(async (e: FormEvent<EventTarget>) => {
    e.preventDefault();
    setError("");

    const finalValue = value.trim();

    if (finalValue === "") {
      setError(_(msg`Please enter a valid ${name}`));
      return;
    }

    try {
      setLoading(true);
      await action(finalValue);
      showAlert({
        variant: "success",
        message: _(msg`${finalValue} has been created successfully!`),
      });
      setOpen(false);
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(e.message);
      }
      throw e;
    } finally {
      setLoading(false);
    }
  });

  const hasError = !!error;
  const emptyInput = value.trim() === "";

  return (
    <Modal
      as="form"
      open={open}
      onDismiss={() => setOpen(false)}
      onSubmit={handleFormSubmit}
      size="small"
      label={_(msg`Modal Dialog: Create ${name}`)}
      shouldCloseOnDocumentClick={!loading}
    >
      <Modal.Header>
        <Heading>
          <Trans>Create {name}</Trans>
        </Heading>
        <CloseButton
          placement="end"
          offset="small"
          onClick={() => setOpen(false)}
          screenReaderLabel={_(msg`Close`)}
          size="medium"
          margin="small"
          disabled={loading}
        />
      </Modal.Header>
      <Modal.Body padding="large medium">
        <TextInput
          renderLabel={_(msg`${name} name`)}
          placeholder={_(msg`Enter ${name} name`)}
          disabled={loading}
          messages={hasError ? [{ type: "error", text: error }] : []}
          value={value}
          maxLength={maxLength}
          onChange={(e) => setValue(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleButtonClick} disabled={loading} margin="0 x-small 0 0">
          <Trans>Close</Trans>
        </Button>
        <Button
          color="primary"
          type="submit"
          disabled={loading || hasError || emptyInput}
          renderIcon={loading && <ButtonLoader />}
        >
          <Trans>Create</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
