import { useEffect, useState } from "react";
import { PRE_FETCH_SIZE } from "../Constants/constants";
import { fetchPageData } from "../api/retriever";
import { useAuth } from "../context/auth";
import { useChatData } from "../context/chat";
import { usePinboard } from "../context/pinboard";
import { DataMap } from "../context/pinboard/pinboard.i";
import { withSentry } from "../helpers/wrapper";
import { useUI } from "../context/ui";
import { ViewEnum } from "../context/ui/ui.i";
import { ParentDocTypeEnum } from "../api/retriever.i";

const fetchPageDataCache = {};

export const useQuery = (result, queryOptions, shouldFetchData?: boolean) => {
  const { dataMap, setDataMap, currentThread } = useChatData();
  const { view } = useUI();
  const { currBoardId } = usePinboard();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [queryError, setQueryError] = useState(null);
  const [fetchComplete, setFetchComplete] = useState(false);

  const parentDocId = view === ViewEnum.CHAT_VIEW ? currentThread : currBoardId;
  const parentDocType =
    view === ViewEnum.CHAT_VIEW ? ParentDocTypeEnum.THREAD : ParentDocTypeEnum.PINBOARD;

  const { userDocument } = useAuth();

  const prefetchPageSize = PRE_FETCH_SIZE;

  //TODO:Remove this when we have a better way to support demo environment
  const handleDemoData = () => {
    setTimeout(() => {
      if (result.data?.rows) {
        setRows(
          result.data.rows.map((row, index) => ({
            id: index,
            ...row,
          }))
        );
      }

      setPageInfo({
        headers: result.data?.headers,
        totalRowCount: result.data?.rows?.length,
        hyperlinkColumns: {},
      });

      setLoading(false);
      setFetchComplete(true);
    }, 200);
  };

  const callRetriever = withSentry(async (prefetchPage) => {
    try {
      const fetchPageDataCacheKey = `${result.sql}_${prefetchPageSize}_${prefetchPage}`;
      if (!fetchPageDataCache[fetchPageDataCacheKey]) {
        fetchPageDataCache[fetchPageDataCacheKey] = fetchPageData({
          page_size: prefetchPageSize,
          page_number: prefetchPage,
          sort_model: queryOptions.sortModel,
          doc_id: result.id,
          parent_doc_id: parentDocId,
          parent_doc_type: parentDocType,
          retrieval_type: result.type === "PREDICTION" ? "prediction" : "query",
          user_defined_query: result.originalSql && result.sql !== result.originalSql,
        })
          .then((data) => {
            delete fetchPageDataCache[fetchPageDataCacheKey];
            return data;
          })
          .catch((error) => {
            delete fetchPageDataCache[fetchPageDataCacheKey];
            throw error;
          });
      }

      const { data, headers } = await fetchPageDataCache[fetchPageDataCacheKey];

      const totalRowCount = data.totalRows;
      const hyperlinkColumns = data.hyperlink_columns;
      const dataTypes = data.data_types;
      const formattedData = data.rows?.reduce((acc, row, index) => {
        return { [index + prefetchPage * prefetchPageSize]: row, ...acc };
      }, {});

      (setDataMap as any)((prev) => {
        const firstId = Object.keys(formattedData)[0];

        if (prev[result.id] && prev[result.id].rows.hasOwnProperty(firstId)) {
          return prev;
        }

        const output: DataMap = {
          ...prev,
          [result.id]: {
            rows: {
              ...prev[result.id]?.rows,
              ...formattedData,
            },
            pageInfo: {
              totalRowCount: totalRowCount,
              headers: headers,
              hyperlinkColumns: hyperlinkColumns,
              dataTypes: dataTypes,
            },
          },
        };

        return output;
      });

      return { totalRowCount, headers, formattedData, hyperlinkColumns };
    } catch (error) {
      setLoading(false);
      setFetchComplete(true);
      setQueryError(error);
      throw error;
    }
  });

  const sortDataLocally = () => {
    const { field, sort } = queryOptions.sortModel[0];
    const { dataTypes } = dataMap[result.id].pageInfo;

    const sortedRows = Object.values(dataMap[result.id]?.rows || {}).sort((row1, row2) => {
      const value1 = row1[field];
      const value2 = row2[field];
      const fieldType = dataTypes[field];

      let comparison = 0;

      if (value1 === null || value2 === null) {
        if (value1 === value2) return 0;
        return value1 === null ? (sort === "asc" ? -1 : 1) : sort === "asc" ? 1 : -1;
      }

      switch (fieldType) {
        case "timestamp":
        case "datetime":
        case "date":
          comparison = new Date(value1).getTime() - new Date(value2).getTime();
          break;
        case "integer":
        case "float":
          comparison = value1 - value2;
          break;
        case "boolean":
          comparison = value1 === value2 ? 0 : value1 ? 1 : -1;
          break;
        case "string":
          comparison = value1.localeCompare(value2);
          break;
        default:
          comparison = value1.toString().localeCompare(value2.toString());
      }

      return sort === "asc" ? comparison : -comparison;
    });

    return sortedRows;
  };

  const shouldSortDataLocally = () => {
    const totalRowCount = dataMap[result.id]?.pageInfo.totalRowCount;
    const rows = dataMap[result.id]?.rows;
    return totalRowCount < prefetchPageSize && rows;
  };

  const roundNumericValues = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === "number") {
        obj[key] = Math.round(obj[key] * 100) / 100;
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        roundNumericValues(obj[key]);
      }
    }
    return obj;
  };

  const processRows = (rows) => {
    try {
      return rows.map((row) => roundNumericValues({ ...row }));
    } catch (error) {
      console.error("Error processing rows, using original data:", error);
      return rows;
    }
  };

  const formatForSetRows = (rows) => {
    const startIdx = getRowsStart(queryOptions);
    const keys = generatePageRowKeys(startIdx, queryOptions.paginationModel.pageSize);
    const unrounded = keys
      .map((idx) => ({ id: idx, ...rows[idx] }))
      .filter((row) => {
        const { id, ...rest } = row;
        return Object.values(rest).some((value) => value !== undefined);
      });
    return processRows(unrounded);
  };

  const retrieveSortedData = withSentry(async () => {
    try {
      //If the total row count is less than the prefetch page size, we can sort the data locally
      if (shouldSortDataLocally()) {
        const sortedRows = sortDataLocally();
        const formattedRows = formatForSetRows(sortedRows);
        setRows(formattedRows);
        setPageInfo(dataMap[result.id]?.pageInfo);
      } else {
        const { data, headers } = await fetchPageData({
          doc_id: result.id,
          parent_doc_id: parentDocId,
          parent_doc_type: parentDocType,
          page_size: queryOptions.paginationModel.pageSize,
          page_number: queryOptions.paginationModel.page,
          sort_model: queryOptions.sortModel,
        });
        if (!dataMap[result.id]) {
          callRetriever(0);
        }

        setRows(
          data.rows?.map((row, index) => {
            return { id: index, ...row };
          })
        );
        setPageInfo({
          totalRowCount: data.totalRows,
          headers: headers,
          hyperlinkColumns: data.hyperlink_columns,
        });
      }
      setLoading(false);
      setFetchComplete(true);
    } catch (error) {
      setLoading(false);
      setFetchComplete(true);
      setQueryError(error);
      throw error;
    }
  });

  const getRowsStart = (queryOptions) => {
    return queryOptions.paginationModel.page * queryOptions.paginationModel.pageSize;
  };

  const getPrefetchPage = (queryOptions) => {
    const page = queryOptions.paginationModel.page;
    const pageSize = queryOptions.paginationModel.pageSize;
    return Math.floor((page * pageSize) / prefetchPageSize);
  };

  const handlePrefetchNeighbourPages = withSentry(async (prefetchPage, totalRowCount) => {
    const totalPages = Math.ceil(totalRowCount / prefetchPageSize);

    const prevPage = prefetchPage > 1 ? prefetchPage - 1 : null;
    const nextPage = prefetchPage + 1 < totalPages ? prefetchPage + 1 : null;

    try {
      if (prevPage && !dataMap[result.id]?.rows[prevPage * prefetchPageSize]) {
        await callRetriever(prevPage);
      }

      if (nextPage && !dataMap[result.id]?.rows[nextPage * prefetchPageSize]) {
        await callRetriever(nextPage);
      }
      cleanUpDataMap(prefetchPage, totalPages);
    } catch (error) {
      console.error("Error prefetching neighbour pages:", error);
      throw error;
    } finally {
      setFetchComplete(true);
    }
  });

  const cleanUpDataMap = (prefetchPage, totalPages) => {
    if (prefetchPage === 0) return;

    (setDataMap as any)((prev) => {
      const newDataMap: DataMap = { ...prev };

      if (newDataMap[result.id] && newDataMap[result.id].rows) {
        // Calculate valid pages to keep: the first page, the current page, one page before, and one page after
        const validPages = Array.from(
          new Set([0, prefetchPage - 1, prefetchPage, prefetchPage + 1])
        ).filter((page) => page >= 0 && page < totalPages);

        // Add indices of valid pages to a set for efficient lookup
        const validIndices = new Set(
          validPages.flatMap((page) => {
            const startIdx = page * prefetchPageSize;
            return Array.from({ length: prefetchPageSize }, (_, i) => startIdx + i);
          })
        );

        newDataMap[result.id].rows = Object.keys(newDataMap[result.id].rows)
          .filter((key) => validIndices.has(parseInt(key)))
          .reduce((obj, key) => {
            obj[key] = newDataMap[result.id].rows[key];
            return obj;
          }, {});
      }

      return newDataMap;
    });
  };

  function generatePageRowKeys(startIdx, pageSize) {
    return Array.from({ length: pageSize }, (_, index) => startIdx + index);
  }

  const retrieveAndSetData = withSentry(async () => {
    const startIdx = getRowsStart(queryOptions);
    const prefetchPage = getPrefetchPage(queryOptions);

    if (dataMap && dataMap[result.id]?.rows[startIdx]) {
      const formattedRows = formatForSetRows(dataMap[result.id]?.rows);
      setRows(formattedRows);
      setPageInfo(dataMap[result.id].pageInfo);
      setLoading(false);
      setFetchComplete(true);

      handlePrefetchNeighbourPages(prefetchPage, dataMap[result.id].pageInfo.totalRowCount);
    } else {
      const { totalRowCount, headers, formattedData, hyperlinkColumns } =
        await callRetriever(prefetchPage);
      const formattedRows = formatForSetRows(formattedData);
      setRows(formattedRows);
      setPageInfo({
        totalRowCount: totalRowCount,
        headers: headers,
        hyperlinkColumns: hyperlinkColumns,
      });
      setLoading(false);
      setFetchComplete(true);

      handlePrefetchNeighbourPages(prefetchPage, totalRowCount);
    }
  });

  useEffect(() => {
    setPageInfo({});
    setRows([]);
  }, [result?.sql]);

  useEffect(() => {
    if (shouldFetchData) {
      setLoading(true);
      setFetchComplete(false);

      if (result?.sql || (result?.type === "PREDICTION" && result?.status === "success")) {
        if (result.sql === "demo_question") {
          handleDemoData();
        } else if (queryOptions?.sortModel?.length > 0) {
          retrieveSortedData();
        } else {
          retrieveAndSetData();
        }
      } else {
        setLoading(false);
        setFetchComplete(true);
      }
    }
  }, [queryOptions, result?.status]);

  return { loading, rows, pageInfo, fetchComplete, setFetchComplete, queryError };
};
