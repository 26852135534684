import { IconButton, ScreenReaderContent, TextInput, Tooltip } from "@instructure/ui";
import { IconCheckMarkSolid, IconEditLine, IconEndLine } from "@instructure/ui-icons";
import React, { FC, useCallback, useState } from "react";

import { CHAT_VIEW } from "../../../../../../../Constants/constants";
import { useChatData } from "../../../../../../../context/chat";
import { usePinboard } from "../../../../../../../context/pinboard";
import { useUI } from "../../../../../../../context/ui";
import { withSentry } from "../../../../../../../helpers/wrapper";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface ChartTitleProps {
  results: any;
  updateTitle: (title: string, id?: string, value?: any) => void;
  disabled?: boolean;
}

export const ChartTitle: FC<ChartTitleProps> = ({ results, updateTitle, disabled }) => {
  const { _ } = useLingui();
  const [value, setValue] = useState(results?.title);
  const [readOnly, setReadOnly] = useState(true);

  const { view } = useUI();
  const { currBoardId } = usePinboard();
  const { searchHistory } = useChatData();

  const handleChange = useCallback((e, value) => {
    setValue(value);
  }, []);

  const handleIconClick = useCallback(() => {
    setReadOnly(false);
  }, []);

  const handleCheckClick: any = withSentry(async () => {
    view === CHAT_VIEW
      ? await updateTitle(value)
      : await updateTitle(currBoardId, results?.id, value);
    setReadOnly(true);
  });

  const handleEndClick = () => {
    setReadOnly(true);
    setValue(results?.title);
  };

  React.useEffect(() => {
    setValue(results?.title);
  }, [results]);

  let AfterInput = null;

  if (disabled) {
    AfterInput = (
      <IconButton
        renderIcon={IconEditLine}
        withBackground={false}
        withBorder={false}
        screenReaderLabel={_(msg`Edit`)}
        onClick={handleIconClick}
        disabled
      />
    );
  } else if (readOnly) {
    AfterInput = (
      <Tooltip renderTip={<Trans>Edit Title</Trans>}>
        <IconButton
          renderIcon={IconEditLine}
          withBackground={false}
          withBorder={false}
          screenReaderLabel={_(msg`Edit`)}
          onClick={handleIconClick}
          disabled={disabled}
        />
      </Tooltip>
    );
  }

  let interaction: "enabled" | "readonly" | "disabled" = "enabled";
  if (readOnly) interaction = "readonly";
  if (disabled) interaction = "disabled";

  return (
    <div className="ml-2 mr-4 flex w-full items-center">
      <TextInput
        renderLabel={
          <ScreenReaderContent>
            <Trans>Edit pinboard title</Trans>
          </ScreenReaderContent>
        }
        value={value || ""}
        onChange={handleChange}
        interaction={interaction}
        disabled={disabled}
        renderAfterInput={AfterInput}
      />

      {!readOnly && (
        <>
          <div className="ml-2">
            <IconButton onClick={handleCheckClick} screenReaderLabel={_(msg`Edit name success`)}>
              <IconCheckMarkSolid color="success" />
            </IconButton>
          </div>
          <div className="ml-2">
            <IconButton onClick={handleEndClick} screenReaderLabel={_(msg`Cancel edit name`)}>
              <IconEndLine color="error" />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
};
