import { Flex, Text } from "@instructure/ui";
import error from "../ChartContent/ErrorDisplay/error.png";
import { Trans } from "@lingui/macro";

export default function EmptyTableOverlay() {
  return (
    <Flex
      direction="column"
      justifyItems="center"
      alignItems="center"
      gap="large"
      height="100%"
      margin="none"
    >
      <Flex.Item>
        <img src={error} alt="ERROR" width={250} />
      </Flex.Item>
      <Flex.Item textAlign="center" width={450}>
        <Text>
          <Trans>
            Based on your question, we have generated the SQL query for you. Please access it by
            selecting "Show SQL" from the "More Options" dropdown menu. However, please note that
            there is no data available to answer your question!
          </Trans>
        </Text>
      </Flex.Item>
    </Flex>
  );
}
