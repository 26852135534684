import { Button, Flex, Text, View } from "@instructure/ui";
import Editor from "@monaco-editor/react";
import { FC, useEffect, useState } from "react";
import { format } from "sql-formatter";

import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { retrieverPost } from "../../../../../../api/base";
import { useChatData } from "../../../../../../context/chat";
import useBreakpoint from "../../../../../../hooks/useBreakpoint";
import { ButtonLoader } from "../../../../UI/ButtonLoader/ButtonLoader";

interface SQLEditorProps {
  sql: string;
  originalSQL: string;
  setSqlTrayOpen: (open: boolean) => void;
}

export const SQLEditor: FC<SQLEditorProps> = ({ sql, originalSQL, setSqlTrayOpen }) => {
  const { _ } = useLingui();
  const [editorSQL, setEditorSQL] = useState(`/* ${_(msg`Edit your SQL`)} */`);
  const [isValid, setIsValid] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");
  const [performingUpdate, setPerformingUpdate] = useState(false);
  const [performingOriginalUpdate, setPerformingOriginalUpdate] = useState(false);
  const { updateSqlInFirestore } = useChatData();
  const { isChatDesktop } = useBreakpoint();

  useEffect(() => {
    if (sql) {
      let formattedSQL: string;
      try {
        formattedSQL = format(sql, { language: "bigquery" });
      } catch (e) {
        formattedSQL = sql;
        console.error(e);
      }
      setEditorSQL(formattedSQL);
    }
  }, [sql]);

  const updateSQL = async (sql, original) => {
    try {
      original ? setPerformingOriginalUpdate(true) : setPerformingUpdate(true);

      const response = await retrieverPost("retrieve/validate_sql", {
        proposed_sql: sql,
      });
      // @ts-ignore
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // @ts-ignore
      const sqlValidation = await response.data;
      setIsValid(sqlValidation.isValid);
      setValidationMessage(sqlValidation.message);

      if (sqlValidation.isValid) {
        // @ts-ignore
        await updateSqlInFirestore(sql);
      }
      original ? setPerformingOriginalUpdate(false) : setPerformingUpdate(false);
      if (sqlValidation.isValid) {
        setSqlTrayOpen(false);
      }
    } catch (error) {
      console.error("Error posting data: ", error);
      original ? setPerformingOriginalUpdate(false) : setPerformingUpdate(false);
    }
  };

  const handleChange = async (value, event) => {
    if (isValid !== null) {
      setIsValid(null);
    }
    if (validationMessage !== "") {
      setValidationMessage("");
    }
    setEditorSQL(value);
  };

  const bColor:
    | "success"
    | "danger"
    | "alert"
    | "transparent"
    | "primary"
    | "secondary"
    | "brand"
    | "info"
    | "warning" = isValid === null ? "primary" : isValid ? "success" : "danger";
  return (
    <div className="flex-1 border-l-2 border-l-neutral-300">
      <Flex direction="column">
        <View
          borderWidth={isValid === false ? "small" : "0"}
          borderColor={isValid === false ? "danger" : "success"}
          display="inline-block"
          width={"100%"}
          padding="0"
          margin="0"
          className="flex-1"
        >
          <Editor
            height={isChatDesktop ? "83vh" : "70vh"}
            defaultLanguage="sql"
            value={editorSQL}
            onChange={handleChange}
            options={{ minimap: { enabled: false }, readOnly: performingUpdate }}
          />
        </View>
        <Flex justifyItems="end" padding="0" margin="0">
          <Flex.Item padding="0 0 x-small 0">
            <Flex direction="row" padding="0" margin="0" gap="medium">
              <Flex.Item>
                <Button
                  onClick={() => updateSQL(editorSQL, false)}
                  renderIcon={performingUpdate && <ButtonLoader />}
                  color="primary"
                  disabled={
                    editorSQL?.replace(/\s+/g, "") === sql?.replace(/\s+/g, "") ||
                    performingUpdate ||
                    performingOriginalUpdate
                  }
                >
                  <Trans>Save SQL</Trans>
                </Button>
              </Flex.Item>
              {originalSQL && sql !== originalSQL && (
                <Flex.Item>
                  <Button
                    onClick={() => updateSQL(originalSQL, true)}
                    renderIcon={performingOriginalUpdate && <ButtonLoader />}
                    color="success"
                    disabled={performingUpdate || performingOriginalUpdate}
                  >
                    <Trans>Revert to original SQL</Trans>
                  </Button>
                </Flex.Item>
              )}
            </Flex>
          </Flex.Item>
          {isValid === false && (
            <Flex.Item padding="0">
              <Text size="small" color={bColor}>
                {validationMessage}
              </Text>
            </Flex.Item>
          )}
        </Flex>
      </Flex>
    </div>
  );
};
