import { PlottableChartTypes } from "../canvasApp/components/ChartBuilder/ChartBuilder";
import { BAR_CHART, LINE_CHART, PIE_CHART, SCATTER_CHART } from "../Constants/constants";

export interface SortModel {
  field: string;
  sort: string;
}

export type Rows = { [key: string]: any }[];

export type Headers = string[];

export interface PageInfo {
  totalRowCount: number;
  headers: Headers;
  dataTypes: {
    [key: string]: ["timestamp" | "datetime" | "date" | "string" | "integar" | "boolean" | "float"];
  };
  hyperlinkColumns: { [key: string]: string[] };
}

export enum ParentDocTypeEnum {
  THREAD = "thread",
  PINBOARD = "pinboard",
}
export interface FetchPageDataParams {
  page_size: number;
  page_number: number;
  sort_model?: SortModel[];
  doc_id: string;
  parent_doc_type: ParentDocTypeEnum;
  parent_doc_id?: string;
  retrieval_type?: string;
  user_defined_query?: boolean;
}

export interface retrieverResponse {
  rows: Rows;
  data_types: {
    [key: string]: ["timestamp" | "datetime" | "date" | "string" | "integar" | "boolean" | "float"];
  };
  hyperlink_columns: { [key: string]: string[] };
  totalRows: number;
}

export type Aggregations = "sum" | "mean" | "min" | "max" | "count";

export const VisualizationTypesEnum = {
  TABLE: "TABLE",
  PIE_CHART,
  BAR_CHART,
  LINE_CHART,
  SAVANT_LINE: "SAVANT_LINE",
  SCATTER_CHART,
  ERROR: "ERROR",
  NO_SQL: "NO_SQL",
  EMPTY_ROWS: "EMPTY_ROWS", // This is a legacy type from the old UI that we need to support, we can remove this once we have migrated all the old data
} as const;

export type VisualizationType =
  (typeof VisualizationTypesEnum)[keyof typeof VisualizationTypesEnum];

export interface ChartConfig {
  suggestion: VisualizationType;
  columns: string[];
  column_types: Record<string, "categorical" | "numerical" | "temporal">;
  options: Record<string, any>;
  column_aggregations: Record<string, Aggregations>;
  selectedColumns: string[];
}

export interface FetchChartDataParams {
  docId: string;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  chartType: PlottableChartTypes;
  selectedColumns: string[];
  chartConfig: ChartConfig;
}
