import React from "react";

interface LoadingSpinnerProps {
  color?: string;
  size?: number;
  title?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  color = "currentColor",
  size = 24,
  title = "Loading",
}) => (
  <svg
    role="img"
    aria-hidden={!title}
    aria-label={title}
    viewBox="0 0 24 24"
    width={size}
    height={size}
    style={{
      animation: "spin 1s linear infinite",
    }}
  >
    {title && <title>{title}</title>}
    <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="2" fill="none" opacity="0.3" />
    <path
      d="M12 2a10 10 0 0 1 10 10"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <style>
      {`
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}
    </style>
  </svg>
);

export default LoadingSpinner;
