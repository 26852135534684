export const focusById = (htmlElementId: string) => {
  const element = document.getElementById(htmlElementId);

  if (element) {
    element.focus();
  }
};

export const clickById = (htmlElementId: string) => {
  const element = document.getElementById(htmlElementId);

  if (element) {
    element.click();
  }
};

export const getById = (htmlElementId: string) => {
  return document.getElementById(htmlElementId);
};
