import { useEffect } from "react";

import { ConversationKey } from "../../Constants/constants";
import { useUI } from "../../context/ui";
import { ViewEnum } from "../../context/ui/ui.i";
import { UsageProvider } from "../../context/usage";
import { ConversationMgr } from "../../helpers/slidingWindow";
import { ChatTab } from "./ChatTab/ChatTab";
import { FullContainer } from "./FullContainer/FullContainer";
import { Header } from "./Header/Header";
import { PinboardTab } from "./PinboardTab/PinboardTab";
import Usage from "./Usage/Usage";
import AvailableDataModal from "./AvailableDataModal/AvailableDataModal";
import AboutModal from "./AboutModal/AboutModal";
import TipsModal from "./ChatTab/ChatArea/SearchBar/TipsModal";

export const Canvas = () => {
  const { view } = useUI();

  useEffect(() => {
    // Reset conversation histories whenever Canvas is unmounted.
    return () => {
      ConversationMgr.get(ConversationKey.SQL).clear();
      ConversationMgr.get(ConversationKey.SQL).add({
        role: "system",
        // TODO: Do we translate this?
        // eslint-disable-next-line lingui/no-unlocalized-strings
        content: "You are an AI assistant trained to generate SQL.",
      });
    };
  }, []);

  const isInUsageView = view === ViewEnum.CANVAS_USAGE;
  const isInChatView = view === ViewEnum.CHAT_VIEW;
  const isInPinboardView = view === ViewEnum.PINBOARD_VIEW;

  return (
    <>
      <div className="hidden chat-desktop:block">
        <Header />
      </div>

      <main className="flex flex-1 flex-col">
        {isInUsageView && (
          <FullContainer>
            <UsageProvider>
              <Usage />
            </UsageProvider>
          </FullContainer>
        )}
        {isInChatView && <ChatTab />}
        {isInPinboardView && <PinboardTab />}
        <AvailableDataModal />
        <AboutModal />
        <TipsModal />
      </main>
    </>
  );
};
