// components/PinFullViewModal.tsx
import React, { FC, PropsWithChildren } from "react";
import { Modal } from "@instructure/ui";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import { PinViewContainerProps } from "../types";

/**
 * Modal component for desktop view of the pin.
 */
export const PinFullViewModal: FC<PropsWithChildren<PinViewContainerProps>> = ({
  children,
  isOpen,
  handleClose,
  size,
}) => {
  const { _ } = useLingui();
  const [header, body, footer] = React.Children.toArray(children);
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      label={_(msg`Pinboard full screen Modal`)}
      id="full-screen-pin"
      size={size}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
};
