import { ThreadSummary } from "../../../context/chat/chat.i";

export const getThreadIndex = (threadId: string, threads: ThreadSummary[]) => {
  return threads.findIndex((option) => option.id === threadId);
};

export const selectPreviousChat = (
  currentThread: string,
  threads: ThreadSummary[],
  selectThread: (threadId: string, setLatestResult?: string) => Promise<void>
) => {
  const index = getThreadIndex(currentThread, threads);

  if (index === 0 || index === -1) {
    return;
  }

  const previousThread = threads[index - 1];
  selectThread(previousThread.id);
};

export const selectNextChat = (
  currentThread: string,
  threads: ThreadSummary[],
  selectThread: (threadId: string, setLatestResult?: string) => Promise<void>
) => {
  const index = getThreadIndex(currentThread, threads);

  if (index === threads.length - 1) {
    return;
  }

  const nextThread = threads[index + 1];
  selectThread(nextThread.id);
};
