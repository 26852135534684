import "./canvas.css";

import { AlertProvider } from "../context/alert";
import { ChartProvider } from "../context/chart";
import { ChatDataProvider } from "../context/chat";
import { PinboardProvider } from "../context/pinboard";
import { UIProvider } from "../context/ui";
import { Canvas } from "./components/Canvas";
import { Layout } from "./components/Layout/Layout";
import DrawerProvider from "../context/mobileDrawers";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

export default function CanvasApp() {
  // Temporary check. Move to env variable
  const isDev = /127\.0\.0\.1/.test(window.location.href);

  return (
    <UIProvider>
      <QueryClientProvider client={queryClient}>
        <AlertProvider>
          <ChatDataProvider>
            <ChartProvider>
              <PinboardProvider>
                <DrawerProvider>
                  <Layout isDev={isDev}>
                    <Canvas />
                  </Layout>
                </DrawerProvider>
              </PinboardProvider>
            </ChartProvider>
          </ChatDataProvider>
        </AlertProvider>
      </QueryClientProvider>
    </UIProvider>
  );
}
