import { Button, Menu, View } from "@instructure/ui";
import { IconSettingsLine } from "@instructure/ui-icons";
import { FC } from "react";

import { Trans } from "@lingui/macro";
import { useChatData } from "../../../../context/chat";
import { useUI } from "../../../../context/ui";
import { ViewEnum } from "../../../../context/ui/ui.i";

const SettingsMenu: FC = () => {
  const { setView, setAvailableDataModalOpen, setAboutModalOpen } = useUI();
  const { loading } = useChatData();

  return (
    <View padding="small" textAlign="center" width="10rem">
      <Menu
        placement="bottom"
        trigger={
          // @ts-ignore
          <Button disabled={loading} renderIcon={IconSettingsLine} display="block">
            <Trans>Info & Help</Trans>
          </Button>
        }
        mountNode={() => document.getElementById("main")}
      >
        <Menu.Item disabled={loading} value="usage" onClick={() => setView(ViewEnum.CANVAS_USAGE)}>
          <Trans>Usage Trends</Trans>
        </Menu.Item>
        <Menu.Item
          disabled={loading}
          value="available_data"
          onClick={() => setAvailableDataModalOpen(true)}
        >
          <Trans>Available Data</Trans>
        </Menu.Item>
        <Menu.Item disabled={loading} value="about" onClick={() => setAboutModalOpen(true)}>
          <Trans>About</Trans>
        </Menu.Item>
      </Menu>
    </View>
  );
};

export default SettingsMenu;
