import { components, operations } from "../../types/WebserverInterfaces.gen";
import { AxiosResponse } from "axios";
import { post } from "./base";

type FeedbackRequest = components["schemas"]["FeedbackRequest"];

type FeedbackResponse =
  operations["handle_feedback_api_negative_feedback_post"]["responses"]["200"]["content"]["application/json"];

export const reportNegativeFeedback = async (
  question: string,
  questionId: string
): Promise<AxiosResponse<FeedbackResponse>> => {
  try {
    const request: FeedbackRequest = {
      question,
      question_id: questionId,
    };
    return post("/negative_feedback", request);
  } catch (error) {
    console.error("Error sending feedback", error);
    throw error;
  }
};
