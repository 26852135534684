import {
  Breadcrumb,
  Button,
  Flex,
  Heading,
  IconButton,
  IconHomeLine,
  IconKeyboardShortcutsLine,
  Tooltip,
} from "@instructure/ui";
import { useState } from "react";

import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useAuth } from "../../../context";
import { useUI } from "../../../context/ui";
import { ViewEnum } from "../../../context/ui/ui.i";
import { handleAnalyticsHubNavigation } from "../../../helpers";
import { KeyboardShortcutsModal } from "./KeyboardShortcutsModal/KeyboardShortcutsModal";
import SettingsMenu from "./SettingsMenu/SettingsMenu";

export const Header = () => {
  const { _ } = useLingui();
  const { baseURL, accountId, accountName, organizationDocument } = useAuth();

  const [open, setOpen] = useState(false);
  const { view, setView } = useUI();

  const isInUsageView = view === ViewEnum.CANVAS_USAGE;
  const homeButton = (
    <Button
      renderIcon={<IconHomeLine />}
      onClick={() => setView(ViewEnum.CHAT_VIEW)}
      margin="0 0 0 small"
    >
      <Trans>Home</Trans>
    </Button>
  );

  let title;

  switch (view) {
    case ViewEnum.CANVAS_USAGE:
      title = <Trans>Usage</Trans>;
      break;
    case ViewEnum.PINBOARD_VIEW:
      title = <Trans>Pinboard</Trans>;
      break;
    case ViewEnum.CHAT_VIEW:
    default:
      title = <Trans>Chats</Trans>;
      break;
  }

  return (
    <div className="flex flex-col items-center justify-between border-b border-black md:flex-row">
      <div className="sr-only">
        <Heading level="h1" margin="medium 0" color="primary">
          <Trans>Ask Your Data</Trans>
        </Heading>
      </div>
      {/* TODO: Temporary workaround for Instructure UI bug
          The Breadcrumb component crashes when it has multiple Breadcrumb.Link children.
          Wrapping it in a Heading component with as="div" prevents the crash.
          This should be revisited when the bug is fixed in the UI library. */}

      <Heading as="div" margin="medium 0" color="primary" width="100%">
        <Breadcrumb label={_(msg`You are here:`)}>
          <Breadcrumb.Link
            target="_parent"
            key="subaccount"
            href={handleAnalyticsHubNavigation({
              baseUrl: baseURL,
              accountId,
            })}
          >
            {accountName}
          </Breadcrumb.Link>
          <Breadcrumb.Link
            target="_parent"
            key="analytics-hub"
            href={handleAnalyticsHubNavigation({
              baseUrl: baseURL,
              accountId,
              navigateToAnalyticsHub: true,
            })}
          >
            <Trans>Analytics Hub</Trans>
          </Breadcrumb.Link>
          <Breadcrumb.Link key="app" onClick={() => setView(ViewEnum.CHAT_VIEW)}>
            <Trans>Ask Your Data</Trans>
          </Breadcrumb.Link>
          <Breadcrumb.Link key="title">{title}</Breadcrumb.Link>
        </Breadcrumb>
      </Heading>
      <Flex>
        {isInUsageView ? homeButton : <SettingsMenu />}
        <Tooltip renderTip={_(msg`Keyboard shortcuts`)} placement="bottom">
          <IconButton
            margin="x-small"
            withBackground={false}
            withBorder={false}
            onClick={() => {
              setOpen(true);
            }}
            screenReaderLabel={_(msg`Keyboard shortcuts`)}
          >
            <IconKeyboardShortcutsLine />
          </IconButton>
        </Tooltip>
      </Flex>
      <KeyboardShortcutsModal open={open} setOpen={setOpen} />
    </div>
  );
};
