import { CSS } from "@dnd-kit/utilities";
import { Button, Flex, View } from "@instructure/ui";
import { FC, useEffect, useRef, useState } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import React from "react";
import { useChatData } from "../../../../../context/chat";
import { usePinboard } from "../../../../../context/pinboard";
import { Pin } from "../../../../../context/pinboard/pinboard.i";
import { useUI } from "../../../../../context/ui";
import { ViewEnum } from "../../../../../context/ui/ui.i";
import { withSentry } from "../../../../../helpers/wrapper";
import useBreakpoint from "../../../../../hooks/useBreakpoint";
import { Analytics } from "../../../../../services/analytics/Analytics";
import { PinboardChartContent } from "../../../ChartContent/PinboardChartContent";
import { DeleteModal } from "../../../UI/Modals/Delete";
import { PinFullScreenView } from "../../PinFullScreenView/components/PinFullScreenView";

import { MovePopover } from "../../MovePopover";
import { ActionButtons } from "./ActionButtons";
import { ChartTitle } from "./ChartTitle";

const MemoizedPinboardChartContent = React.memo(PinboardChartContent);

interface PinboardChartProps {
  chart?: Pin;
  filteredPinboardResults?: Pin[];
  setFilteredPinboardResults?: React.Dispatch<React.SetStateAction<Pin[]>>;
  setPinboardResults?: React.Dispatch<React.SetStateAction<Pin[]>>;
  hasSearch?: boolean;
  inDragMode?: boolean;
}

export const PinboardChart: FC<PinboardChartProps> = ({
  chart,
  filteredPinboardResults,
  setFilteredPinboardResults,
  setPinboardResults,
  hasSearch,
  inDragMode,
}) => {
  const { _ } = useLingui();
  const pinboardRef = useRef(null);
  const { width: ViewWidth } = useWindowSize();

  const { updatePinTitle, unpinFromBoard, currBoardId } = usePinboard();
  const { navigateToChatFromPin } = useChatData();
  const { setView } = useUI();
  const { isChatDesktop } = useBreakpoint();

  const [isFullScreen, setFullScreen] = useState(false);
  const [deleteChartModalOpen, setDeleteChartModalOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [value, setValue] = useState(chart?.title);
  const [readOnly, setReadOnly] = useState(true);

  const handleViewChat = withSentry(async () => {
    await navigateToChatFromPin(chart?.id);

    setView(ViewEnum.CHAT_VIEW);
  });

  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id: chart?.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  useEffect(() => {
    if (isFullScreen) {
      Analytics.track("Pinboard full screen");
    }
  }, [isFullScreen]);

  useEffect(() => {
    if (pinboardRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setHeight(entry.contentRect.height - 10);
          setWidth(entry.contentRect.width);
        }
      });

      resizeObserver.observe(pinboardRef.current);

      return () => {
        if (resizeObserver) {
          resizeObserver.disconnect();
        }
      };
    }
  }, [pinboardRef.current]);

  return (
    <div style={style}>
      <View
        as="div"
        elementRef={setNodeRef}
        background={isDragging ? "secondary" : "primary"}
        height="43vh"
        minHeight="350px"
        borderWidth="small"
        borderRadius="large"
        shadow="resting"
        width={ViewWidth - 30}
      >
        <div className="flex h-full flex-col ">
          <Flex justifyItems="space-between" padding="x-small">
            <Flex.Item>
              {!hasSearch && isChatDesktop && (
                <MovePopover
                  pinId={chart?.id}
                  filteredPinboardResults={filteredPinboardResults}
                  setFilteredPinboardResults={setFilteredPinboardResults}
                  setPinboardResults={setPinboardResults}
                  listeners={listeners}
                  attributes={attributes}
                  setActivatorNodeRef={setActivatorNodeRef}
                />
              )}
            </Flex.Item>
            <Flex.Item shouldShrink>
              <ChartTitle
                results={chart}
                setValue={setValue}
                value={value}
                readOnly={readOnly}
                inDragMode={inDragMode}
              />
            </Flex.Item>
            <Flex.Item>
              <ActionButtons
                setDeleteChartModalOpen={setDeleteChartModalOpen}
                value={value}
                pinResult={chart}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                updateTitle={updatePinTitle}
                setValue={setValue}
              />
            </Flex.Item>
          </Flex>
          <div className="mx-3 flex flex-1" ref={pinboardRef}>
            <MemoizedPinboardChartContent
              height={height ?? 100}
              width={width ?? 100}
              pinResult={chart}
            />
          </div>
          <div className="border-b-solid mt-1 border-b border-b-[#C7CDD1]" />
          <div className="flex justify-end">
            <Button size="small" margin="x-small" onClick={() => handleViewChat()}>
              <Trans>View Chat</Trans>
            </Button>
            <Button
              size="small"
              margin="x-small small x-small x-small"
              onClick={() => setFullScreen(true)}
            >
              <Trans>Full Screen</Trans>
            </Button>
          </div>
        </div>

        {setDeleteChartModalOpen && (
          <DeleteModal
            open={deleteChartModalOpen}
            setOpen={setDeleteChartModalOpen}
            action={() => (unpinFromBoard as any)(chart?.id, currBoardId)}
            name={_(msg`Chart`)}
            text={_(msg`Are you sure you want to delete this chart?`)}
          />
        )}
        {isFullScreen && (
          <PinFullScreenView
            open={isFullScreen}
            setOpen={setFullScreen}
            chart={chart}
            setDeleteChartModalOpen={setDeleteChartModalOpen}
          />
        )}
      </View>
    </div>
  );
};
