import { Button, Flex } from "@instructure/ui";
import { useDebounce } from "@uidotdev/usehooks";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import useBreakpoint from "../../../../../../../../hooks/useBreakpoint";
import { Doowii } from "../../../../../../../../search/Doowii";
import { getEstimateReadingTime } from "../../../../../../DataTable/helpers";
import { Feedback } from "../../../../../../Feedback/Feedback";
import "./BotTextArea.css";
import { FollowUpQuestions } from "./Suggestions/FollowUpQuestions";
import { Suggestions } from "./Suggestions/Suggestions";
import { Trans, msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface BotTextAreaProps {
  index: number;
  text: string;
  loading: boolean;
  single: boolean;
  first?: boolean;
  doowii: MutableRefObject<Doowii | null>;
  setQuery?: (value: string) => void;
  followUpQuestions?: string[];
  onSelectChat: () => void;
}

// TODO: move this to a shared library
export const renderMethologyMarkdown = (markdownText: string) => {
  try {
    if (!markdownText) return null;

    return (
      <ReactMarkdown
        className="result-summary-markdown"
        children={markdownText}
        rehypePlugins={[rehypeRaw]}
        components={{
          ul: ({ children }) => (
            <ul style={{ listStyle: "none", paddingLeft: "1em", margin: "0" }}>{children}</ul>
          ),
          li: ({ children }) => (
            <li style={{ textIndent: "-0.75em" }}>
              {/* Standard list style bullets were not rendering properly when converting to pdf. This and the padding above is a work-around. */}
              <strong>• </strong> {children}
            </li>
          ),
          h3: ({ children, node }) => (
            <h3 style={{ marginTop: children === "Methodology" ? "1rem" : "" }}>{children}</h3>
          ),
          strong: (props) => {
            if (props.children === "dap_undefined") {
              return <>__{props.children}__</>;
            }
            return <strong>{props.children}</strong>;
          },
        }}
      />
    );
  } catch (e) {
    console.error(e);
    return markdownText;
  }
};

const cleanText = (text: string | null | undefined): string => {
  if (text == null) {
    return "";
  }
  return text
    .replaceAll("**", "")
    .replaceAll("### ", "")
    .replaceAll("__", "")
    .replace(t`Summary`, t`Summary -`)
    .replace(t`Methodology`, t`- Methodology -`);
};

export const BotTextArea = ({
  index,
  text,
  loading,
  first,
  setQuery,
  onSelectChat,
  followUpQuestions,
  single,
  doowii,
}: BotTextAreaProps) => {
  const [mobileReader, setMobileReader] = useState("");
  const debouncedMobileReader = useDebounce(mobileReader, 500);
  const debouncedText = useDebounce(text, 500);
  const itWasLoading = useRef(false);
  const { isChatDesktop } = useBreakpoint();
  const { _ } = useLingui();

  if (loading) {
    itWasLoading.current = true;
    if (!mobileReader) {
      setMobileReader(_(msg`Loading results, please wait.`));
    }
  }

  useEffect(() => {
    let timeout;
    if (!isChatDesktop && itWasLoading.current && debouncedText) {
      const estimateReadingTime = getEstimateReadingTime(debouncedText);
      timeout = setTimeout(() => {
        const chatInput = document.getElementById("chat-input");
        (chatInput as HTMLTableCellElement)?.focus();
      }, estimateReadingTime);
    }

    return () => clearTimeout(timeout);
  }, [isChatDesktop, debouncedText]);

  return loading ? (
    <div
      className={`relative flex min-h-12 grow justify-between rounded-lg border border-[#C7CDD1] p-3`}
    >
      {itWasLoading.current && !isChatDesktop && (
        <div aria-live="polite" aria-atomic style={{ position: "absolute", left: "-999999px" }}>
          {debouncedMobileReader}
        </div>
      )}
      <div className={`grow text-sm`}>
        <div className="dot-typing" />
      </div>
    </div>
  ) : (
    <>
      <div
        className={`relative flex min-h-12 grow justify-between rounded-lg border border-[#C7CDD1]`}
      >
        <div className={`grow text-sm`}>
          {itWasLoading.current && !first && (
            <div aria-live="polite" aria-atomic style={{ position: "absolute", left: "-999999px" }}>
              {cleanText(debouncedText)}
            </div>
          )}

          <div className="p-3">{renderMethologyMarkdown(text)}</div>
          {!first && !isChatDesktop && (
            <div className="px-4 py-5">
              <Button display="block" onClick={onSelectChat} disabled={loading}>
                <Trans>View Results</Trans>
              </Button>
            </div>
          )}
          {first && <Suggestions doowii={doowii} />}
          {!!followUpQuestions?.length && (
            <FollowUpQuestions
              doowii={doowii}
              followUpQuestions={followUpQuestions}
              single={single}
            />
          )}
        </div>
      </div>
      {!first && (
        <Flex direction="row-reverse">
          <Flex.Item>
            <Feedback index={index} disabled={loading} />
          </Flex.Item>
        </Flex>
      )}
    </>
  );
};
