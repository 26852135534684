import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import {
  EMPTY_ROWS,
  ERROR,
  ERROR_MESSAGE,
  LOADING_P1,
  LOADING_P2,
  LOADING_P3,
  LOADING_START,
  TABLE,
} from "../Constants/constants";
import { callSequalizer, fetchFollowUpPrompts } from "../api/sequalizer";
import { addToChatHistory, getSourceType, updatedChatHistory } from "../services/firebase";
import { getNewChartConfig } from "../services/webserver/openai";
import { SequalizerError } from "./errors";

import { doc, getDoc } from "@firebase/firestore";
import { I18n } from "@lingui/core";
import { msg } from "@lingui/macro";
import * as Sentry from "@sentry/react";
import { CanceledError } from "axios";
import { sequalizerErrorMessagesMap } from "../Constants/errorMessages";
import { ParentDocTypeEnum } from "../api/retriever.i";
import { Result } from "../context/chat/chat.i";
import { VisualizationTypesEnum } from "../context/pinboard/pinboard.i";
import { ViewEnum } from "../context/ui/ui.i";
import { User } from "../context/users/users.i";
import { firebaseTimestampToLuxon } from "../helpers/time";
import { Analytics } from "../services/analytics/Analytics";
import { db } from "../services/firebase";
import { QuestionTypeEnum } from "./Doowii.i";
import { fetchStreamEvent } from "./StreamEvent";
import { CustomError } from "./errors/error.i";

/**
 *  Doowii chat bot class. This class handles all the chat interactions between the user and doowii.
 * @param setSearchHistory - updates the searchHistory with the new query being added.
 * @param setResults updates the current result.
 * @param setLoading controls the loading state of the webapp.
 * @param allResults is all the previous results of queries from the user.
 * @param setAllResults updates the allResults state variable
 * @param setLoadingText controls the UI loading indicator for progress.
 */
export class Doowii {
  i18n: I18n;
  setSearchHistory: Function;
  setResults: (result: Result) => void;
  setLoading: (loading: boolean) => void;
  setAllResults: Function;
  setAnswer: Function;
  setLoadingText: (text: string) => void;
  setStreamLoading: (loading: boolean) => void;
  user: User;
  threadId: string;
  chat_id: string;
  chat_start_time: number;
  model: string;
  allResults: Result[];
  threads: any;
  featureFlags: string[];
  accountId: string;
  private abortController: AbortController | null;

  constructor(
    i18n,
    setSearchHistory,
    setResults,
    setLoading,
    setAllResults,
    setAnswer,
    setLoadingText,
    setStreamLoading,
    user,
    threadId,
    model,
    allResults,
    threads,
    featureFlags,
    accountId
  ) {
    this.i18n = i18n;
    this.setSearchHistory = setSearchHistory;
    this.setResults = setResults;
    this.setLoading = setLoading;
    this.setAllResults = setAllResults;
    this.setAnswer = setAnswer;
    this.setLoadingText = setLoadingText;
    this.setStreamLoading = setStreamLoading;
    this.user = user;
    this.threadId = threadId;
    this.chat_id = null;
    this.chat_start_time = null;
    this.model = model;
    this.allResults = allResults;
    this.threads = threads;
    this.featureFlags = featureFlags;
    this.accountId = accountId;

    this.abortController = new AbortController();
  }

  /**
   * Handles user-submitted queries to fetch relevant data. The function follows these steps:
   * 1. Initializes the chat with the query.
   * 2. Fetches embeddings for the query from OpenAI.
   * 3. Attempts a demo chat with the query embeddings.
   * 4. If demo chat isn't successful, it moves on to:
   *    a. Generate the BigQuery SQL code for the query.
   *    b. Run the BigQuery SQL code and parse the data.
   *    c. Hydrate the UI with answers and visualisation.
   * If any step fails, it gracefully handles the error.
   *
   * @param {string} query - The query submitted by the user.
   */
  async chat({
    query,
    index,
    model = ViewEnum.QUERY_MODEL,
    recent_messages = [],
    questionType = QuestionTypeEnum.USER,
  }) {
    try {
      this.initializeChat(query, index, questionType);

      Analytics.track("Chat", { type: questionType });

      this.setLoadingText(this.i18n._(LOADING_P1));

      model === ViewEnum.QUERY_MODEL
        ? await this.get_sql_and_answer(query, index, recent_messages, questionType)
        : await this.generate_prediction(query, recent_messages, questionType);
    } catch (error) {
      if (error instanceof CanceledError) {
        this.handleCancel(error, query);
        return;
      }
      this.handleError(error, query);
    }
  }

  async cancel_query() {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = new AbortController();
    }
  }

  /**
   * Transforms a user-submitted query into its corresponding SQL code.
   *
   * @param {string} query - The original query submitted by the user.
   * @param {Object} queryEmbedding - The embedding representation of the query string.
   * @returns {string} SQL code for querying BigQuery tables, along with potential messages from SequalizerAI.
   */

  async get_sql_and_answer(query, index, recent_messages, questionType = "user") {
    try {
      let source_type = await getSourceType(this.user.organization);

      const response = await callSequalizer(
        this.user.organization,
        query,
        this.user.email,
        recent_messages,
        this.threadId,
        this.chat_id,
        source_type,
        "query",
        questionType === QuestionTypeEnum.REGENERATE ? "user" : questionType, // TODO: change back to actuall questionType
        this.abortController
      );

      let chartConfig = { suggestion: "TABLE", columns: [], column_types: {} };

      try {
        chartConfig = await getNewChartConfig(response.sql, query);
        if (chartConfig) {
          // Canvas wants chart suggestion to always be TABLE
          chartConfig.suggestion = "TABLE";
        }
      } catch (error) {
        console.error("Error generating new chart config:", error);
      }

      this.setLoadingText(this.i18n._(LOADING_P2));
      response.status_url
        ? this.clean_up_prediction({
            org_id: this.user.organization,
            thread_id: this.threadId,
            question_id: this.chat_id,
            query: query,
          })
        : this.clean_up({
            index: index,
            visualisation: response.sql ? TABLE : EMPTY_ROWS,
            query: query,
            sql: response.sql,
            visualisationArray: [TABLE],
            chartConfig: chartConfig,
            recent_messages: recent_messages,
            questionType: questionType as QuestionTypeEnum,
          });

      this.setLoadingText(this.i18n._(LOADING_P3));

      return;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Generates predictions from a user submitted query.
   *
   * @param {string} query - The original query submitted by the user.
   * @returns {string} SQL code for querying BigQuery tables, along with potential messages from SequalizerAI.
   */
  async generate_prediction(query, recent_messages, questionType) {
    try {
      let source_type = await getSourceType(this.user.organization);

      await callSequalizer(
        this.user.organization,
        query,
        this.user.id,
        recent_messages,
        this.threadId,
        this.chat_id,
        source_type,
        "prediction",
        questionType
      );

      this.setLoadingText(this.i18n._(LOADING_P2));
      this.clean_up_prediction({
        org_id: this.user.organization,
        thread_id: this.threadId,
        question_id: this.chat_id,
        query: query,
      });

      return;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Handles various types of errors encountered during query processing.
   *
   * When an error is recognized (i.e., SequalizerError, AnswerVizError, or NoDataError),
   * specific details from the error are extracted to form an error response.
   * For unrecognized errors, a default error type and message are set.
   *
   * After processing the error details, the function cleans up resources and
   * updates the state/UI to reflect the error.
   *
   * @param {Error} error - The error object that was thrown.
   * @param {string} query - The query that was being processed when the error occurred.
   * @param {string} [bigQuerySQLCode=""] - The SQL code (if available) associated with the query when the error occurred.
   */
  handleError(error, query, bigQuerySQLCode = "") {
    Sentry.withScope((scope) => {
      scope.setTag("error_type", error.name);
      scope.setLevel("error");
      Sentry.captureMessage(`Could not answer questions: ${error}`);
    });

    let errorMessage: CustomError;

    if (error instanceof SequalizerError) {
      errorMessage = {
        message: sequalizerErrorMessagesMap[error.name] || error.message,
        name: error.name,
        code: error.code,
      };
    } else {
      errorMessage = {
        message: this.i18n._(ERROR_MESSAGE),
        // eslint-disable-next-line lingui/no-unlocalized-strings
        name: "OtherError",
        code: "OTHER_ERROR",
      };
    }

    this.model === ViewEnum.QUERY_MODEL
      ? this.clean_up({
          visualisation: ERROR,
          dataArray: null,
          query: query,
          index: this.allResults.length,
          sql: bigQuerySQLCode,
          visualisationArray: [],
          error: errorMessage,
        })
      : this.clean_up_prediction({
          org_id: this.user.organization,
          thread_id: this.threadId,
          question_id: this.chat_id,
          query: query,
        });
  }

  handleCancel(error, query) {
    const chatEndTime = performance.now();
    const duration = chatEndTime - this.chat_start_time;
    const canceledError = {
      message: error.message,
      name: error.name,
      code: error.code,
    };

    const newResult = {
      id: this.chat_id,
      query: query,
      visualisation: VisualizationTypesEnum.CANCELED,
      title: query,
      satisfied: "UNKNOWN",
      sql: "",
      timestamp: DateTime.now(),
      latency: parseFloat((duration / 1000).toFixed(2)),
      follow_up_prompts: [],
      error: canceledError,
      parentDocId: this.threadId,
      parentDocType: ParentDocTypeEnum.THREAD,
      chartConfig: {
        suggestion: "CANCELED",
        columns: [],
        column_types: {},
      },
    };
    this.setResults(newResult);
    this.setLoadingText("");
    this.setLoading(false);
    this.setAllResults((prevResults) => [...prevResults, newResult]);
    this.setAnswer((prevAnswers) => [...prevAnswers, ""]);

    addToChatHistory(
      this.accountId,
      this.threads,
      this.threadId,
      this.user.organization,
      this.user.id,
      query,
      newResult,
      ""
    );
  }

  /**
   * Function to update the state variables depending on if the query resulted
   * in a successful or error state. The function cleans up all the state
   * variables such that they can be used to display the appropriate UI components.
   * @param {*} agentAnswer from ChatGpt inferred from the data.
   * @param {*} visualisation to be created based on the data.
   * @param {*} dataArray containing cleaned, formatted data for the UI.
   * @param {*} query submitted by user, cannot be updated.
   */
  async clean_up({
    index,
    visualisation,
    dataArray = null,
    query,
    sql,
    visualisationArray = [],
    error = {} as CustomError,
    demoAnswer = "",
    chartConfig = null,
    recent_messages = [],
    questionType = QuestionTypeEnum.USER,
  }) {
    const chatEndTime = performance.now();
    const duration = chatEndTime - this.chat_start_time;

    const apolloSense = chartConfig ?? {
      suggestion: visualisation || "TABLE",
      columns: [],
      column_types: {},
    };
    const newResult = {
      id: this.chat_id,
      data: dataArray,
      query: query,
      visualisation:
        visualisation === "EMPTY_ROWS" || visualisation === "ERROR" || dataArray?.isDemo
          ? visualisation
          : apolloSense.suggestion,
      title: query,
      satisfied: "UNKNOWN",
      error: error,
      sql: sql,
      timestamp: DateTime.now(),
      latency: parseFloat((duration / 1000).toFixed(2)),
      chartConfig: apolloSense,
      parentDocId: this.threadId,
      parentDocType: ParentDocTypeEnum.THREAD,
      follow_up_prompts: [],
    };
    this.setResults(newResult);
    this.setLoadingText("");
    this.setLoading(false);

    let newAnswer: string = dataArray?.isDemo ? demoAnswer : error?.message ? error.message : "";

    if (questionType === QuestionTypeEnum.REGENERATE) {
      this.setAllResults((prevResults) =>
        prevResults.map((result, i) => (i === index ? newResult : result))
      );
      this.setAnswer((prevAnswers) =>
        prevAnswers.map((answer, i) => (i === index ? newAnswer : answer))
      );
    } else {
      this.setAllResults((prevResults) => [...prevResults, newResult]);
      this.setAnswer((prevAnswers) => [...prevAnswers, newAnswer]);
    }

    let followUpPrompts = [];
    if (Object.keys(error).length === 0 && !dataArray?.isDemo) {
      this.setStreamLoading(true);

      // Initiate both promises without awaiting them
      const fetchStreamEventPromise = fetchStreamEvent({
        setAnswer: this.setAnswer,
        index: index,
        thread_id: this.threadId,
        question_id: this.chat_id,
        recent_messages: recent_messages,
        setStreamLoading: this.setStreamLoading,
      });

      const fetchFollowUpPromptsPromise = fetchFollowUpPrompts({
        org_id: this.user.organization,
        thread_id: this.threadId,
        question_id: this.chat_id,
        recent_messages: recent_messages,
      });

      const results = await Promise.allSettled([
        fetchStreamEventPromise,
        fetchFollowUpPromptsPromise,
      ]);

      const [fetchStreamEventResult, fetchFollowUpPromptsResult] = results;

      if (fetchStreamEventResult.status === "fulfilled") {
        newAnswer = fetchStreamEventResult.value;
      } else {
        newAnswer = sequalizerErrorMessagesMap[fetchStreamEventResult.reason];
        this.setStreamLoading(false);

        this.setAnswer((prevAnswers) => {
          const updatedAnswers = [...prevAnswers];
          updatedAnswers[updatedAnswers.length - 1] = newAnswer;
          return updatedAnswers;
        });

        Sentry.withScope((scope) => {
          scope.setTag("error_type", "explanation-streaming-error");
          scope.setLevel("error");
          Sentry.captureMessage(`Error fetching stream event: ${fetchStreamEventResult.reason}`);
        });
      }

      if (fetchFollowUpPromptsResult.status === "fulfilled") {
        followUpPrompts = fetchFollowUpPromptsResult.value;
      } else {
        Sentry.withScope((scope) => {
          scope.setTag("error_type", "follow-up-prompts-error");
          scope.setLevel("error");
          Sentry.captureMessage(
            `Error fetching follow-up prompts: ${fetchFollowUpPromptsResult.reason}`
          );
        });
      }
    }

    const updatedResult = {
      ...newResult,
      follow_up_prompts: followUpPrompts || [],
    };
    this.setResults(updatedResult);

    this.setAllResults((prev) => {
      const updatedResults = [...prev];
      updatedResults[index] = updatedResult;
      return updatedResults;
    });
    if (questionType === QuestionTypeEnum.REGENERATE) {
      updatedChatHistory(this.threadId, this.user.organization, query, updatedResult, newAnswer);
    } else {
      addToChatHistory(
        this.accountId,
        this.threads,
        this.threadId,
        this.user.organization,
        this.user.id,
        query,
        updatedResult,
        newAnswer
      );
    }
  }

  async clean_up_prediction({ org_id, thread_id, question_id, query }) {
    const chatDocRef = doc(db, "organizations", org_id, "threads", thread_id, "chats", question_id);

    try {
      const doc = await getDoc(chatDocRef);
      let data;
      if (doc.exists()) {
        data = doc.data();
        data.timestamp = firebaseTimestampToLuxon(doc.data().timestamp);
      } else {
        data = {
          answer: this.i18n._(msg`Sorry, we have run into a snag! Please try again.`),
          docId: question_id,
          error: {
            // eslint-disable-next-line lingui/no-unlocalized-strings
            message: "Savant failed to create document",
            code: 400,
            // eslint-disable-next-line lingui/no-unlocalized-strings
            name: "DocumentNotFound",
          },
          expires_at: DateTime.now().toISO(),
          query: query,
          satisfied: "UNKNOWN",
          title: query,
          visualisation: EMPTY_ROWS,
          visualisationArray: [],
          type: "PREDICTION",
          status: "error",
          timestamp: DateTime.now().toISO(),
        };

        await (chatDocRef as any).set(data); // ?
      }
      const transformedData = { ...data, id: data?.docId };
      this.setResults(transformedData);
      this.setLoadingText("");
      this.setLoading(false);
      this.setAllResults((prevResults) => [...prevResults, transformedData]);
      this.setAnswer((prevAnswers) => [...prevAnswers, data.answer]);
    } catch (err) {
      console.error("Failed to clean up prediction:", err);
      Sentry.withScope((scope) => {
        scope.setTag("error_type", "savant-error");
        scope.setLevel("error");
        Sentry.captureMessage(`Error fetching/setting Savant firestore doc: ${err}`);
      });
    }
  }

  async clean_up_chart({
    visualisation,
    query,
    sql,
    visualisationArray = [],
    followUpPrompts = [],
    error = {},
    chartConfig,
  }) {
    const tempAnswer = this.i18n._(
      msg`This is the updated chart based on your query. Please let us know if you need any more help!`
    );
    const newResult = {
      id: this.chat_id,
      query: query,
      visualisation: visualisation,
      title: query,
      satisfied: "UNKNOWN",
      error: error,
      sql: sql,
      timestamp: DateTime.now(),
      latency: parseFloat(((performance.now() - this.chat_start_time) / 1000).toFixed(2)),
      follow_up_prompts: followUpPrompts,
      chartConfig: chartConfig,
      answer: tempAnswer,
      parentDocId: this.threadId,
      parentDocType: ParentDocTypeEnum.THREAD,
    };
    this.setResults(newResult);
    this.setLoadingText("");
    this.setLoading(false);
    this.setAllResults((prevResults) => [...prevResults, newResult]);

    this.setAnswer((prevAnswers) => [...prevAnswers, tempAnswer]);

    addToChatHistory(
      this.accountId,
      this.threads,
      this.threadId,
      this.user.organization,
      this.user.id,
      query,
      newResult,
      tempAnswer
    );
  }

  initializeChat(query, index, questionType) {
    this.chat_start_time = performance.now();
    this.chat_id =
      questionType === QuestionTypeEnum.REGENERATE ? this.allResults[index].id : uuidv4();
    this.setLoadingText(this.i18n._(LOADING_START));
    this.setLoading(true);

    if (questionType !== QuestionTypeEnum.REGENERATE) {
      this.setSearchHistory((prevSearchHistory) => [...prevSearchHistory, query]);
      this.setResults({
        parentDocId: this.threadId,
        parentDocType: ParentDocTypeEnum.THREAD,
      } as Result);
    }
  }
}
