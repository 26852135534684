/* eslint-disable lingui/no-unlocalized-strings */
import { Alert, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getStatus } from "../services/webserver/status";
import PastIncidents from "./PastIncidents";
import StatusAppBar from "./StatusAppBar";
import StatusDots from "./StatusDots";
import StatusTable from "./StatusTable";
import { DEGRADED_OPERATIONS, NORMAL_OPERATIONS, OUTAGE, UNDER_MAINTENANCE } from "./constants";
import { Incidents, OperationStatus } from "./interface";

const projects = [
  "doowii-prod",
  "doowii-prod-uk",
  "doowii-prod-asia",
  "doowii-prod-au",
  "doowii-prod-ca",
];

const Status: React.FC = () => {
  const [incidents, setIncidents] = useState<Incidents>({});
  const [statuses, setStatuses] = useState<Record<string, Record<string, OperationStatus>>>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getStatus(projects);
        console.log(response.data);
        const aggregatedIncidents = aggregateIncidents(response.data);

        setIncidents(aggregatedIncidents);
        setStatuses(response.data);
      } catch (error) {
        console.error("Error fetching statuses:", error);
      }
    };

    fetchData();
  }, []);

  const aggregateIncidents = (data: any): Incidents => {
    if (!data) {
      return {};
    }
    console.log(data);
    const aggregatedIncidents: Incidents = {};
    projects.forEach((project) => {
      const projectIncidents = data[project]?.incidents_status;
      console.log(projectIncidents, data[project]);
      Object.keys(projectIncidents).forEach((date) => {
        if (!aggregatedIncidents[date]) {
          aggregatedIncidents[date] = [];
        }
        aggregatedIncidents[date].push(...projectIncidents[date]);
      });
    });
    return aggregatedIncidents;
  };

  const determineAlert = () => {
    const allStatuses = projects?.flatMap((project) => {
      const projectStatuses = statuses?.[project];
      if (!projectStatuses) return [];
      return Object.values(projectStatuses);
    });
    if (allStatuses.length === 0) return null;
    if (allStatuses?.every((status) => status === OUTAGE)) {
      return <Alert severity="error">System experiencing issues - Please check back later.</Alert>;
    } else if (allStatuses?.some((status) => status === OUTAGE || status === DEGRADED_OPERATIONS)) {
      return <Alert severity="warning">System partially operational - Some issues reported.</Alert>;
    } else if (allStatuses?.every((status) => status === NORMAL_OPERATIONS)) {
      return <Alert severity="success">System operational - No issues reported.</Alert>;
    } else if (allStatuses?.some((status) => status === UNDER_MAINTENANCE)) {
      return <Alert severity="info">System under maintenance - Please check back later.</Alert>;
    }
  };

  return (
    <div>
      <StatusAppBar />
      <Container style={{ marginTop: "100px" }} maxWidth={false}>
        <Typography variant="h4" fontWeight={"600"} color={"#153946"}>
          System Status
        </Typography>
        <br />
        {determineAlert()}
        <br />
        <StatusDots />
        <StatusTable statuses={statuses} projects={projects} />
        <br />
        <PastIncidents incidents={incidents} />
      </Container>
    </div>
  );
};

export default Status;
