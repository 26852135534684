import { FC, ReactNode } from "react";
import TopNavBar from "./TopNavBar";
import { CanvasSkeleton } from "./CanvasSkeleton/CanvasSkeleton";

interface LayoutProps {
  children: ReactNode;
  isDev: boolean;
}

export const Layout: FC<LayoutProps> = ({ children, isDev }) => {
  const content = (
    <>
      <TopNavBar />
      <div className="flex flex-1 flex-col px-3 pt-3 md:px-5 md:pt-4 lg:px-9">{children}</div>
    </>
  );

  if (isDev) {
    return (
      <div className="font-lato">
        <CanvasSkeleton>{content}</CanvasSkeleton>
      </div>
    );
  }

  return <div className="font-lato flex h-screen min-h-full flex-col">{content}</div>;
};
