import { HighchartsWithAccessibility, sharedChartOptions } from "../helpers";
import HighchartsReact from "highcharts-react-official";
import { memo, useEffect, useRef } from "react";
import { CHART_DATA_LIMIT } from "../../../Constants/constants";
import { Loading } from "../../LoadingSpinner/Loading";
import { ChartErrorState } from "../ChartErrorState/ChartErrorState";
import ChartWarning from "../ChartWarning/ChartWarning";
import { ChartHProps } from "../chart.i";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

const BarChartH = ({
  data,
  height,
  width,
  chartConfig,
  loading,
  error,
  totalRows,
  title,
  onChartInstance,
}: ChartHProps) => {
  const { _ } = useLingui();
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      const cleanup = onChartInstance(chartRef.current.chart);
      return cleanup;
    }
  }, [onChartInstance]);

  if (loading || !chartConfig || !chartConfig.columns || !data) {
    return <Loading />;
  }
  if (error) {
    return <ChartErrorState />;
  }

  const { categories, series, categoricalColumns } = data;

  const options: HighchartsWithAccessibility.Options = {
    ...sharedChartOptions,
    chart: {
      ...sharedChartOptions.chart,
      type: "bar",
      height,
      width,
    },
    title: {
      ...sharedChartOptions.title,
      text: title,
    },
    xAxis: {
      categories: categories,
      title: {
        text: categoricalColumns.join(", "),
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: _(msg`Values`),
      },
      crosshair: chartConfig.y_axis_crosshair ?? true,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: chartConfig.data_labels ?? false,
        },
      },
      series: {
        stacking: chartConfig.stack ? "normal" : undefined,
      },
    },
    series,
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: chartConfig.tooltip ?? true,
    },
    legend: {
      layout: chartConfig.legend_layout || "horizontal",
      align: "center",
      verticalAlign: chartConfig.legend_vertical_align || "bottom",
      floating: chartConfig.legend_floating ?? false,
    },
  };

  return (
    <div style={{ position: "relative" }}>
      {totalRows > CHART_DATA_LIMIT && <ChartWarning />}

      <HighchartsReact highcharts={HighchartsWithAccessibility} options={options} ref={chartRef} />
    </div>
  );
};

const MemoizedBarChartH = memo(BarChartH, (prevProps, nextProps) => {
  return (
    prevProps.data === nextProps.data &&
    prevProps.height === nextProps.height &&
    prevProps.width === nextProps.width &&
    prevProps.chartConfig === nextProps.chartConfig &&
    prevProps.totalRows === nextProps.totalRows &&
    prevProps.title === nextProps.title &&
    prevProps.onChartInstance === nextProps.onChartInstance
  );
});

export default MemoizedBarChartH;
