import { msg } from "@lingui/macro";
import pandaSrc from "../../../../../assets/canvas/waving.png";
import { PandaOnboardingType } from "../../../../types";
import { PandaOnboarding } from "../../../UI/PandaOnboarding/PandaOnboarding";
import { useLingui } from "@lingui/react";

export default function InitialPanda() {
  const { _ } = useLingui();

  const text = _(
    msg`Don't forget to pin the results that you would like to access later. When you click the 'Pin It' button, your prompt and results will be added to the Pinboard.`
  );

  return <PandaOnboarding type={PandaOnboardingType.Initial} imageSrc={pandaSrc} text={text} />;
}
