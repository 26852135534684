import { ScreenReaderContent, Select, Text } from "@instructure/ui";
import { useState } from "react";
import { PlusIcon } from "./PlusIcon";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface CustomSelectProps {
  options: Map<string, any>;
  selected: {
    label: string;
    value: string;
  };
  onSelect: (event: any, { id }: { id: string }) => void;
  createNewLabel?: string;
}

export const CustomSelect = ({
  options,
  selected,
  onSelect,
  createNewLabel,
}: CustomSelectProps) => {
  const { _ } = useLingui();
  const [isShowingOptions, setIsShowingOptions] = useState(false);
  const [highlightedOptionId, setHighlightedOptionId] = useState(null);

  const isNewOptionHighlighted = highlightedOptionId === "new";

  const handleShowOptions = () => {
    setIsShowingOptions(true);
  };

  const handleHideBoards = () => {
    setIsShowingOptions(false);
    setHighlightedOptionId(null);
  };

  const handleBlur = () => setHighlightedOptionId(null);

  const handleHighlightBoard = (event, { id }) => {
    event.persist();

    setHighlightedOptionId(id);
  };

  const handleSelect = (event, { id }) => {
    onSelect(event, { id });

    setIsShowingOptions(false);
  };

  return (
    <Select
      renderLabel={
        <ScreenReaderContent>
          <Trans>Select a pinboard</Trans>
        </ScreenReaderContent>
      }
      assistiveText={_(msg`Use arrow keys to navigate boards.`)}
      inputValue={selected.label}
      isShowingOptions={isShowingOptions}
      onBlur={handleBlur}
      onRequestShowOptions={handleShowOptions}
      onRequestHideOptions={handleHideBoards}
      onRequestHighlightOption={handleHighlightBoard}
      onRequestSelectOption={handleSelect}
      width="100%"
    >
      {Array.from(options).map(([key, board]) => (
        <Select.Option
          id={key}
          key={key}
          isHighlighted={key === highlightedOptionId}
          isSelected={key === selected.value}
        >
          <Text size="small">{board.name}</Text>
        </Select.Option>
      ))}
      {createNewLabel && (
        <Select.Group renderLabel="">
          <Select.Option
            id="new"
            renderBeforeLabel={
              <div className="ml-[2.5px]">
                <PlusIcon fill={isNewOptionHighlighted ? "white" : "#425F6A"} />
              </div>
            }
            isHighlighted={isNewOptionHighlighted}
          >
            <Text size="small">{createNewLabel}</Text>
          </Select.Option>
        </Select.Group>
      )}
    </Select>
  );
};
