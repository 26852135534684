export const LightBulbIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    style={{ marginRight: 5 }}
  >
    <path
      d="M8.00008 3.3822C7.82424 3.3822 7.64559 3.3914 7.46911 3.4094C5.23697 3.6372 3.45958 5.3754 3.33485 7.4524C3.25971 8.7054 3.77422 9.9236 4.7465 10.7946C5.55226 11.5166 6.01415 12.511 6.01415 13.5228V14.0904C6.01415 14.637 6.49553 15.0816 7.08734 15.0816H8.91259C9.50441 15.0816 9.98578 14.637 9.98578 14.0904V13.5234C9.98578 12.4986 10.4368 11.5138 11.2231 10.8218C12.1577 9.9992 12.6724 8.8898 12.6724 7.6978C12.6727 5.3182 10.5765 3.3822 8.00008 3.3822ZM10.7751 10.3874C9.86062 11.192 9.33615 12.3352 9.33615 13.5234V14.0904C9.33615 14.3062 9.14624 14.4816 8.91259 14.4816H7.08734C6.85369 14.4816 6.66378 14.3062 6.66378 14.0904V13.5228C6.66378 12.3498 6.12979 11.1984 5.19865 10.364C4.36149 9.614 3.91844 8.565 3.98319 7.4856C4.09059 5.6978 5.61982 4.2016 7.54014 4.0056C7.69323 3.99 7.84785 3.982 7.99986 3.982C10.2181 3.982 12.0228 5.6488 12.0228 7.6976C12.023 8.724 11.58 9.6792 10.7751 10.3874Z"
      fill="#0374B5"
    />
    <path
      d="M9.57206 15.4948H6.42784C6.24833 15.4948 6.10303 15.629 6.10303 15.7948C6.10303 15.9606 6.24833 16.0948 6.42784 16.0948H9.57206C9.75158 16.0948 9.89688 15.9606 9.89688 15.7948C9.89688 15.629 9.75136 15.4948 9.57206 15.4948Z"
      fill="#0374B5"
    />
    <path
      d="M9.89688 16.7474C9.89688 16.5816 9.75158 16.4474 9.57206 16.4474H6.42784C6.24833 16.4474 6.10303 16.5816 6.10303 16.7474C6.10303 16.9132 6.24833 17.0474 6.42784 17.0474H9.57206C9.75136 17.0474 9.89688 16.913 9.89688 16.7474Z"
      fill="#0374B5"
    />
    <path
      d="M9.1109 17.7C9.1109 17.5342 8.9656 17.4 8.78609 17.4H7.21398C7.03446 17.4 6.88916 17.5342 6.88916 17.7C6.88916 17.8658 7.03446 18 7.21398 18H8.78609C8.96538 18 9.1109 17.8658 9.1109 17.7Z"
      fill="#0374B5"
    />
    <path
      d="M0.324816 7.68875H2.20572C2.38523 7.68875 2.53053 7.55455 2.53053 7.38875C2.53053 7.22295 2.38523 7.08875 2.20572 7.08875H0.324816C0.145301 7.08875 0 7.22295 0 7.38875C0 7.55455 0.145301 7.68875 0.324816 7.68875Z"
      fill="#0374B5"
    />
    <path
      d="M15.6752 7.08875H13.7943C13.6148 7.08875 13.4695 7.22295 13.4695 7.38875C13.4695 7.55455 13.6148 7.68875 13.7943 7.68875H15.6752C15.8547 7.68875 16 7.55455 16 7.38875C16 7.22295 15.8547 7.08875 15.6752 7.08875Z"
      fill="#0374B5"
    />
    <path
      d="M7.67529 0.3V2.0372C7.67529 2.203 7.82059 2.3372 8.00011 2.3372C8.17962 2.3372 8.32492 2.203 8.32492 2.0372V0.3C8.32492 0.1342 8.17962 0 8.00011 0C7.82059 0 7.67529 0.1342 7.67529 0.3Z"
      fill="#0374B5"
    />
    <path
      d="M13.6569 2.16419C13.53 2.04699 13.3245 2.04699 13.1976 2.16419L11.8676 3.39259C11.7407 3.50959 11.7407 3.69979 11.8676 3.81679C11.9311 3.87539 12.0142 3.90459 12.0974 3.90459C12.1805 3.90459 12.2637 3.87539 12.3271 3.81679L13.6572 2.58839C13.7836 2.47119 13.7836 2.28119 13.6569 2.16419Z"
      fill="#0374B5"
    />
    <path
      d="M2.80251 12.6137L4.13252 11.3853C4.25942 11.2683 4.25942 11.0783 4.13252 10.9611C4.00563 10.8439 3.80013 10.8439 3.67323 10.9611L2.34322 12.1895C2.21632 12.3065 2.21632 12.4965 2.34322 12.6137C2.40667 12.6723 2.48982 12.7015 2.57297 12.7015C2.65612 12.7015 2.73906 12.6723 2.80251 12.6137Z"
      fill="#0374B5"
    />
    <path
      d="M3.67326 3.81679C3.73671 3.87539 3.81986 3.90459 3.90301 3.90459C3.98616 3.90459 4.06932 3.87539 4.13276 3.81679C4.25966 3.69979 4.25966 3.50959 4.13276 3.39259L2.80275 2.16419C2.67586 2.04699 2.47036 2.04699 2.34346 2.16419C2.21657 2.28119 2.21657 2.47139 2.34346 2.58839L3.67326 3.81679Z"
      fill="#0374B5"
    />
    <path
      d="M13.6569 12.1895L12.3269 10.9611C12.2 10.8439 11.9945 10.8439 11.8676 10.9611C11.7407 11.0783 11.7407 11.2683 11.8676 11.3853L13.1976 12.6137C13.2611 12.6723 13.3442 12.7015 13.4274 12.7015C13.5106 12.7015 13.5937 12.6723 13.6572 12.6137C13.7836 12.4965 13.7836 12.3065 13.6569 12.1895Z"
      fill="#0374B5"
    />
  </svg>
);
