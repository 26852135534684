import { msg } from "@lingui/macro";

export const FAVORITE_VIEW = "favorite";
export const CHAT_VIEW = "chat";
export const PINBOARD_VIEW = "pinboard";
export const USER_MANAGEMENT_VIEW = "USER_MANAGEMENT";
export const SETTINGS_VIEW = "SETTINGS";
export const INTEGRATIONS_VIEW = "INTEGRATIONS";
export const TABLE = "TABLE";
export const PIE_CHART = "PIE";
export const BAR_CHART = "BAR";
export const COMBINED_CHART = "COMBINED_CHART";
export const LINE_CHART = "LINE";
export const SAVANT_LINE = "SAVANT_LINE";
export const SQL_CHART = "SQL";
export const SCATTER_CHART = "SCATTER";
export const DATA_EXPLORE = "DATA_EXPLORE";
export const SCHEMA = "SCHEMA";
export const SINGLE_METRIC = "SINGLE_METRIC";

export const ASCEND_LEARNING = "ascend_learning";
export const IMAGINE_LEARNING_MAGE = "imagine_learning_mage";

export const QUESTIONS = [
  msg`How many teachers are there in my district?`,
  msg`Who are my worst performing students?`,
  msg`What is the average attendance rate per school?`,
  msg`Who are my best students`,
  msg`What's the average grade per assignment in Science courses?`,
  msg`Can you show all students between grade level 3 to 5?`,
  msg`Which students have more than 3 absences and a GPA below 90?`,
  msg`What is the tardy rate over time?`,
];

export const LOADING_PREFETCH = msg`Just a few more seconds!`;
export const LOADING_START = msg`We're on the case!`;
export const LOADING_P1 = msg`Digging through the data...Thanks for your patience!`;
export const LOADING_P2 = msg`The data is on its way, hang tight!`;
export const LOADING_P3 = msg`Just adding some finishing touches...`;
export const RETRY = msg`Hmmm, this data is tricky to find. I will try extra hard!`;

export const CANCELED = "CANCELED";
export const ERROR = "ERROR";
export const ERROR_MESSAGE = msg`Sorry, it looks like there is an error! We are working on fixing it. Please give it another try in a few moments.`;

export const EMPTY_ROWS = "EMPTY_ROWS";
export const EMPTY_ROWS_MESSAGE = msg`We did not attempt to retrieve data because your question is either too ambiguous or requests data that does not exist in the dataset. Please refine your prompt to be more specific to your dataset!`;
export const QUERY_ERROR = "QUERY_ERROR";
export const QUERY_ERROR_MESSAGE = msg`It looks like we've encountered a hiccup and can't retrieve your data right now.  Please give it another try in a few moments.`;

const _SQL = "sql";
const _VIZ = "viz";
const _ANSWER = "answer";

export class ConversationKey {
  static get SQL() {
    return _SQL;
  }
  static get VIZ() {
    return _VIZ;
  }
  static get ANSWER() {
    return _ANSWER;
  }
}

export const LOGO_TEXT = "doowii";

export const PREDICTION_LOGO_TEXT = () => (
  <span>
    doowii
    <sup style={{ fontSize: "0.4em", color: "#F5CC61", verticalAlign: "top" }}>prediction</sup>
  </span>
);

export const SQL_INIT_MESSAGE = {
  role: "system",
  // TODO: Should this be translated?
  // eslint-disable-next-line lingui/no-unlocalized-strings
  content: "You are an AI assistant trained to generate SQL.",
};

export const RESULT_INIT_MESSAGE = {
  role: "system",
  // TODO: Should this be translated?
  // eslint-disable-next-line lingui/no-unlocalized-strings
  content: "You are an AI assistant trained to analyze data results.",
};

export const DEFAULT_PINBOARD = "Default";
export const PINBOARD_NAME_CHARACTER_LIMIT = 60;

export const DEMO_ACCOUNT_UID = "PMneMCvQZvMQKuWVlp5fhyYJa9R2";

export const SATISFIED = "SATISFIED";
export const DISSATISFIED = "DISSATISFIED";

export const SUCCESS = "SUCCESS";
export const UPLOADED = "UPLOADED";
export const FAILED = "FAILED";

export const CHART_DATA_LIMIT = 5000;
export const DEFAULT_QUERY_OPTIONS = {
  page: 0,
  pageSize: 50,
};
export const PRE_FETCH_SIZE = 500;
export const PIN_PRE_FETCH_SIZE = 1000;
export const VALIDATE_FETCH_PAGE_SIZE = 10;
export const VALIDATE_FETCH_PAGE_NUMBER = 0;
export const CHART_LIMIT = 500;

export const ALERT_TIMEOUT = 3000;

export const CANVAS_FEEDBACK = "canvas_feedback";
export const DOOWII_FEEDBACK = "doowii_feedback";
export const DOOWII_FEEDBACK_COLUMN = "doowii_feedback_column";

export const GENERAL_TIPS = [
  {
    title: msg`Give Exact Names`,
    description: msg`If you are referring to a specific course or person, make sure to put their name exactly as it would appear in a database.`,
  },
  {
    title: msg`Be Specific`,
    description: msg`When referring to an item, qualify the item with what domain it is related to (courses, classes, attendance, demographics).`,
  },
  {
    title: msg`Quantify`,
    description: msg`When asking for a quality that needs to be interpreted, specify how the chatbot should interpret it.`,
  },
];
export const MATH_DEMO_TIPS = [
  {
    title: msg`Be Specific`,
    description: msg`When referring to an ID or number, qualify that item with what the data point is related to (student ID, teacher ID, grade, score, etc).`,
  },
  {
    title: msg`Time Frame`,
    description: msg`Specify a time frame for your query if applicable. For instance, you can ask for specific terms in your prompt like 22-23.`,
  },
  {
    title: msg`Interpret`,
    description: msg`Don't ask about vague measures such as "student performance" or "student participation". Create interpretable values by specifying metrics, such as "student performance by achievement level" or "student participation by number of days attended".`,
  },
];

export const UPREP_EXAMPLE_BEFORE = msg`Which students are at risk of chronic absenteeism?`;
export const UPREP_EXAMPLE_AFTER = msg`Which 9th grade students are at risk of chronic absenteeism? Define chronic absenteeism as having an attendance rate of 90% or lower.`;

export const MATH_DEMO_BEFORE = msg`What is the proficiency rate for each school?`;
export const MATH_DEMO_AFTER = msg`What is the average math proficiency rate for each school in the school year 2022-2023?`;

export const QUESTION_LENGTH_LIMIT = 500;
