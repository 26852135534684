import { Button, Heading, Modal, TextArea } from "@instructure/ui";
import { CloseButton } from "@instructure/ui-buttons";
import React, { FC, SyntheticEvent, useState } from "react";
import { useAlert } from "../../../context/alert";
import { useChatData } from "../../../context/chat";
import { withSentry } from "../../../helpers/wrapper";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

interface FeedbackModalProps {
  index: number;
  open: boolean;
  setOpen: (value: boolean) => void;
  like: string;
}

export const FeedbackModal: FC<FeedbackModalProps> = ({ index, open, setOpen, like }) => {
  const { _ } = useLingui();
  const [feedback, setFeedback] = useState("");

  const { updateIndividualSatisfaction } = useChatData();
  const { showAlert } = useAlert();

  React.useEffect(() => {
    return () => {
      setFeedback("");
    };
  }, [open]);

  const handleFormSubmit: any = withSentry(async (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      // @ts-ignore
      await updateIndividualSatisfaction(index, like, feedback);
      showAlert({
        variant: "success",
        message: _(msg`Thank you for your feedback! We will use it to improve our service.`),
      });
    } catch (e) {
      showAlert({
        variant: "error",
        message: _(msg`Failed to submit feedback. Please try again later.`),
      });
      throw e;
    } finally {
      setOpen(false);
    }
  });

  const renderCloseButton = () => {
    return (
      <CloseButton
        placement="end"
        offset="small"
        onClick={() => setOpen(false)}
        screenReaderLabel={_(msg`Close`)}
      />
    );
  };
  const headerTitle = like ? _(msg`Tell us what you liked!`) : _(msg`How can we improve?`);
  return (
    <Modal
      as="form"
      open={open}
      onDismiss={() => {
        setOpen(false);
      }}
      onSubmit={handleFormSubmit}
      size="auto"
      label={_(msg`Feedback Modal Dialog`)}
      shouldCloseOnDocumentClick
    >
      <Modal.Header>
        {renderCloseButton()}
        <Heading>{headerTitle}</Heading>
      </Modal.Header>
      <Modal.Body>
        <TextArea
          label={_(msg`Feedback (Optional)`)}
          maxHeight="10rem"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />{" "}
      </Modal.Body>
      <Modal.Footer>
        <Button color="primary" type="submit">
          {_(msg`Submit`)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
