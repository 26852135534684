import { useMemo, forwardRef } from "react";

import { Box } from "@mui/material";
import { Alert, Responsive, Table } from "@instructure/ui";

import { Theme } from "../../../styled";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

interface SortableTableProps {
  caption: string;
  headers: Array<{
    id: string;
    text: string;
    width?: string;
    renderCell?: (value: any) => React.ReactNode;
  }>;
  rows: Array<any>;
  onSortModelChange: (model: any) => void;
  sortModel: Array<{ field: string; sort: "asc" | "desc" }>;
}

export const SortableTable = forwardRef<Responsive, SortableTableProps>((props, ref) => {
  const { caption, headers, rows, onSortModelChange, sortModel } = props;
  const { _ } = useLingui();

  const sortBy = sortModel?.[0]?.field;
  const sortOrder = sortModel?.[0]?.sort;

  const sortedRows = useMemo(() => {
    if (!sortBy) return rows;

    const sorted = [...rows].sort((a, b) => {
      return a[sortBy] > b[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0;
    });

    if (sortOrder === "asc") return sorted;
    if (sortOrder === "desc") return sorted.reverse();
    return rows; // No sort
  }, [sortBy, sortOrder, rows]);

  const handleSort = (event, { id }) => {
    // Check if the column being sorted is the same as the current sorted column
    if (id === sortBy) {
      // If the current sort order is ascending, change it to descending
      if (sortOrder === "asc") {
        onSortModelChange([{ field: id, sort: "desc" }]);
        // If the current sort order is descending, clear the sort (no sort)
      } else if (sortOrder === "desc") {
        onSortModelChange([]);
        // If there is no current sort order, change it to ascending
      } else {
        onSortModelChange([{ field: id, sort: "asc" }]);
      }
    } else {
      // If a different column is being sorted, set the sort order to ascending
      onSortModelChange([{ field: id, sort: "asc" }]);
    }
  };

  const renderHeaderRow = (direction) => (
    <Table.Row>
      {(headers || []).map(({ id, text, width }) => (
        <Table.ColHeader
          key={id}
          id={id}
          width={width}
          data-testid={`column-${id}`}
          aria-label={_(msg`Column ${text}. Press Enter to sort by ascending order.`)}
          {...(direction && {
            textAlign: "start",
            stackedSortByLabel: text,
            onRequestSort: handleSort,
            sortDirection: id === sortBy ? direction : "none",
            themeOverride: { background: Theme.canvas.colors.blue, color: "white" },
          })}
        >
          {text}
        </Table.ColHeader>
      ))}
    </Table.Row>
  );

  const direction =
    sortOrder === "asc" ? "ascending" : sortOrder === "desc" ? "descending" : "none";

  return (
    <Responsive
      query={{
        small: { maxWidth: "40rem" },
        large: { minWidth: "41rem" },
      }}
      ref={ref}
    >
      {(props) => (
        <Box
          sx={{
            "& table": {
              position: "relative",
            },
            "& thead": {
              position: "sticky",
              top: 0,
              zIndex: 1,
            },
            "& tr *": {
              fontSize: "14px",
            },
          }}
        >
          <Table
            caption={_(msg`${caption}: sorted by ${sortBy} in ${direction} order`)}
            data-testid="table"
            aria-rowcount={sortedRows.length}
            {...props}
          >
            <Table.Head renderSortLabel={_(msg`Sort by`)}>{renderHeaderRow(direction)}</Table.Head>
            <Table.Body>
              {sortedRows.map((row) => (
                <Table.Row key={row.id}>
                  {headers.map(({ id, renderCell }) => (
                    <Table.Cell key={id} textAlign="start">
                      {renderCell ? renderCell(row[id]) : row[id]}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Alert
            // get the body element
            liveRegion={() => document.getElementById("table-alert")}
            liveRegionPoliteness="polite"
            screenReaderOnly
          >
            {_(msg`Sorted by ${sortBy} in ${direction} order`)}
          </Alert>
        </Box>
      )}
    </Responsive>
  );
});
