import React from "react";

const BarChartIcon: React.FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14V1H11V14H7ZM8.1 2.1H9.9V12.9H8.1V2.1Z"
      fill="#273540"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 5H1V14H5V5ZM3.9 6.1H2.1V12.9H3.9V6.1Z"
      fill="#273540"
    />
    <path d="M18 16H0V17.1H18V16Z" fill="#273540" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 7H13V14H17V7ZM15.9 8.1H14.1V12.9H15.9V8.1Z"
      fill="#273540"
    />
  </svg>
);

export default BarChartIcon;
