import { Chart as HighchartsChart } from "highcharts";
/**
 * https://stackoverflow.com/a/78708218/2221361
 * Converts an svg string into a data:image/png;base64 string.
 * @param {string} svg
 * @param {number | undefined} width
 * @param {number | undefined} height
 */
export const svg2png = async (svg, width = 800, height = 600) => {
  const img = new Image();
  img.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;

  await new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
  });

  const canvas = document.createElement("canvas");
  [canvas.width, canvas.height] = [width, height];

  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height);

  return canvas.toDataURL("image/png");
};

export const handleDownloadPNGFunc = async ({ chart, _ }: { chart: HighchartsChart; _: any }) => {
  // show the chart title when exporting png
  const chartSVG = chart.getSVG({ title: { style: { display: "auto" } } });
  const titleValue = chart.title.element.textContent;
  const chartPNG = await svg2png(chartSVG, 800 * 1.5, 600 * 1.5);
  // download the image
  const link = document.createElement("a");
  link.download = `${titleValue}.png`;
  link.href = chartPNG;
  link.click();
};
