import React from "react";

const SingleMetricIcon: React.FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" fill="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.6 0C0.716344 0 0 0.716344 0 1.6V16.4C0 17.2837 0.716344 18 1.6 18H16.4C17.2837 18 18 17.2837 18 16.4V1.6C18 0.716344 17.2837 0 16.4 0H1.6ZM2 1C1.44772 1 1 1.44772 1 2V16C1 16.5523 1.44772 17 2 17H16C16.5523 17 17 16.5523 17 16V2C17 1.44772 16.5523 1 16 1H2Z"
      fill="#2B3B46"
    />
    <path
      d="M9.79496 13.924C9.52302 13.8761 9.34143 13.6168 9.38938 13.3448L10.9522 4.48154C11.0002 4.20959 11.2595 4.02801 11.5314 4.07596C11.8034 4.12391 11.985 4.38324 11.937 4.65519L10.3742 13.5185C10.3262 13.7904 10.0669 13.972 9.79496 13.924Z"
      fill="#2B3B46"
    />
    <path
      d="M4 7.25C4 6.97386 4.22386 6.75 4.5 6.75H13.5C13.7761 6.75 14 6.97386 14 7.25C14 7.52614 13.7761 7.75 13.5 7.75H4.5C4.22386 7.75 4 7.52614 4 7.25Z"
      fill="#2B3B46"
    />
    <path
      d="M4 10.75C4 10.4739 4.22386 10.25 4.5 10.25H13.5C13.7761 10.25 14 10.4739 14 10.75C14 11.0261 13.7761 11.25 13.5 11.25H4.5C4.22386 11.25 4 11.0261 4 10.75Z"
      fill="#2B3B46"
    />
    <path
      d="M6.29497 13.924C6.02303 13.8761 5.84144 13.6168 5.88939 13.3448L7.45223 4.48154C7.50018 4.20959 7.75951 4.02801 8.03145 4.07596C8.3034 4.12391 8.48499 4.38324 8.43703 4.65519L6.8742 13.5185C6.82625 13.7904 6.56692 13.972 6.29497 13.924Z"
      fill="#2B3B46"
    />
  </svg>
);

export default SingleMetricIcon;
