/**
 * This file contains two components for measuring the height of React components:
 *
 * 1. MeasureComponent: A wrapper component that measures the height of its children.
 *
 * 2. measureComponentHeight: A function that creates a temporary div, renders a component
 *    inside it, measures its height, and then removes the div. It returns a promise that resolves
 *    to an object containing the height of the component and the temporary div in memory.
 *
 * Note: With React 18's concurrent rendering, this process is more complex than in React 17.
 * The createRoot API is used instead of the synchronous ReactDOM.render, and we need to
 * handle the asynchronous nature of the rendering process carefully.
 */

import { useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";

export const MeasureComponent = ({ children, onMeasure }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      onMeasure(ref.current.offsetHeight);
    }
  }, [onMeasure]);
  return <div ref={ref}>{children}</div>;
};

export const measureComponentHeight = (
  Component: React.ReactElement,
  maxWidth?: number
): Promise<{ height: number; tempDiv: HTMLDivElement }> => {
  return new Promise((resolve) => {
    const tempDiv = document.createElement("div");
    document.body.style.overflow = "hidden";
    tempDiv.style.maxWidth = `${maxWidth}px`;
    const root = createRoot(tempDiv);

    root.render(
      <MeasureComponent
        onMeasure={(height) => {
          resolve({ height, tempDiv });
          setTimeout(() => {
            document.body.removeChild(tempDiv);
            root.unmount();
            document.body.style.overflow = "";
          }, 0);
        }}
      >
        {Component}
      </MeasureComponent>
    );

    document.body.appendChild(tempDiv);
  });
};
