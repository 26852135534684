import { Flex, Text } from "@instructure/ui";
import { FC, MutableRefObject, useEffect, useState } from "react";
import pandaSrc from "../../../../../../assets/canvas/analytics.png";
import { useChatData } from "../../../../../../context/chat";
import { Doowii } from "../../../../../../search/Doowii";
import { PandaOnboardingType } from "../../../../../types";
import { PandaOnboarding } from "../../../../UI/PandaOnboarding/PandaOnboarding";
import CancelButton from "../CancelButton/CancelButton";
import LoadingBar from "./LoadingBar";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface LoadingPandaProps {
  loadingText: string;
  progressValue: number;
  setProgressValue: React.Dispatch<React.SetStateAction<number>>;
  doowii: MutableRefObject<Doowii | null>;
}

export const LoadingPanda: FC<LoadingPandaProps> = ({
  loadingText,
  progressValue,
  setProgressValue,
  doowii,
}) => {
  const { _ } = useLingui();
  const { loading } = useChatData();
  const [onboardingText, setOnboardingText] = useState<string>("");
  const onboardingTexts = [
    msg`Do you want to see your previous questions? Go to My Chats dropdown box and navigate to the one you are looking for`,
    msg`Simply tap the new chat button above the chat window to kick off a new conversation! We can't wait to see what you'll chat about next.`,
    msg`You can download the result as CSV by going to three dots and clicking 'Download Data as CSV' button.`,
    msg`Do you want to see the chart? Click columns that you want to visualize and click chart type you want.`,
    msg`Help us improve!  After reviewing the results, please provide feedback with a thumbs up or thumbs down.`,
  ];

  useEffect(() => {
    const index = Math.floor(progressValue / 20);
    if (index < onboardingTexts.length) {
      setOnboardingText(_(onboardingTexts[index]));
    }
  }, [progressValue]);

  return (
    <div className="relative ml-3 mt-3 flex flex-1">
      <div
        className="absolute bottom-0 left-0 right-0 top-0 flex h-full flex-1 overflow-y-auto"
        data-testid="loading-panda"
      >
        <Flex direction="column" justifyItems="center" margin="medium" gap="medium">
          <Flex.Item textAlign="center">
            <Text> {loadingText}</Text>
          </Flex.Item>
          <Flex.Item>
            <div className="my-3 flex flex-row flex-nowrap ">
              <div className="w-11/12 flex-initial">
                <LoadingBar progressValue={progressValue} setProgressValue={setProgressValue} />
              </div>
              <div className="flex-none">{loading && <CancelButton doowii={doowii} />}</div>
            </div>
          </Flex.Item>
          <Flex.Item margin="x-large none">
            <PandaOnboarding
              type={PandaOnboardingType.Loading}
              imageSrc={pandaSrc}
              text={onboardingText}
            ></PandaOnboarding>
          </Flex.Item>
        </Flex>
      </div>
    </div>
  );
};
