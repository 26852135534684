import { useState, useEffect } from 'react';
import { Result } from '../context/chat/chat.i';
import { Pin } from '../context/pinboard/pinboard.i';
import { ChartColumnType, PlottableChartTypes } from '../canvasApp/components/ChartBuilder/ChartBuilder';
import { ViewEnum } from '../context/ui/ui.i';
import { useChatData } from '../context/chat';
import { usePinboard } from '../context/pinboard';
import { ChartType } from '../canvasApp/types';

interface UseKPIChartProps {
  result: Result | Pin;
  selectedChart: PlottableChartTypes | null;
  view: ViewEnum.CHAT_VIEW | ViewEnum.PINBOARD_VIEW;
  columns: ChartColumnType[];
}

export const useKPIChart = ({
  result,
  selectedChart,
  view,
  columns,
}: UseKPIChartProps) => {
  const { dataMap, updateResultsFields } = useChatData();
  const { updatePinFields } = usePinboard();

  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [KPIName, setKPIName] = useState('');

  // Determine if data should be fetched
  useEffect(() => {
    if (result?.id && !dataMap[result.id]) {
      setShouldFetchData(true);
    }
    if (result?.id && dataMap[result.id]) {
      setShouldFetchData(false);
    }
  }, [result, dataMap]);

  // Initialize KPIName based on chartConfig or columns
  useEffect(() => {
    if (result?.id) {
      const chartConfig = result.chartConfig || (result as Pin).chart_config;
      if (selectedChart === ChartType.SINGLE_METRIC) {
        setKPIName(chartConfig?.options?.KPIName || columns[0]?.name);
      }
    }
  }, [result?.id, selectedChart, columns]);

  // Update chartConfig when KPIName changes
  useEffect(() => {
    if (selectedChart === ChartType.SINGLE_METRIC) {
      const currentKPIName = result.chartConfig?.options?.KPIName || '';
      if (KPIName !== currentKPIName) {
        if (view === ViewEnum.CHAT_VIEW) {
          updateResultsFields({
            chartConfig: {
              ...result.chartConfig,
              options: { ...result.chartConfig?.options, KPIName },
            },
          });
        } else {
          updatePinFields(result?.id, {
            chartConfig: {
              ...result.chartConfig,
              options: { ...result.chartConfig?.options, KPIName },
            },
          });
        }
      }
    }
  }, [
    selectedChart,
    KPIName,
    result.chartConfig,
    updateResultsFields,
    updatePinFields,
    result,
    view,
  ]);

  return {
    shouldFetchData,
    KPIName,
    setKPIName,
  };
};
