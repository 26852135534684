const countToken = (message) => {
  let tokenCount = 0;
  let text = message?.content;

  if (text === null || text === undefined) {
    return tokenCount;
  }

  if (typeof text !== "string") {
    text = String(text);
  }

  // Regular expression to split by word boundaries, punctuation, and special characters
  const regex = /(\b|[.,/#!$%^&*;:{}=\-_`~()?[\]"'<>|\\+])+/;

  // Regular expression to match emojis
  const emojiRegex = /[\p{Emoji}]/gu;
  const tokens = text.split(regex);

  for (let token of tokens) {
    if (token.match(emojiRegex)) {
      // Count each emoji as a separate token
      tokenCount += [...token.matchAll(emojiRegex)].length;
    } else if (token.trim() !== "") {
      // Count each non-empty string as a token
      tokenCount++;
    }
  }
  return tokenCount;
};

export function createNode(value) {
  let value_token_size = countToken(value);

  return {
    value: value,
    size: value_token_size,
    next: null,
  };
}

interface Node {
  value: string;
  size: number;
  next: Node | null;
}

export default class LinkedList {
  head: Node | null;
  tail: Node | null;
  length: number;

  constructor() {
    this.head = null;
    this.tail = null;
    this.length = 0;
  }

  append(value) {
    // increment entry count
    this.length++;
    let newNode = createNode(value);
    if (this.tail) {
      this.tail.next = newNode;
      this.tail = newNode;
      return newNode;
    }
    this.head = this.tail = newNode;
    return newNode;
  }

  remove() {
    if (this.head) {
      this.length--;
      let headNode = this.head;
      this.head = headNode.next;
      headNode.next = null;
      if (this.length === 0) {
        this.head = null;
        this.tail = null;
      }
      return headNode;
    } else {
      return undefined;
    }
  }
}
