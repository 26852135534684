import { withSentry } from "../helpers/wrapper";
import { retrieverPost } from "./base";

import {
  FetchChartDataParams,
  FetchPageDataParams,
  ParentDocTypeEnum,
  Headers,
  retrieverResponse,
} from "./retriever.i";

function renameIdKeys(data) {
  // Rename the id key to id_ to avoid conflict with the data-grid id key
  data.rows = data.rows.map((item) => {
    if ("id" in item) {
      const newItem = {};
      Object.keys(item).forEach((key) => {
        if (key === "id") {
          newItem["id_"] = item[key];
        } else {
          newItem[key] = item[key];
        }
      });
      return newItem;
    }
    return item;
  });

  if (data.hyperlink_columns) {
    let hyperlinkColumns = data.hyperlink_columns;
    if (hyperlinkColumns.hasOwnProperty("courses") && hyperlinkColumns.courses.includes("id")) {
      const index = hyperlinkColumns.courses.indexOf("id");
      hyperlinkColumns.courses[index] = "id_";
    }
    if (hyperlinkColumns.hasOwnProperty("gradebook") && hyperlinkColumns.gradebook.includes("id")) {
      const index = hyperlinkColumns.gradebook.indexOf("id");
      hyperlinkColumns.gradebook[index] = "id_";
    }
    data.hyperlink_columns = hyperlinkColumns;
  }
}

const fetchPageData = withSentry(
  async ({
    page_size,
    page_number,
    doc_id,
    parent_doc_id,
    parent_doc_type,
    sort_model = [],
    retrieval_type = "query",
    user_defined_query = false,
  }: FetchPageDataParams): Promise<{ data: retrieverResponse; headers: Headers }> => {
    try {
      const retrieve_all_data = true;
      const response =
        retrieval_type === "query"
          ? await retrieverPost("retrieve", {
              doc_id,
              parent_doc_id,
              parent_doc_type,
              page_size,
              page_number,
              sort_model,
              user_defined_query,
            })
          : await retrieverPost("retrieve", {
              doc_id,
              parent_doc_id,
              parent_doc_type,
              retrieval_type,
              retrieve_all_data,
              user_defined_query,
            });

      renameIdKeys(response.data);

      const headers = response.data.rows.length > 0 ? Object.keys(response.data.rows[0]) : [];

      return {
        data: response.data,
        headers: headers,
      };
    } catch (error) {
      throw error;
    }
  }
);

const fetchAllData = withSentry(
  async function fetchAllData(filename, doc_id, parent_doc_id, parent_doc_type) {
    try {
      const response = await retrieverPost("retrieve/download", {
        doc_id,
        parent_doc_id,
        parent_doc_type,
        filename,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const fetchChartData = async ({
  docId,
  parentDocId,
  parentDocType,
  chartType,
  chartConfig,
  selectedColumns,
}: FetchChartDataParams): Promise<any> => {
  const response = await retrieverPost("retrieve/chart", {
    doc_id: docId,
    parent_doc_id: parentDocId,
    parent_doc_type: parentDocType,
    chart_type: chartType.toLowerCase(),
    columns: selectedColumns.reduce((acc, column) => {
      acc[column] = {
        name: column,
        type: chartConfig.column_types[column],
      };
      return acc;
    }, {}),
    column_aggregations: chartConfig.column_aggregations || {},
    chart_limit: 100000,
  });
  return response.data;
};

export { fetchAllData, fetchChartData, fetchPageData };
