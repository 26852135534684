import * as Sentry from "@sentry/react";

/**
 * A wrapper for async functions to handle and report errors to Sentry.
 * @param {Function} asyncFunc The async function to be wrapped.
 * @returns {Function} The wrapped async function with error handling.
 */
export const withSentry = <Func extends (...args: any[]) => any>(
  asyncFunc: Func
): ((...args: Parameters<Func>) => ReturnType<Func>) => {
  return (...args: Parameters<Func>): ReturnType<Func> => {
    try {
      return asyncFunc.apply(this, args);
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTag("log-tag", "frontend-network-error");
        scope.setLevel("fatal");
        Sentry.captureMessage(`A fatal error occurred in the frontend: ${error}`);
      });
      throw error;
    }
  };
};
