import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useRef, useState } from "react";

export const useResize = () => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [entries, setEntries] = useState([]);

  const componentRef = useRef(null);
  const debouncedEntries = useDebounce(entries, 400);

  useEffect(() => {
    if (!entries?.length) return;

    for (const entry of debouncedEntries) {
      const newHeight = entry.contentRect.height;
      const newWidth = entry.contentRect.width;

      if (newHeight !== height || newWidth !== width) {
        setHeight(newHeight);
        setWidth(newWidth);
      }
    }
  }, [debouncedEntries]);

  useEffect(() => {
    if (componentRef.current) {
      const resizeObserver = new ResizeObserver(setEntries);
      resizeObserver.observe(componentRef.current);

      return () => {
        if (resizeObserver) {
          resizeObserver.disconnect();
        }
      };
    }
  }, [componentRef.current]);

  return { height, width, componentRef };
};
