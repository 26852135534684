import { ProgressBar, Text } from "@instructure/ui";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";

interface LoadingBarProps {
  progressValue: number;
  setProgressValue: React.Dispatch<React.SetStateAction<number>>;
}

const LoadingBar: React.FC<LoadingBarProps> = ({ progressValue }) => {
  const { _ } = useLingui();
  const valueMAX = 100;

  return (
    <>
      <ProgressBar
        screenReaderLabel={_(msg`Loading progress bar`)}
        formatScreenReaderValue={({ valueNow, valueMax }) => {
          return Math.round((valueNow / valueMax) * 100) + " percent";
        }}
        renderValue={({ valueNow, valueMax }) => {
          return <Text weight="bold">{Math.round((valueNow / valueMax) * 100)}%</Text>;
        }}
        valueNow={progressValue}
        valueMax={valueMAX}
        shouldAnimate
      />
    </>
  );
};

export default LoadingBar;
