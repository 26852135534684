import { Spinner } from "@instructure/ui";

import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

export const ButtonLoader = () => {
  const { _ } = useLingui();
  return (
    <Spinner
      renderTitle={_(msg`Loading`)}
      size="x-small"
      margin="0"
      themeOverride={{ xSmallSize: "26px" }}
    />
  );
};
