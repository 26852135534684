import { Text, TextInput, Tooltip } from "@instructure/ui";
import React, { ChangeEvent, useCallback } from "react";

interface ChartTitleProps {
  results: any;
  value: string;
  readOnly: boolean;
  setValue: (value: string) => void;
}

export const ChartTitle = ({ results, value, readOnly, setValue }: ChartTitleProps) => {
  const handleChange = useCallback((e: ChangeEvent, value: string) => {
    setValue(value);
  }, []);

  React.useEffect(() => {
    setValue(results?.title);
  }, [results]);

  if (!readOnly) return <TextInput renderLabel={""} value={value} onChange={handleChange} />;

  return (
    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
      <Tooltip renderTip={value} placement="top">
        <Text>{value}</Text>
      </Tooltip>
    </div>
  );
};
