import React from "react";

const LineChartIcon: React.FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" />
    <path
      d="M13.3536 1.64645C13.253 1.54588 13.1144 1.4929 12.9724 1.50077C12.8304 1.50863 12.6985 1.5766 12.6096 1.68765L8.86213 6.37202L5.22366 4.55279C4.99812 4.44002 4.7239 4.51284 4.58403 4.72265L0.584028 10.7227C0.430852 10.9524 0.492939 11.2628 0.722703 11.416C0.952467 11.5692 1.2629 11.5071 1.41608 11.2774L5.17124 5.64461L8.77645 7.44721C8.98732 7.55265 9.2432 7.49645 9.39049 7.31235L13.0415 2.74857L16.6465 6.35355C16.8418 6.54882 17.1583 6.54882 17.3536 6.35355C17.5489 6.15829 17.5489 5.84171 17.3536 5.64645L13.3536 1.64645Z"
      fill="#273540"
    />
    <path
      d="M1.00005 14.5C0.723911 14.5 0.500053 14.7239 0.500053 15C0.500053 15.2761 0.723911 15.5 1.00005 15.5H17.0001C17.2762 15.5 17.5001 15.2761 17.5001 15C17.5001 14.7239 17.2762 14.5 17.0001 14.5H1.00005Z"
      fill="#273540"
    />
  </svg>
);

export default LineChartIcon;
