// components/ActionsOptionsContainer.tsx
import { Button, IconButton, Menu, Tooltip } from "@instructure/ui";
import { IconTrashLine } from "@instructure/ui-icons";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC } from "react";
import { useAuth } from "../../../../../context";
import useBreakpoint from "../../../../../hooks/useBreakpoint";
import { ActionsOptionsContainerProps } from "../types";
import { SINGLE_METRIC } from "../../../../../Constants/constants";

/**
 * Component for rendering action buttons or dropdown based on breakpoint.
 */
export const ActionsOptionsContainer: FC<ActionsOptionsContainerProps> = ({
  pinResult,
  handleDownloadCSV,
  handleDownloadPDF,
  handleDownloadPNG,
  setDeleteChartModalOpen,
  variant,
}) => {
  const { _ } = useLingui();
  const { isChatDesktop } = useBreakpoint();
  const { featureFlags } = useAuth();

  return isChatDesktop ? (
    <div className="flex items-center gap-1">
      <Menu
        withArrow={false}
        placement="bottom start"
        offsetY={10}
        trigger={
          <Tooltip renderTip={_(msg`Download pin`)} placement="top">
            <Button color="secondary">
              <Trans>Download</Trans>
            </Button>
          </Tooltip>
        }
      >
        <Menu.Group label={_(msg`Download Pin as`)}>
          <Menu.Separator />
          <Menu.Item onSelect={handleDownloadCSV}>
            <Trans>Comma Separated values (.csv)</Trans>
          </Menu.Item>

          <Menu.Item
            onSelect={handleDownloadPNG}
            disabled={
              pinResult?.visualisation === "TABLE" || pinResult?.visualisation === SINGLE_METRIC
            }
          >
            <Trans>PNG image (.png)</Trans>
          </Menu.Item>

          <Menu.Item onSelect={handleDownloadPDF}>
            <Trans>PDF Document (.pdf)</Trans>
          </Menu.Item>
        </Menu.Group>
      </Menu>
      {variant === "fullscreen" && (
        <>
          |
          <Tooltip renderTip={_(msg`Delete chart`)} placement="top">
            <IconButton
              withBackground={false}
              withBorder={false}
              screenReaderLabel={_(msg`Delete chart`)}
              onClick={() => setDeleteChartModalOpen(true)}
            >
              <IconTrashLine />
            </IconButton>
          </Tooltip>
        </>
      )}
    </div>
  ) : (
    <div className="flex self-end">
      <IconButton
        screenReaderLabel={_(msg`Delete pin`)}
        onClick={() => setDeleteChartModalOpen(true)}
      >
        <IconTrashLine />
      </IconButton>
    </div>
  );
};
