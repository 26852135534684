import { View } from "@instructure/ui";
import { Alert } from "@instructure/ui-alerts";
import { Spinner } from "@instructure/ui-spinner";
import { FC, useEffect, useState } from "react";
import { getCurrentUserAuthorization, retrieverApi } from "../../../../../../api/base";
import SQLDiagram from "../../../../../../components/SQLDiagram/SQLDiagram";
import useBreakpoint from "../../../../../../hooks/useBreakpoint";

interface SQLEditorProps {
  sql: string;
}

export const SQLDiagramHandler: FC<SQLEditorProps> = ({ sql }) => {
  const [tables, setTables] = useState([]);
  const [columns, setColumns] = useState({});
  const [joins, setJoins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { isChatDesktop } = useBreakpoint();

  useEffect(() => {
    if (sql) {
      updateSQL(sql);
    }
  }, [sql]);

  const updateSQL = async (sql: string) => {
    setIsLoading(true);
    setError(null);
    try {
      const token = await getCurrentUserAuthorization();
      const response = await retrieverApi.post(
        "retrieve/sql_metadata",
        { sql },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const sqlMetadata = response.data;
      setTables(sqlMetadata.tables);
      setJoins(sqlMetadata.joins);
      setColumns(sqlMetadata.columns);
    } catch (error) {
      console.error("Error posting data: ", error);
      setError("Failed to load SQL metadata. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex-[2]">
      <View
        borderWidth="0"
        display="inline-block"
        width="100%"
        height={isChatDesktop ? "85vh" : "70vh"}
        padding="0"
        margin="0"
      >
        {isLoading ? (
          <View textAlign="center" padding="medium">
            <Spinner renderTitle="Loading SQL diagram" size="medium" />
            <View margin="small 0 0 0">Loading SQL diagram...</View>
          </View>
        ) : error ? (
          <Alert variant="error" margin="small">
            {error}
          </Alert>
        ) : tables.length > 0 ? (
          <SQLDiagram tables={tables} columns={columns} joins={joins} />
        ) : (
          <Alert variant="info" margin="small">
            No tables found in the SQL query.
          </Alert>
        )}
      </View>
    </div>
  );
};
