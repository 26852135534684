import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseApp } from "../services/firebase/connection";

export const getFirebaseFunctionsInstance = () => {
  return getFunctions(firebaseApp);
};

export const createHttpsCallable = (functionsInstance, functionName) => {
  return httpsCallable(functionsInstance, functionName);
};
