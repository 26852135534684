import { Button, CloseButton, Heading, Modal, Text } from "@instructure/ui";
import { FormEvent, useState } from "react";

import { useAlert } from "../../../../context/alert";
import { withSentry } from "../../../../helpers/wrapper";
import { ButtonLoader } from "../ButtonLoader/ButtonLoader";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";

interface DeleteModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  name: string;
  action: () => Promise<void>;
  text?: string;
}

export const DeleteModal = ({ open, setOpen, name, action, text }: DeleteModalProps) => {
  const { _ } = useLingui();
  const handleButtonClick = () => {
    setOpen(!open);
  };

  const [loading, setLoading] = useState(false);

  const { showAlert } = useAlert();
  const handleFormSubmit: any = withSentry(async (e: FormEvent<EventTarget>) => {
    e.preventDefault();
    try {
      setLoading(true);
      await action();
      showAlert({
        variant: "success",
        message: _(msg`${name} deleted successfully`),
      });
    } catch (e) {
      console.error(e);
      showAlert({
        variant: "error",
        message: _(msg`Failed to delete ${name}. Please try again later.`),
      });
    } finally {
      setLoading(false);
      setOpen(false);
    }
  });

  const lowerCaseName = name.toLowerCase();

  return (
    <Modal
      as="form"
      open={open}
      onDismiss={() => setOpen(false)}
      onSubmit={handleFormSubmit}
      size="small"
      label={_(msg`Modal Dialog: Delete ${name}`)}
      shouldCloseOnDocumentClick={!loading}
    >
      <Modal.Header>
        <Heading>
          <Trans>Delete {name}</Trans>
        </Heading>
        <CloseButton
          placement="end"
          offset="small"
          onClick={() => setOpen(false)}
          screenReaderLabel={_(msg`Close`)}
          size="medium"
          margin="small"
          disabled={loading}
        />
      </Modal.Header>
      <Modal.Body padding="large medium">
        <Text>
          {text ?? (
            <Trans>
              Are you sure you want to delete {lowerCaseName}? This action can not be undone.
            </Trans>
          )}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleButtonClick} disabled={loading} margin="0 x-small 0 0">
          <Trans>Cancel</Trans>
        </Button>
        <Button
          color="danger"
          disabled={loading}
          type="submit"
          renderIcon={loading && <ButtonLoader />}
        >
          <Trans>Delete</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
