/* eslint-disable lingui/no-unlocalized-strings */
import { AppBar, Toolbar, Typography } from "@mui/material";

export default function StatusAppBar() {
  return (
    <AppBar style={{ backgroundColor: "#153946" }}>
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            textAlign: "left",
            fontSize: "2.9em",
            fontFamily: '"Outfit"',
            fontWeight: 600,
          }}
        >
          d<span style={{ color: "#3CA0C3" }}>o</span>
          <span style={{ color: "#F5CC61" }}>o</span>
          wii
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
