import {
  IconArrowOpenDownLine,
  IconArrowOpenUpLine,
  Link,
  Text,
  ToggleDetails,
} from "@instructure/ui";
import * as Sentry from "@sentry/react";

import { Trans } from "@lingui/macro";
import { MutableRefObject } from "react";
import { useAuth } from "../../../../../../../../../context/auth";
import { useChatData } from "../../../../../../../../../context/chat";
import { useUI } from "../../../../../../../../../context/ui";
import { getRecentMessage } from "../../../../../../../../../helpers/getRecentMessage";
import { Doowii } from "../../../../../../../../../search/Doowii";
import { QuestionTypeEnum } from "../../../../../../../../../search/Doowii.i";

interface SuggestionsProps {
  followUpQuestions: string[];
  doowii: MutableRefObject<Doowii | null>;
  single: boolean;
}

export const FollowUpQuestions = ({ doowii, followUpQuestions = [], single }: SuggestionsProps) => {
  const { allResults, answer, loading } = useChatData();
  const { userDocument } = useAuth();
  const { model } = useUI();

  const handleClick = (suggestion: string) => {
    Sentry.withScope((scope) => {
      scope.setTag("log-tag", "RefinedQuestionClicked");
      scope.setTag("user_id", userDocument?.email);
      Sentry.captureMessage(`User: ${userDocument?.email} clicked refined question: ${suggestion}`);
    });

    doowii.current.chat({
      query: suggestion,
      index: allResults.length,
      model: model,
      recent_messages: getRecentMessage({ allResults, answer }),
      questionType: QuestionTypeEnum.REFINEMENT,
    });
  };

  return (
    <div className="flex flex-col rounded-bl-lg rounded-br-lg bg-[#F5F5F5] p-3">
      <ToggleDetails
        summary={<Trans>Refinement Suggestions</Trans>}
        size="small"
        themeOverride={{
          fontWeight: 700,
          iconMargin: "10px",
        }}
        icon={() => (
          <IconArrowOpenDownLine
            size="small"
            themeOverride={{
              sizeSmall: "12px",
            }}
          />
        )}
        iconExpanded={() => (
          <IconArrowOpenUpLine
            size="small"
            themeOverride={{
              sizeSmall: "12px",
            }}
          />
        )}
        defaultExpanded={single}
      >
        <div className="flex flex-col">
          {followUpQuestions.slice(0, 3).map((suggestion, i) => (
            <Link
              data-testid="refinement-link"
              isWithinText={false}
              key={i}
              forceButtonRole
              onClick={() => {
                handleClick(suggestion);
              }}
              disabled={loading}
            >
              <span className="ml-[9px]">
                <Text size="small" weight="bold">
                  {suggestion}
                </Text>
              </span>
            </Link>
          ))}
        </div>
      </ToggleDetails>
    </div>
  );
};
