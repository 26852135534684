import { saveAs } from "file-saver";

import { DEFAULT_QUERY_OPTIONS } from "../Constants/constants";
import { fetchAllData } from "../api/retriever";
import { Analytics } from "../services/analytics/Analytics";
import { withSentry } from "./wrapper";

// Sanitize according to these rules https://cloud.google.com/storage/docs/buckets
export const sanitizeFilename = (filename: string): string => {
  filename = filename.toLowerCase();

  // Remove any characters that are not lowercase letters, numbers, dashes, underscores, or dots
  filename = filename.replace(/[^a-z0-9._-]/g, "");

  return filename;
};

const formatForSetRows = (rows: any[]) => {
  const keys = Array.from({ length: DEFAULT_QUERY_OPTIONS.pageSize }, (_, index) => index);

  return keys
    .map((idx) => ({ id: idx, ...rows[idx] }))
    .filter((row) => {
      const { id, ...rest } = row;
      return Object.values(rest).some((value) => value !== undefined);
    });
};

export const downloadPredictionData = withSentry(async (currentResult, filename, dataMap) => {
  try {
    if (dataMap.hasOwnProperty(currentResult.id)) {
      const rows = formatForSetRows(dataMap[currentResult.id].rows);
      const headers = Object.keys(rows[0]).join(",");
      const csvRows = rows.map((row) =>
        Object.values(row)
          .map((value) => `"${String(value).replace(/"/g, '""')}"`)
          .join(",")
      );

      const csvContent = "data:text/csv;charset=utf-8," + [headers, ...csvRows].join("\n");
      const encodedUri = encodeURI(csvContent);
      saveAs(encodedUri, filename);
    } else {
      throw new Error("Error fetching data");
    }
  } catch (error) {
    throw error;
  }
});

export const downloadCSV = withSentry(
  async ({
    filename,
    currentResult = {},
    dataMap = {},
    doc_id,
    parent_doc_id,
    parent_doc_type,
  }: {
    filename: string;
    currentResult?: any;
    dataMap?: any;
    doc_id: string;
    parent_doc_id: string;
    parent_doc_type: string;
  }) => {
    if (currentResult.type === "PREDICTION") {
      await downloadPredictionData(currentResult, filename, dataMap);
    } else {
      try {
        const sanitizedFilename = sanitizeFilename(filename);

        let url;
        try {
          url = await fetchAllData(sanitizedFilename, doc_id, parent_doc_id, parent_doc_type);
        } catch (error) {
          throw error;
        }

        if (!url) {
          console.error("Error fetching data");
          throw new Error("Error fetching data");
        }

        //make a get request to the url
        const response = await fetch(url);
        const blob = await response.blob();
        saveAs(blob, filename);
      } catch (error) {
        throw error;
      } finally {
        Analytics.track("Download CSV");
      }
    }
  }
);
