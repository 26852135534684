import { Alert, CondensedButton, Heading, IconArrowOpenStartLine, Tray } from "@instructure/ui";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useEffect, useState } from "react";
import { EMPTY_ROWS, ERROR } from "../../../Constants/constants";
import { useChart } from "../../../context/chart";
import { useChatData } from "../../../context/chat";
import { useDrawer } from "../../../context/mobileDrawers";
import { ViewEnum } from "../../../context/ui/ui.i";
import PinButton from "../ChatTab/ResultArea/PinButton";
import { FullscreenModal } from "../ChatTab/ResultArea/Whiteboard/FullscreenModal/FullscreenModal";
import { SQLTray } from "../ChatTab/ResultArea/Whiteboard/SQLTray/SQLTray";
import { DropdownMenu } from "../ChatTab/ResultArea/Whiteboard/WhiteboardHeader/DropdownMenu/DropdownMenu";
import { DataVisualizationTabs } from "../DataVisualizationTabs/DataVisualizationTabs";
import { Feedback } from "../Feedback/Feedback";
import { Loader } from "../UI/Loader/Loader";

type ResultDrawerProps = {
  loading: boolean;
  loadingText: string;
  loadingThread: boolean;
};
const ResultDrawer: React.FC<ResultDrawerProps> = ({ loading, loadingText, loadingThread }) => {
  const { _ } = useLingui();
  const [totalRows, setTotalRows] = useState(0);
  const { currentResult: result, allResults } = useChatData();
  const { setSelectedColumns } = useChart();
  const [chartType, setChartType] = useState(null);
  const [sqlTrayOpen, setSqlTrayOpen] = useState(false);
  const [fullScreenOpen, setFullScreenOpen] = useState(false);

  const {
    drawers: { result: isResultDrawerOpen, pin: isPinDrawerOpen },
    setDrawerState,
  } = useDrawer();

  useEffect(() => {
    setChartType(result?.visualisation);
  }, [result]);

  const invalidState =
    result?.visualisation === EMPTY_ROWS ||
    result?.visualisation === ERROR ||
    (result?.visualisation && totalRows === 0);

  return (
    <>
      <Tray
        label={_(msg`Result drawer`)}
        open={isResultDrawerOpen}
        onDismiss={() => setDrawerState("result", false)}
        size="large"
        placement="end"
      >
        {loadingThread ? (
          <div className="flex items-center justify-center pt-20 ">
            <Loader message={_(msg`Loading Thread`)} />
          </div>
        ) : (
          <div className="flex flex-col p-3 pt-20 ">
            <CondensedButton
              renderIcon={<IconArrowOpenStartLine />}
              onClick={() => setDrawerState("result", false)}
            >
              <Trans>Insights Chats</Trans>
            </CondensedButton>

            <Heading margin="medium 0">{result?.title || ""}</Heading>

            <div className="flex gap-2">
              <div className="flex-1">
                <PinButton
                  full
                  isOpen={isPinDrawerOpen}
                  chartType={chartType}
                  disabled={loading || invalidState}
                  onClick={() => setDrawerState("pin", true)}
                  setIsOpen={(isOpen) => setDrawerState("pin", isOpen)}
                />
              </div>
              <DropdownMenu
                setSqlTrayOpen={setSqlTrayOpen}
                disabled={loading}
                invalidState={invalidState}
                setFullScreenOpen={setFullScreenOpen}
              />
            </div>

            <div className="relative my-5 flex justify-center">
              <div className="flex h-[450px] flex-1">
                <DataVisualizationTabs
                  result={result}
                  chartType={chartType}
                  setChartType={setChartType}
                  setTotalRows={setTotalRows}
                  didCreateChart={(chartProps) => {
                    setSelectedColumns(chartProps.selectedColumns);
                    setChartType(chartProps.chartType);
                  }}
                  view={ViewEnum.CHAT_VIEW}
                />
              </div>
            </div>
            <Alert variant="info" margin="small">
              <Trans>Ask Your Data uses queries generated by AI. Check the results.</Trans>
            </Alert>

            <Feedback
              index={allResults.findIndex((res) => res.id === result.id)}
              disabled={loading}
            />
          </div>
        )}
      </Tray>
      <SQLTray results={result} sqlTrayOpen={sqlTrayOpen} setSqlTrayOpen={setSqlTrayOpen} />
      <FullscreenModal
        open={fullScreenOpen}
        setOpen={setFullScreenOpen}
        result={result}
        chartType={chartType}
        setChartType={setChartType}
      />
    </>
  );
};

export default ResultDrawer;
