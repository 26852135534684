import { CloseButton, Heading } from "@instructure/ui";
import { FC } from "react";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

interface SQLTrayCloseButtonProps {
  onClose: () => void;
}

export const SQLTrayCloseButton: FC<SQLTrayCloseButtonProps> = ({ onClose }) => {
  const { _ } = useLingui();
  return (
    <div className="flex items-center justify-between border-b-2 border-b-neutral-300 pb-6">
      <div className="mt-4 chat-desktop:mt-0">
        <Heading>
          <Trans>SQL</Trans>
        </Heading>
      </div>
      <CloseButton
        placement="end"
        size="medium"
        offset="small"
        screenReaderLabel={_(msg`Close`)}
        onClick={onClose}
      />
    </div>
  );
};
