import { DateTime } from "luxon";

export const getRelativeDateAndTime = (
  luxonTimestamp: DateTime = DateTime.now(),
  locale: string = "en"
): string => {
  if (!luxonTimestamp?.isValid) {
    luxonTimestamp = DateTime.fromISO(luxonTimestamp as any); // ? type mismatch
  }

  const time = luxonTimestamp.toLocaleString(
    {
      hour: "numeric",
      minute: "2-digit",
    },
    { locale }
  );

  return `${luxonTimestamp.toRelativeCalendar({ locale })} @ ${time}`;
};
