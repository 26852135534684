import { lighten } from "@mui/material";

export const Theme = {
  core: {
    colors: {
      darkBlue: "#013B48",
      "darkBlue:hover": lighten("#013B48", 0.2),
      yellow: "#F5CC62",
      "yellow:hover": lighten("#F5CC62", 0.2),
      lightBlue: "#3DA0C3",
      "lightBlue:hover": lighten("#3DA0C3", 0.2),
    },
  },
  canvas: {
    colors: {
      text: "#2D3B45",
      blue: "#0376B5",
      lightBlue: "#4EB5CC",
    },
  },
};
