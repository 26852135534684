import { Pin } from "../../context/pinboard/pinboard.i";
import { withSentry } from "../../helpers/wrapper";

import { get, put } from "./base";

const fetchPins = withSentry(async (pinboardId: string): Promise<Pin[]> => {
  try {
    const path = `/pinboards/${pinboardId}/pins`;
    const response = await get(path, {});
    if (response.data && response.data.data && response.data.data.pins) {
      const pins = response.data.data.pins;
      return pins;
    }
    return [];
  } catch (error) {
    console.error("Error fetching pins:", error);
    return [];
  }
});

const savePinOrders = withSentry(async (pinboardId: string, pinIds: string[]): Promise<void> => {
  const path = `/pinboards/${pinboardId}`;
  await put(path, { pin_ids: pinIds });
});

export { fetchPins, savePinOrders };
