/**
 * PreRenderCharts is a component that handles pre-rendering of charts for export functionality.
 *
 * When exporting charts to PDF/PNG from views where charts are not already rendered (like the pinboard list view),
 * we need to first render the charts invisibly to generate the Highcharts instances needed for export.
 *
 * This component:
 * 1. Takes an array of pins and renders any charts that don't already have Highcharts instances
 * 2. Renders them invisibly using CSS (opacity: 0, invisible, pointer-events: none)
 * 3. Calls onComplete when all needed charts are rendered and ready for export
 * 4. Skips rendering for non-chart visualizations (tables, errors, empty states)
 *
 * Used by the Chart context's export functionality to ensure charts are properly rendered
 * before generating PDFs or PNGs.
 */
import { FC, memo, useEffect, useMemo } from "react";
import { Pin } from "../pinboard/pinboard.i";
import { PinboardChartContent } from "../../canvasApp/components/ChartContent/PinboardChartContent";
import { useChart } from ".";
import { SINGLE_METRIC } from "../../Constants/constants";

interface PreRenderChartsProps {
  pins: Pin[];
  onComplete: () => void;
}

const PreRenderCharts: FC<PreRenderChartsProps> = memo(({ pins, onComplete }) => {
  const { chartInstances } = useChart();

  const missingCharts = pins.filter((pin) => {
    if (
      pin?.visualisation === "ERROR" ||
      pin?.visualisation === "EMPTY_ROWS" ||
      pin?.visualisation === "TABLE" ||
      pin?.visualisation === SINGLE_METRIC
    ) {
      return false;
    }
    return !chartInstances.some((instance) => instance.id === pin.id);
  });

  useEffect(() => {
    if (missingCharts.length === 0) {
      onComplete();
    }
  }, [missingCharts.length, onComplete]);

  return useMemo(
    () => (
      // Tailwind classes acting inconsistently, so using inline styles for now
      <div
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          height: "100%",
          width: "100%",
          overflow: "hidden",
          opacity: 0,
          pointerEvents: "none",
          visibility: "hidden",
        }}
        className="invisible-charts-wrapper"
      >
        {missingCharts.map((pin, index) => (
          <div
            key={`prerender-${index}-${pin?.id}`}
            style={{ display: "flex", height: "500px", maxWidth: "450px" }}
          >
            <PinboardChartContent width={400} height={500} pinResult={pin} />
          </div>
        ))}
      </div>
    ),
    []
  );
});

// eslint-disable-next-line lingui/no-unlocalized-strings
PreRenderCharts.displayName = "PreRenderCharts";

export { PreRenderCharts };
