import { FC, ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";

import { CHAT_VIEW } from "../../Constants/constants";
import { Analytics } from "../../services/analytics/Analytics";
import { UIContextI, ViewEnum } from "./ui.i";

const initialState: UIContextI = {
  view: ViewEnum.CHAT_VIEW,
  setView: (view: ViewEnum) => {},
  model: ViewEnum.QUERY_MODEL,
  setModel: (model: ViewEnum) => {},
  chatDrawerOpen: false,
  setChatDrawerOpen: (open: boolean) => {},
  searchBarOpen: false,
  setSearchBarOpen: () => {},
  ui: "canvas",
  availableDataModalOpen: false,
  setAvailableDataModalOpen: () => {},
  aboutModalOpen: false,
  setAboutModalOpen: () => {},
  tipsModalOpen: false,
  setTipsModalOpen: () => {},
};

export const UIContext = createContext(initialState);

export const UIProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const currentPath = window.location.pathname;
  const [view, _setView] = useState<ViewEnum>(ViewEnum.CHAT_VIEW);
  const [model, setModel] = useState<ViewEnum>(ViewEnum.QUERY_MODEL);
  const [chatDrawerOpen, setChatDrawerOpen] = useState(false);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [availableDataModalOpen, setAvailableDataModalOpen] = useState(false);
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const [tipsModalOpen, setTipsModalOpen] = useState(false);

  const setView = (view: ViewEnum) => {
    Analytics.trackPageView(view);

    _setView(view);
  };

  const ui = useMemo(() => {
    const tenant = currentPath.startsWith("/canvas-ui") ? "canvas" : "core";

    Analytics.addUserInformation({ tenant });

    return tenant;
  }, [currentPath]);

  useEffect(() => {
    if (view !== CHAT_VIEW) {
      setChatDrawerOpen(false);
      setSearchBarOpen(false);
    }
  }, [view]);

  return (
    <UIContext.Provider
      value={{
        view,
        setView,
        model,
        setModel,
        chatDrawerOpen,
        setChatDrawerOpen,
        searchBarOpen,
        setSearchBarOpen,
        ui,
        availableDataModalOpen,
        setAvailableDataModalOpen,
        aboutModalOpen,
        setAboutModalOpen,
        tipsModalOpen,
        setTipsModalOpen,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUI = () => useContext(UIContext);
