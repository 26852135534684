import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import * as echarts from "echarts";
import React, { useEffect, useRef } from "react";

interface UsageBarChartProps {
  sortedMonthlyCounts: { [key: string]: number };
  width: number;
  height: number;
}

const UsageBarChart: React.FC<UsageBarChartProps> = ({ sortedMonthlyCounts, width, height }) => {
  const { _ } = useLingui();
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!chartRef.current) return;

    const myChart = echarts.init(chartRef.current);

    const categories = Object.keys(sortedMonthlyCounts);
    const data = Object.values(sortedMonthlyCounts);

    let option = {
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "shadow" },
      },
      grid: {
        left: "5%",
        right: "4%",
        bottom: "7%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: categories,
        name: _(msg`Month`),
        nameLocation: "middle",
        nameGap: 30,
        nameTextStyle: {
          color: "#333",
          fontSize: 16,
        },
      },
      yAxis: {
        type: "value",
        name: _(msg`Number of Queries`),
        nameLocation: "middle",
        nameTextStyle: {
          color: "#333",
          fontSize: 16,
        },
        nameGap: 50,
      },
      series: [
        {
          data: data,
          type: "bar",
        },
      ],
      title: {
        text: _(msg`Usage by Month`),
        subtext: _(msg`Detailed view of monthly usage`),
        left: "center",
        top: "top",
        textStyle: {
          color: "#333",
          fontSize: 20,
        },
        subtextStyle: {
          color: "#666",
        },
      },
    };

    myChart.setOption(option);
    myChart.resize();

    return () => {
      myChart.dispose();
    };
  }, [sortedMonthlyCounts, width, height]);

  return (
    <div style={{ width: "100%", height }}>
      <div ref={chartRef} style={{ width: "100%", height: "100%" }}></div>
    </div>
  );
};

export default UsageBarChart;
