import styled from "styled-components";

export const Heading = styled.div`
  color: #e0061f;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

export const Message = styled.div`
  font-size: 16px;
  text-align: center;
`;
