import React from "react";
import { View } from "@instructure/ui-view";

type SchemaExploreProps = {
  htmlFilePath: string;
};

const SchemaExplore: React.FC<SchemaExploreProps> = ({ htmlFilePath }) => {
  return (
    <iframe
      src={htmlFilePath}
      title="HTML Content"
      style={{ width: "100%", height: "100%", border: "none", flex: "1" }}
    ></iframe>
  );
};

export default SchemaExplore;
