import { ForwardedRef, ReactElement, forwardRef } from "react";

export const FullContainer = forwardRef(
  (
    {
      children,
      className = "",
      ...props
    }: {
      children: ReactElement | ReactElement[];
      className?: string;
      // Send all other props directly to the container
      [x: string]: any;
    },
    ref?: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div className="relative flex flex-1">
        <div className={`absolute bottom-0 left-0 right-0 top-0 ${className}`} {...props} ref={ref}>
          {children}
        </div>
      </div>
    );
  }
);
