/* eslint-disable lingui/no-unlocalized-strings */
export class NoDataError extends Error {
  name: string;
  code: string | number;

  constructor(message) {
    super(message);

    this.name = "NoDataError";
    this.code = "NO_DATA_ERROR";
  }
}
