// helpers/time.ts
import { DateTime } from 'luxon';

/**
 * Formats a Luxon DateTime object to a relative date and time string.
 * @param luxonTimestamp - The Luxon DateTime object to format.
 * @returns A formatted string representing the date and time.
 */
export const getRelativeDateAndTime = (luxonTimestamp: DateTime = DateTime.now()): string => {
  const time = luxonTimestamp.toFormat('hh:mm a');
  return `${luxonTimestamp.toFormat('D')} @ ${time} ${luxonTimestamp.toFormat('ZZZZ')}`;
};
