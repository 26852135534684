import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

import { Pagination, Spinner, View } from "@instructure/ui";

import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { CANCELED, DEFAULT_QUERY_OPTIONS, EMPTY_ROWS, ERROR } from "../../../Constants/constants";
import { useAuth } from "../../../context";
import { useChatData } from "../../../context/chat";
import { useQuery } from "../../../hooks/useQuery";
import { Doowii } from "../../../search/Doowii";
import { ErrorDisplay } from "../ChartContent/ErrorDisplay/ErrorDisplay";
import { LoadingPanda } from "../ChatTab/ResultArea/Whiteboard/LoadingStatus/LoadingPanda";
import CancelDisplay from "./CancelDisplay";
import EmptyTableOverlay from "./EmptyTableOverlay";
import { SortableTable } from "./SortableTable";
import { applyColumnOrderFromRef, applyColumnWidthsFromRef, determineColumns } from "./helpers";

interface DataTableProps {
  result: any;
  setTotalRows?: any;
  isMini?: boolean;
  width?: number | string;
  height?: number | string;
  selectable?: boolean;
  loadingText?: string;
  chartType?: string;
  setPageData?: any;
  doowii?: MutableRefObject<Doowii | null>;
}

export const DataTable = ({
  result,
  setTotalRows = () => {},
  width = "100%",
  height = "100%",
  selectable = true,
  loadingText = "",
  chartType,
  setPageData,
  doowii,
}: DataTableProps) => {
  const { _ } = useLingui();
  const tableRef = useRef(null);
  const [tableRenderedCheck, setTableRenderedCheck] = useState(0);
  const [paginationModel, setPaginationModel] = useState(DEFAULT_QUERY_OPTIONS);
  const [sortModel, setSortModel] = useState(
    JSON.parse(localStorage.getItem(`sortModel-${result?.id}`) || "[]")
  );

  const [shouldFetchData, setShouldFetchData] = useState(false);
  const prevSqlRef = useRef();
  const columnWidthsRef = useRef({});
  const columnOrderRef = useRef([]);

  const { baseURL } = useAuth();
  const [queryOptions, setQueryOptions] = useState({ paginationModel: paginationModel });
  const {
    loading,
    rows,
    pageInfo: _pageInfo,
    queryError,
  } = useQuery(result, queryOptions, shouldFetchData);
  const { loading: chatLoading, currentResult, allResults, answer } = useChatData();

  const [progressValue, setProgressValue] = useState<number>(0);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showTableComponent, setShowTableComponent] = useState<boolean>(true);
  const [debouncedProgress, setDebouncedProgress] = useState(0);
  const timeCheck = useRef(0);

  useEffect(() => {
    if (setPageData && pageInfo?.totalRowCount !== undefined) {
      setPageData({ rows, pageInfo });
    }
  }, [rows]);

  useEffect(() => {
    // Handle the initial loading phase
    if (chatLoading) {
      setShowProgress(true);
      setProgressValue(0);
      let intervalDuration = 400; // Initial duration

      let interval = setInterval(() => {
        setProgressValue((prevProgress) => {
          if (prevProgress >= 80) {
            intervalDuration = 3500; // Slow down once we hit 80 percent
            clearInterval(interval); // Clear the existing interval
            // Start a new interval with the slower pace
            interval = setInterval(() => {
              setProgressValue((slowProgress) => {
                const nextProgress = slowProgress + 1;
                return nextProgress <= 90 ? nextProgress : slowProgress;
              });
            }, intervalDuration);
          }

          const nextProgress = prevProgress + 1;
          return nextProgress <= 90 ? nextProgress : prevProgress;
        });
      }, intervalDuration);

      return () => clearInterval(interval);
    }
  }, [chatLoading]);

  useEffect(() => {
    if (chartType && (chartType === EMPTY_ROWS || chartType === ERROR || chartType === CANCELED)) {
      if (showProgress) {
        setProgressValue(100);
        setTimeout(() => {
          setShowTableComponent(false);
          setShowProgress(false);
        }, 500);
      } else {
        setShowTableComponent(false);
      }
    } else {
      setShowTableComponent(true);
    }
  }, [chartType]);

  useEffect(() => {
    // Skip this effect if still in initial loading phase
    if (chatLoading || !showProgress) return;

    if (loading) {
      let interval = setInterval(() => {
        setProgressValue((prevProgress) => {
          const nextProgress = prevProgress + (100 - prevProgress) * 0.1; // Dynamically adjust increment
          return nextProgress < 100 ? nextProgress : prevProgress;
        });
      }, 200);

      return () => clearInterval(interval);
    } else if (!loading && progressValue < 100) {
      if (pageInfo?.totalRowCount !== undefined || queryError) {
        setProgressValue(100);

        setTimeout(() => {
          setShowProgress(false);
        }, 100);
      }
    }
  }, [loading, chatLoading, progressValue, queryError]);

  useEffect(() => {
    const currentTime = new Date().getTime();

    if (currentTime - timeCheck.current > 2000 || progressValue >= 100) {
      setDebouncedProgress(progressValue);
      timeCheck.current = currentTime;
    }
  }, [progressValue]);

  const pageInfo = _pageInfo as any;

  const [rowCountState, setRowCountState] = useState(pageInfo?.totalRowCount || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined ? pageInfo?.totalRowCount : prevRowCountState
    );
    if (setTotalRows) {
      setTotalRows((prevRowCountState) =>
        pageInfo?.totalRowCount !== undefined ? pageInfo?.totalRowCount : prevRowCountState
      );
    }
  }, [pageInfo?.totalRowCount, setRowCountState, setTotalRows]);

  const handlePaginationModelChange = (paginationModel) => {
    setQueryOptions((prev) => {
      return {
        ...prev,
        paginationModel: paginationModel,
      };
    });
    setPaginationModel(paginationModel);
    setShouldFetchData(true);
  };

  const handleSortModelChange = (newSortModel) => {
    if (rows.length === 0) {
      return;
    }
    setPaginationModel((prev) => {
      return {
        ...prev,
        page: 0,
      };
    });

    setQueryOptions((prev) => {
      return {
        ...prev,
        sortModel: newSortModel,
        paginationModel: { ...prev.paginationModel, page: 0 },
      };
    });

    setShouldFetchData(true);

    setSortModel(newSortModel);
    localStorage.setItem(`sortModel-${result?.id}`, JSON.stringify(newSortModel));
  };

  const handleColumnHeaderClick = (event, params) => {
    // Stop the default sorting behavior
    event.stopPropagation();
    // handleColumnSelect(params);
  };

  const rawColumns = determineColumns(result, pageInfo, [], handleColumnHeaderClick, baseURL);
  const orderedColumns = applyColumnOrderFromRef(rawColumns, columnOrderRef);
  const columns = applyColumnWidthsFromRef(orderedColumns, columnWidthsRef);

  useEffect(() => {
    const isSqlChanged = prevSqlRef.current !== result?.sql;
    if (isSqlChanged) {
      prevSqlRef.current = result?.sql;
      const localSortModel = JSON.parse(localStorage.getItem(`sortModel-${result?.id}`) || "[]");
      setShouldFetchData(true);

      setPaginationModel(DEFAULT_QUERY_OPTIONS);
      setQueryOptions((prev) => {
        return {
          ...prev,
          paginationModel: DEFAULT_QUERY_OPTIONS,
          sortModel: localSortModel,
        };
      });
      setSortModel(localSortModel);
      columnOrderRef.current = [];
      columnWidthsRef.current = {};
    }
  }, [result?.sql]);

  useEffect(() => {
    const localSortModel = JSON.parse(localStorage.getItem(`sortModel-${result?.id}`) || "[]");
    setShouldFetchData(true);

    setPaginationModel(DEFAULT_QUERY_OPTIONS);
    setQueryOptions((prev) => {
      return {
        ...prev,
        paginationModel: DEFAULT_QUERY_OPTIONS,
        sortModel: localSortModel,
      };
    });
    setSortModel(localSortModel);
  }, [result?.id, result?.status]);

  const getSummaryAnswer = useCallback((): string => {
    const currentResultIndex = allResults.findIndex((res) => res.id === currentResult.id);
    return answer[currentResultIndex];
  }, [allResults, currentResult, answer]);

  useEffect(() => {
    let timeout;
    if (!showProgress) {
      if (tableRef.current) {
        const summaryAnswer = getSummaryAnswer();
        if (summaryAnswer) {
          const userQuestion = document.querySelector('div[role="button"][aria-pressed="true"]');
          (userQuestion as HTMLDivElement)?.focus();
        }
      } else {
        if (tableRenderedCheck < 5) {
          setTableRenderedCheck((e) => e + 1);
        }
      }
    }

    return () => clearTimeout(timeout);
  }, [tableRenderedCheck, showProgress]);

  if (!showTableComponent) {
    return (
      <>
        {chartType === EMPTY_ROWS && <ErrorDisplay version={EMPTY_ROWS} />}
        {chartType === ERROR && <ErrorDisplay version={ERROR} />}
        {chartType === CANCELED && <CancelDisplay results={result} doowii={doowii} />}
      </>
    );
  }

  const isError = chartType === EMPTY_ROWS || chartType === ERROR || chartType === CANCELED;

  const debouncedProgressRounded = Math.round(debouncedProgress);
  const percentProgressText =
    debouncedProgressRounded >= 100 ? "100" : `${debouncedProgressRounded}`;

  const getDisplayedRows = () => {
    const from = paginationModel.page * paginationModel.pageSize + 1;
    const to = Math.min((paginationModel.page + 1) * paginationModel.pageSize, rowCountState);
    return _(msg`${from} - ${to} of ${rowCountState}`);
  };

  const totalPageNumber = Math.ceil(rowCountState / paginationModel.pageSize);

  return (
    <>
      {!isError && (
        <div
          aria-live="assertive"
          aria-atomic="true"
          style={{ position: "absolute", left: "-999999px" }}
        >
          {`${
            debouncedProgressRounded >= 100
              ? _(
                  msg`${percentProgressText} % - Data table was loaded successfully with columns: ${columns.map(
                    (column) => `${column.headerName} - `
                  )} and ${rowCountState} rows. Loading result summary, please wait.`
                )
              : _(msg`${percentProgressText} %`)
          }`}
        </div>
      )}

      {showProgress && (
        <LoadingPanda
          loadingText={loadingText}
          progressValue={progressValue}
          setProgressValue={setProgressValue}
          doowii={doowii}
        />
      )}
      {!showProgress && (
        <View
          as="div"
          className="flex flex-col"
          height={height}
          width={width}
          maxWidth={width}
          borderWidth="small"
          borderRadius="medium"
          overflowY="hidden"
        >
          {loading && (
            <div className="flex flex-1 items-center justify-center">
              <Spinner margin="auto" renderTitle={_(msg`Loading...`)} />
            </div>
          )}
          {!loading && (
            <div className="flex-1 overflow-auto">
              {rows.length === 0 && <EmptyTableOverlay />}
              {rows.length > 0 && (
                <>
                  <div id="table-alert" className="sr-only" role="alert" />
                  <SortableTable
                    ref={tableRef}
                    caption={result?.title}
                    headers={columns}
                    rows={rows}
                    onSortModelChange={handleSortModelChange}
                    sortModel={sortModel}
                  />
                </>
              )}
            </div>
          )}
          <View borderWidth="small 0 0 0" className="[&>nav]:flex [&>nav]:justify-end">
            <Pagination
              as="nav"
              margin="x-small"
              variant="compact"
              labelNext={_(msg`Next Page`)}
              labelPrev={_(msg`Previous Page`)}
              currentPage={paginationModel.page + 1}
              totalPageNumber={totalPageNumber}
              onPageChange={(nextPage) => {
                handlePaginationModelChange({
                  page: nextPage - 1,
                  pageSize: paginationModel.pageSize,
                });
              }}
              showDisabledButtons
              label={getDisplayedRows()}
            />
          </View>
        </View>
      )}
    </>
  );
};
