import { Button, Heading, Modal, Text, TextArea, CloseButton } from "@instructure/ui";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useUI } from "../../../../../context/ui";
import React from "react";

const TipsModal: React.FC = () => {
  const { _ } = useLingui();
  const { tipsModalOpen, setTipsModalOpen } = useUI();

  const renderCloseButton = () => {
    return (
      <CloseButton
        placement="end"
        offset="small"
        onClick={() => setTipsModalOpen(false)}
        screenReaderLabel={_(msg`Close`)}
      />
    );
  };
  return (
    <Modal
      as="form"
      open={tipsModalOpen}
      onDismiss={() => {
        setTipsModalOpen(false);
      }}
      size={"small"}
      label={_(msg`Prompt Tips Modal Dialog`)}
    >
      <Modal.Header>
        {renderCloseButton()}
        <Heading level="h2">
          <Trans>Composing Prompts</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <Text weight="bold" size="large" transform="capitalize" lineHeight="double">
          <Trans>Give exact names</Trans>
        </Text>
        <br />
        <Text size="medium">
          <Trans>
            If you are referring to a specific course or person, make sure to put their name exactly
            as it would appear in a database.
          </Trans>
        </Text>
        <br />

        <Text weight="bold" size="large" transform="capitalize" lineHeight="double">
          <Trans>Be specific</Trans>
        </Text>
        <br />
        <Text size="medium">
          <Trans>
            When referring to an item, qualify the item with what domain it is related to (courses,
            tools, assignment).
          </Trans>
        </Text>
        <br />

        <Text weight="bold" size="large" transform="capitalize" lineHeight="double">
          <Trans>Designate a time frame</Trans>
        </Text>
        <br />
        <Text size="medium">
          <Trans>If you only want results from this semester, please specify "this term".</Trans>
        </Text>
        <br />

        <Text weight="bold" size="large" transform="capitalize" lineHeight="double">
          <Trans>Quantify</Trans>
        </Text>
        <br />
        <Text size="medium">
          <Trans>
            When asking for a quality that needs to be interpreted, specify how the chatbot should
            interpret it.
          </Trans>
        </Text>
        <br />

        <Text weight="bold" size="large" transform="capitalize" lineHeight="double">
          <Trans>Example</Trans>
        </Text>

        <TextArea
          label={_(msg`Before: `)}
          defaultValue={_(msg`Which teachers are struggling?`)}
          readOnly={true}
          height="3em"
        ></TextArea>
        <br />
        <TextArea
          label={_(msg`Suggested: `)}
          defaultValue={_(msg`Which teachers in CS101 are struggling with the online teaching platform 'Canvas' this
          term? Define 'struggling' as having a student feedback score below 3 out of 5.`)}
          readOnly={true}
        ></TextArea>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setTipsModalOpen(false)} margin="0 x-small 0 0">
          <Trans>Done</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default TipsModal;
