import { Alert } from "@instructure/ui";
import { FC, MutableRefObject } from "react";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import { Doowii } from "../../../../search/Doowii";
import { Loader } from "../../UI/Loader/Loader";
import ResultDrawer from "../../mobileDrawers/ResultDrawer";
import InitialPanda from "./InitialPanda/InitialPanda";
import { Whiteboard } from "./Whiteboard/Whiteboard";
import { WhiteBoardHeaderDisabled } from "./Whiteboard/WhiteboardHeader/WhiteboardHeaderDisabled";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";

interface ResultAreaProps {
  loadingText: string;
  loadingThread: boolean;
  loading: boolean;
  searchHistory: any;
  openPinboardModal: boolean;
  setOpenPinboardModal: (open: boolean) => void;
  doowii: MutableRefObject<Doowii | null>;
}

export const ResultArea: FC<ResultAreaProps> = (props) => {
  const { isChatDesktop } = useBreakpoint();
  return (
    <>
      <h2 className="sr-only">
        <Trans>Chat Results</Trans>
      </h2>
      {isChatDesktop ? <ResultAreaDesktop {...props} /> : <ResultDrawer {...props} />}
    </>
  );
};

const ResultAreaDesktop: FC<ResultAreaProps> = ({
  loadingText,
  loadingThread,
  loading,
  searchHistory,
  openPinboardModal,
  setOpenPinboardModal,
  doowii,
}) => {
  const { _ } = useLingui();
  const showWhiteBoard = searchHistory.length > 0;

  if (loadingThread)
    return (
      <>
        <WhiteBoardHeaderDisabled />
        <Loader message={_(msg`Loading Thread`)} />
      </>
    );

  if (!showWhiteBoard)
    return (
      <>
        <WhiteBoardHeaderDisabled />
        <InitialPanda />
      </>
    );

  return (
    <>
      <Whiteboard
        loading={loading}
        loadingText={loadingText}
        openPinboardModal={openPinboardModal}
        setOpenPinboardModal={setOpenPinboardModal}
        doowii={doowii}
      />
      <Alert variant="info" margin="small">
        <Trans>Ask Your Data uses queries generated by AI. Check the results.</Trans>
      </Alert>
    </>
  );
};
