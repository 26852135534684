import { Skeleton } from "@mui/material";

const SummarySkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <Skeleton height="2rem" width="8rem" />
        <div className="mt-2">
          <Skeleton height="1rem" width="100%" />
          <Skeleton height="1rem" width="95%" />
          <Skeleton height="1rem" width="90%" />
        </div>
      </div>

      <div className="mt-2">
        <Skeleton height="2rem" width="10rem" />
        <div className="mt-2">
          <Skeleton height="1rem" width="100%" />
          <Skeleton height="1rem" width="90%" />
          <Skeleton height="1rem" width="95%" />
          <Skeleton height="1rem" width="85%" />
        </div>
      </div>
    </div>
  );
};

export default SummarySkeleton;
