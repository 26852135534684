import { CloseButton, Heading, Modal, Text } from "@instructure/ui";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC } from "react";

interface ItemLineProps {
  shortcut: string;
  description: string;
}

const ItemLine: FC<ItemLineProps> = ({ shortcut, description }) => {
  return (
    <tr className="border-t border-[#C7CDD1]">
      <th className="py-1 w-36 text-left" scope="row">
        <Text weight="bold" size="small">{shortcut}</Text>
      </th>
      <td className="py-1">
        <Text size="small">{description}</Text>
      </td>
    </tr>
  );
};

interface KeyboardShortcutsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const KeyboardShortcutsModal: FC<KeyboardShortcutsModalProps> = ({ open, setOpen }) => {
  const { _ } = useLingui();
  const onClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      onDismiss={onClose}
      size="auto"
      label={_(msg`Modal Dialog: Keyboard Shortcuts`)}
      shouldCloseOnDocumentClick
    >
      <Modal.Header>
        <CloseButton
          placement="end"
          offset="medium"
          onClick={onClose}
          screenReaderLabel={_(msg`Close`)}
          size="medium"
        />
        <Heading margin="0 medium 0 0">
          <Trans>Keyboard Shortcuts</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <table className="mb-4 w-full border-collapse">
          <caption className="mb-2 text-left">
            <Text weight="bold" size="small"><Trans>Insights Chat Tab</Trans></Text>
          </caption>
          <tbody>
            <ItemLine
              shortcut="CTRL+SHIFT+2"
              description={_(msg`Select "Pinboard" tab`)}
            />
            <ItemLine
              shortcut="CTRL+ALT+1"
              description={_(msg`Focus chat input`)}
            />
            <ItemLine
              shortcut="CTRL+ALT+2"
              description={_(msg`Create new chat`)}
            />
            <ItemLine
              shortcut="CTRL+ALT+3"
              description={_(msg`Show previous chat`)}
            />
            <ItemLine
              shortcut="CTRL+ALT+4"
              description={_(msg`Show next chat`)}
            />
            <ItemLine
              shortcut="CTRL+ALT+5"
              description={_(msg`Add result to pinboard`)}
            />
          </tbody>
        </table>

        <table className="mb-4 w-full border-collapse">
          <caption className="mb-2 text-left">
            <Text weight="bold" size="small"><Trans>Pinboard Tab</Trans></Text>
          </caption>
          <tbody>
            <ItemLine shortcut="CTRL+SHIFT+1" description={_(msg`Select "Insight Chats" tab`)} />
            <ItemLine shortcut="CTRL+ALT+1" description={_(msg`Focus search input`)} />
            <ItemLine shortcut="CTRL+ALT+2" description={_(msg`Create new pinboard`)} />
            <ItemLine shortcut="CTRL+ALT+3" description={_(msg`Show previous pinboard`)} />
            <ItemLine shortcut="CTRL+ALT+4" description={_(msg`Show next pinboard`)} />
          </tbody>
        </table>

        <table className="mb-4 w-full border-collapse">
          <caption className="mb-2 text-left">
            <Text weight="bold" size="small"><Trans>Data table</Trans></Text>
          </caption>
          <tbody>
            <ItemLine shortcut="ENTER" description={_(msg`Sort column`)} />
            <ItemLine
              shortcut="↑, ↓, ←, →"
              description={_(msg`Move inside table`)}
            />
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};
