import { Button, CloseButton, Heading, Link, Modal, Text, View } from "@instructure/ui";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";
import { useUI } from "../../../context/ui";
import useBreakpoint from "../../../hooks/useBreakpoint";

const AboutModal: React.FC = () => {
  const { isChatDesktop } = useBreakpoint();
  const { aboutModalOpen, setAboutModalOpen } = useUI();
  const { _ } = useLingui();

  const handleButtonClick = () => {
    setAboutModalOpen(!aboutModalOpen);
  };

  const renderCloseButton = () => {
    return (
      <CloseButton
        placement="end"
        offset="small"
        onClick={handleButtonClick}
        screenReaderLabel={_(msg`Close`)}
      />
    );
  };

  return (
    <Modal
      as="form"
      open={aboutModalOpen}
      onDismiss={() => {
        setAboutModalOpen(false);
      }}
      label={_(msg`Modal Dialog: About`)}
      shouldCloseOnDocumentClick
      size={isChatDesktop ? "auto" : "fullscreen"}
    >
      <Modal.Header>
        {renderCloseButton()}
        <Heading level="h2">
          <Trans>About</Trans>
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <View margin="small 0">
          <Heading level="h3">
            <Trans>AI Nutrition Facts</Trans>
          </Heading>
          <br />
          <Heading level="h4">
            <Trans>Ask Your Data</Trans>
          </Heading>
          <div style={{ padding: "1rem 0 0 1rem" }}>
            <Text as="p">
              <Trans>
                <strong>Description:</strong> Natural language search of Canvas LMS data for
                administrators. (AI-generated queries to run against a copy of Canvas data; results
                shown, summarized.)
              </Trans>
            </Text>
            <hr />
          </div>
          <div style={{ padding: "1rem 0 0 1rem" }}>
            <Text as="p">
              <Trans>
                <strong>Model & Data:</strong> Ask Your Data is not building or training a model. It
                is using several commercially available models, including Chat GPT-4, GPT-4o, GPT-4o
                mini, GPT-3.5 Turbo and Claude 3.5 Sonnet. Data passed to the model includes the
                user's prompt and summary statistics for the returned data.
              </Trans>
            </Text>
            <hr />
          </div>
          <div style={{ padding: "1rem 0 0 1rem" }}>
            <Text as="p">
              <Trans>
                <strong>Data Retention:</strong> Prompts, logs, and usage data are kept for as long
                as necessary to fulfill the purpose for which they were collected. They are disposed
                of within <strong>30 days</strong> of the termination of contracts.
              </Trans>
            </Text>
            <hr />
          </div>
          <div style={{ padding: "1rem 0 0 1rem" }}>
            <Text as="p">
              <Trans>
                <strong>Supported Regions:</strong> Australia, Canada, EU, Singapore, USA.
              </Trans>
            </Text>
            <hr />
          </div>
          <div style={{ padding: "1rem 0 0 1rem" }}>
            <Text as="p">
              <strong>
                <Trans>PII:</Trans>
              </strong>{" "}
              <Trans>Data in the prompt, possible summary statistics labels.</Trans>
            </Text>
            <hr />
          </div>
          <div style={{ padding: "1rem 0 0 1rem" }}>
            <Text as="p">
              <strong>
                <Trans>Outputs:</Trans>
              </strong>
              <br />
              <div style={{ padding: "1rem 0 0 1rem" }}>
                <strong>
                  <Trans>AI Settings Control:</Trans>
                </strong>{" "}
                <Trans>Yes, Ask Your Data uses AI settings controls.</Trans>
                <hr />
                <strong>
                  <Trans>Human in the Loop:</Trans>
                </strong>{" "}
                <Trans>
                  Ask Your Data is using provided models, it is not building or training a model.
                  Additional context, as provided through the use of the above-listed models, is
                  done by humans. Humans may review the prompts and outputs after the fact.
                </Trans>
                <hr />
                <strong>
                  <Trans>Guardrails:</Trans>
                </strong>{" "}
                <Trans>
                  Ask Your Data ensures secure data transmission and storage, restricted access to
                  authorized personnel, and compliance with geographical data storage requirements.
                  Data retention and disposal processes are followed, with deletions occurring
                  within specified time frames upon contract termination or authorized requests.
                </Trans>
                <hr />
                <strong>
                  <Trans>Expected Risks:</Trans>
                </strong>{" "}
                <Trans>
                  Incorrect interpretation of the question. Inaccurate SQL may be generated.
                </Trans>{" "}
                <Trans>Suggested questions may not be relevant.</Trans>
              </div>
            </Text>
            <hr />
          </div>
          <div style={{ padding: "1rem 0 0 1rem" }}>
            <Text as="p">
              <strong>
                <Trans>Intended Outcomes:</Trans>
              </strong>{" "}
              <Trans>Provide accurate data retrieval, visualizations, and analysis.</Trans>
            </Text>
            <hr />
          </div>
          <div style={{ padding: "1rem 0 0 0" }}>
            <Text as="p" weight="bold" fontStyle="italic">
              <Trans>Ask Your Data generally complies with Instructure's Privacy Policy. </Trans>
              <Link target="_blank" href="https://www.instructure.com/policies/privacy">
                <Trans>Product Privacy Policy</Trans>
              </Link>
            </Text>
            <hr />
          </div>
        </View>
        <br />
        <View margin="small 0">
          <Heading level="h3">
            <Trans>Versions</Trans>
          </Heading>
          <Text as="p">
            <strong>
              <Trans>Ask Your Data Frontend:</Trans>
            </strong>{" "}
            <Trans>Version</Trans> v1.1.0
            <br />
            <strong>
              <Trans>Ask Your Data SQL Engine:</Trans>
            </strong>{" "}
            <Trans>Version</Trans> v1.1.0
            <br />
            <strong>
              <Trans>Ask Your Data Data Pipeline:</Trans>
            </strong>{" "}
            <Trans>Version</Trans> v1.1.0
          </Text>
        </View>{" "}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleButtonClick} margin="0 x-small 0 0">
          <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AboutModal;
