import { Link, Text } from "@instructure/ui";
import waving from "./waving.png";
import { FC, ReactElement } from "react";
import { Trans } from "@lingui/macro";

interface PinboardEmptyProps {
  setCreateModalOpen: (value: boolean) => void;
}

export const PinboardEmpty: FC<PinboardEmptyProps> = ({ setCreateModalOpen }): ReactElement => {
  const handleAddModalOpen = () => {
    setCreateModalOpen(true);
  };
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <img src={waving} alt="waving" className="mb-5" />
      <Text size="small" lineHeight="double">
        <Trans>There are no pins to display yet.</Trans>
      </Text>
      <Text size="small" lineHeight="double">
        <Trans>
          <Link onClick={handleAddModalOpen}>Create a pinboard</Link> and pin your favorite chats to
          see them here.
        </Trans>
      </Text>
    </div>
  );
};
