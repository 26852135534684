import { Avatar as InstructorAvatar } from "@instructure/ui";
import panda from "./panda.png";
interface AvatarProps {
  userName: {
    firstName: string;
    lastName?: string;
  };
}

export const Avatar = ({ userName }: AvatarProps) => {
  let userNameFinal;

  if (userName) {
    if (userName.lastName) {
      userNameFinal = `${userName.firstName} ${userName.lastName}`;
    } else {
      userNameFinal = userName.firstName;
    }

    userNameFinal = userNameFinal.toUpperCase();
  }

  return (
    <div>
      {userName ? (
        <InstructorAvatar name={userNameFinal} size="small" />
      ) : (
        // Avatar will override the white background with grey. There is no way to make it white
        <InstructorAvatar name="" src={panda} size="small" />
      )}
    </div>
  );
};
