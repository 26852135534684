import { Trans } from "@lingui/macro";
import { CHART_DATA_LIMIT } from "../../../Constants/constants";
import * as s from "./ChartWarning.s";
export default function ChartWarning() {
  return (
    <s.ChartWarning>
      <Trans>
        We used the first {CHART_DATA_LIMIT} data points to create the chart. To view the full
        dataset, please use the table view.
      </Trans>
    </s.ChartWarning>
  );
}
