// components/PinViewContainer.tsx
import React, { FC, PropsWithChildren } from "react";
import useBreakpoint from "../../../../../hooks/useBreakpoint";
import { PinViewContainerProps } from "../types";
import { PinFullViewModal } from "./PinFullViewModal";
import { PinFullViewDrawer } from "./PinFullViewDrawer";

/**
 * Container component that renders the appropriate full-screen view based on the breakpoint.
 */
export const PinViewContainer: FC<PropsWithChildren<PinViewContainerProps>> = ({
  children,
  isOpen,
  handleClose,
  size,
}) => {
  const { isChatDesktop } = useBreakpoint();
  const Container = isChatDesktop ? PinFullViewModal : PinFullViewDrawer;

  return (
    <Container isOpen={isOpen} handleClose={handleClose} size={size}>
      {children}
    </Container>
  );
};
