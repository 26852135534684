import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { FC, ReactElement, ReactNode, useCallback, useState } from "react";
import { useAlert } from "../../../../context/alert";
import { Pin } from "../../../../context/pinboard/pinboard.i";
import { savePinOrders } from "../../../../services/webserver/pinboards";

interface DndWrapperProps {
  currBoardId: string;
  filteredPinboardResults: Pin[];
  setFilteredPinboardResults: React.Dispatch<React.SetStateAction<Pin[]>>;
  setPinboardResults: React.Dispatch<React.SetStateAction<Pin[]>>;
  hasSearch: boolean;
  children: ReactNode;
}

const DndWrapper: FC<DndWrapperProps> = ({
  currBoardId,
  filteredPinboardResults,
  setFilteredPinboardResults,
  setPinboardResults,
  hasSearch,
  children,
}): ReactElement => {
  const { showAlert } = useAlert();
  const { _ } = useLingui();

  const [activeId, setActiveId] = useState<string | number | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor)
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      setFilteredPinboardResults((results: Pin[]): Pin[] => {
        const oldIndex = results.findIndex((r) => r.id === active.id);
        const newIndex = results.findIndex((r) => r.id === over?.id);
        const newArray = arrayMove(results, oldIndex, newIndex);
        savePinOrders(
          currBoardId,
          newArray.map((r) => r.id)
        ).catch((error) => {
          console.error("Error saving pin order:", error);

          showAlert({
            variant: "error",
            message: _(msg`Failed to save the pin order. Please try again later.`),
          });
        });

        return newArray;
      });
      setPinboardResults((results: Pin[]): Pin[] => {
        const oldIndex = results.findIndex((r) => r.id === active.id);
        const newIndex = results.findIndex((r) => r.id === over?.id);
        return arrayMove(results, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const inDragMode = activeId !== null;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={filteredPinboardResults} strategy={rectSortingStrategy}>
        {filteredPinboardResults.map((pin, index) => {
          if (!pin || pin?.visualisation === "ERROR" || pin?.visualisation === "EMPTY_ROWS")
            return null;

          return React.cloneElement(children as ReactElement, {
            key: `${pin?.id}-${currBoardId}`,
            chart: pin,
            filteredPinboardResults,
            setFilteredPinboardResults,
            setPinboardResults,
            hasSearch,
            inDragMode,
          });
        })}
      </SortableContext>
      <DragOverlay>
        {activeId
          ? React.cloneElement(children as ReactElement, {
              key: activeId,
              chart: filteredPinboardResults.find((r) => r.id === activeId),
              inDragMode,
            })
          : null}
      </DragOverlay>
    </DndContext>
  );
};

export { DndWrapper };
