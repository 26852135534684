import { createContext, useContext, useState, useEffect, ReactNode, FC } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from "../auth";
import { db } from "../../services/firebase";
import { UsageContextI } from "./usage.i";

const UsageContext = createContext<UsageContextI | undefined>(undefined);

export const useUsage = () => {
  const context = useContext(UsageContext);
  if (context === undefined) {
    throw new Error("useUsage must be used within a UsageProvider");
  }
  return context;
};

export const UsageProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { userDocument } = useAuth();
  const [totalCount, setTotalCount] = useState(0);
  const [monthlyCounts, setMonthlyCounts] = useState<{ [key: string]: number }>({});
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (userDocument?.organization) {
        setLoading(true);
        const orgDocRef = doc(db, "organizations", userDocument.organization);
        const orgDoc = await getDoc(orgDocRef);

        if (orgDoc.exists()) {
          const data = orgDoc.data();
          setTotalCount(data.chargeable_actions || 0);
          const sortedMonthlyCounts = data.monthly_usage
            ? Object.entries(data.monthly_usage)
                .sort(([key1], [key2]) => key1.localeCompare(key2))
                .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
            : {};

          setMonthlyCounts(sortedMonthlyCounts);
          console.log(data, data.start_date);
          data.start_date && setStartDate(data.start_date.toDate());
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [userDocument?.organization]);

  return (
    <UsageContext.Provider value={{ totalCount, monthlyCounts, startDate, loading }}>
      {children}
    </UsageContext.Provider>
  );
};
