import LinkedList from "./linkedList";

const MAX_TOKEN_COUNT = 3000;

export class SlidingWindow {
  conversation: LinkedList;
  token_size: number;
  max_count: number;

  // for constructor, we take the initial input message
  constructor(value, max_count = 4000) {
    this.conversation = new LinkedList();
    this.conversation.append(value);
    this.token_size = this.conversation.head.size;
    this.max_count = max_count;
  }

  add(value) {
    let newNode = this.conversation.append(value);
    this.token_size += newNode.size;
    while (this.token_size > this.max_count) {
      let removedNode = this.conversation.remove();
      if (removedNode === undefined) {
        break;
      }
      this.token_size -= removedNode.size;
    }
  }

  clear() {
    while (this.conversation.length > 0) {
      let removedNode = this.conversation.remove();
      if (removedNode === undefined) {
        break;
      }
      this.token_size -= removedNode.size;
    }
  }

  get_conversation() {
    let conversationString = [];
    let current = this.conversation.head;
    while (current !== null) {
      conversationString.push(current.value);
      current = current.next;
    }
    return conversationString;
  }

  get_token_size() {
    return this.token_size;
  }

  get_length() {
    return this.conversation.length;
  }
}

export class ConversationMgr {
  static windows = {};

  /**  Method to fetch the value associated with key.
   * If key does not exist, the method inserts the key into the window.
   * @param key - key to fetch the value.
   * @param value - defaults to null, but set when trying to insert new (key, value) pairs into the window.
   * @param maxToken - maximum number of tokens allowed in the window.
   */

  static get(key, value = null, maxToken = MAX_TOKEN_COUNT) {
    if (this.windows.hasOwnProperty(key)) {
      return this.windows[key];
    } else {
      this.windows[key] = new SlidingWindow(value, maxToken);
      return this.windows[key];
    }
  }
}
