import { Flex, Spinner, Text, View } from "@instructure/ui";
import { Skeleton } from "@mui/material";
import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";

import { useAuth } from "../../../context";
import UsageBarChart from "./UsageBarChart";
import { useUsage } from "../../../context/usage";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

const Usage: FC = () => {
  const { i18n } = useLingui();
  const { userDocument } = useAuth();
  const { totalCount, monthlyCounts, loading, startDate } = useUsage();
  const [outerHeight, setOuterHeight] = useState(0);
  const [outerWidth, setOuterWidth] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);
  const [innerWidth, setInnerWidth] = useState(0);

  const containerRef = useRef(null);

  function updateSize() {
    if (containerRef.current) {
      const { height, width } = containerRef.current.getBoundingClientRect();

      setOuterHeight(height - 48);
      setOuterWidth(width - 24);
      setInnerHeight(height - (36 + 12) * 2 - 24);
      setInnerWidth(width - (36 + 12) * 2);
    }
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize(); // Set initial size

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);
  console.log(startDate);
  const convertedStartDate = startDate
    ? i18n.date(startDate, {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    : null;
  return (
    <div className="flex h-full flex-1 flex-col items-center pb-4">
      <View
        as="span"
        display="inline-block"
        width="100%"
        margin="small"
        padding="large"
        background="primary"
      >
        {/* {startDate && (
          <Flex direction="column" textAlign="center">
            {" "}
            <Flex.Item align="end">
              <Text size="small" color="secondary">
                Refreshes on {startDate?.toLocaleDateString()}
              </Text>
            </Flex.Item>
          </Flex>
        )}
 */}
        <Flex direction="column" textAlign="center">
          <Flex.Item align="center">
            {loading ? <Spinner size="small" /> : <Text size="xx-large">{totalCount}</Text>}
          </Flex.Item>
          <Flex.Item>
            <b>
              {convertedStartDate ? (
                <Trans>queries have been run since {convertedStartDate}</Trans>
              ) : (
                <Trans>Total Queries</Trans>
              )}
            </b>
          </Flex.Item>
        </Flex>
      </View>
      <div className="flex h-full w-full flex-1 flex-col" ref={containerRef}>
        <View
          as="span"
          display="inline-block"
          margin="small"
          padding="large"
          background="primary"
          shadow="resting"
          width={outerWidth}
          height={outerHeight}
        >
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={innerWidth}
              height={innerHeight}
              animation="wave"
            />
          ) : (
            <UsageBarChart
              sortedMonthlyCounts={monthlyCounts}
              width={innerWidth}
              height={innerHeight}
            />
          )}
        </View>
      </div>
    </div>
  );
};

export default Usage;
