import cx from "classnames";
import { DateTime } from "luxon";

import { MutableRefObject } from "react";
import { Doowii } from "../../../../../../search/Doowii";
import { Avatar } from "./Avatar/Avatar";
import { BubbleTextArea } from "./BubbleTextArea/BubbleTextArea";
import { getRelativeDateAndTime } from "./BubbleTextArea/time-helper";
import { useLocaleContext } from "../../../../../../context/locale";

interface SpeechBubbleProps {
  index?: number;
  text: string;
  timestamp: DateTime;
  single: boolean;
  loading?: boolean;
  selected?: boolean;
  first?: boolean;
  doowii: MutableRefObject<Doowii | null>;
  onSelectChat?: () => void;
  setQuery?: (value: string) => void;
  userName?: { firstName: string; lastName: string };
  followUpQuestions?: string[];
}

export const SpeechBubble = ({
  index,
  text,
  timestamp,
  selected,
  onSelectChat,
  userName,
  loading,
  first,
  setQuery,
  followUpQuestions,
  single,
  doowii,
}: SpeechBubbleProps) => {
  const { locale } = useLocaleContext();
  const isUser = !!userName;
  const relativeDate = getRelativeDateAndTime(timestamp, locale);

  return (
    <div className={cx({ "mt-auto": first })} id={selected ? "selected-chat" : ""}>
      {isUser && <div className="mb-6 mt-6 flex-1 border-t border-[#C7CDD1]" />}
      <div className={`flex flex-col items-start lg:flex-row ${!isUser ? "mt-4" : ""}`}>
        <div className="mb-2 flex items-center gap-4 lg:mt-5">
          <Avatar userName={userName} />
          <div className="mb-1 text-[12px] font-bold text-[#6B7780] lg:hidden">{relativeDate}</div>
        </div>

        <BubbleTextArea
          index={index}
          timestamp={timestamp}
          text={text}
          onSelectChat={onSelectChat}
          selected={selected}
          isUser={isUser}
          loading={loading}
          first={first}
          setQuery={setQuery}
          followUpQuestions={followUpQuestions}
          single={single}
          doowii={doowii}
        />
      </div>
    </div>
  );
};
