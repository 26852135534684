import React from "react";

const ScatterChartIcon: React.FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" fill="none" />
    <path
      d="M0 0.5C0 0.223858 0.223858 0 0.5 0C0.776142 0 1 0.223858 1 0.5V17.5C1 17.7761 0.776142 18 0.5 18C0.223858 18 0 17.7761 0 17.5V0.5Z"
      fill="#2B3B46"
    />
    <path
      d="M17.5 17C17.7761 17 18 17.2239 18 17.5C18 17.7761 17.7761 18 17.5 18H0.5C0.223858 18 0 17.7761 0 17.5C1.20706e-08 17.2239 0.223858 17 0.5 17L17.5 17Z"
      fill="#2B3B46"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.17576 12C5.00419 12 5.67576 11.3284 5.67576 10.5C5.67576 9.67157 5.00419 9 4.17576 9C3.34733 9 2.67576 9.67157 2.67576 10.5C2.67576 11.3284 3.34733 12 4.17576 12ZM4.17576 11C4.4519 11 4.67576 10.7761 4.67576 10.5C4.67576 10.2239 4.4519 10 4.17576 10C3.89962 10 3.67576 10.2239 3.67576 10.5C3.67576 10.7761 3.89962 11 4.17576 11Z"
      fill="#2B3B46"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15.4293C12.8284 15.4293 13.5 14.7578 13.5 13.9293C13.5 13.1009 12.8284 12.4293 12 12.4293C11.1716 12.4293 10.5 13.1009 10.5 13.9293C10.5 14.7578 11.1716 15.4293 12 15.4293ZM12 14.4293C12.2761 14.4293 12.5 14.2055 12.5 13.9293C12.5 13.6532 12.2761 13.4293 12 13.4293C11.7239 13.4293 11.5 13.6532 11.5 13.9293C11.5 14.2055 11.7239 14.4293 12 14.4293Z"
      fill="#2B3B46"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.17576 4.70936C5.00419 4.70936 5.67576 4.03778 5.67576 3.20936C5.67576 2.38093 5.00419 1.70936 4.17576 1.70936C3.34733 1.70936 2.67576 2.38093 2.67576 3.20936C2.67576 4.03778 3.34733 4.70936 4.17576 4.70936ZM4.17576 3.70936C4.4519 3.70936 4.67576 3.4855 4.67576 3.20936C4.67576 2.93321 4.4519 2.70936 4.17576 2.70936C3.89962 2.70936 3.67576 2.93321 3.67576 3.20936C3.67576 3.4855 3.89962 3.70936 4.17576 3.70936Z"
      fill="#2B3B46"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1454 12C16.9738 12 17.6454 11.3284 17.6454 10.5C17.6454 9.67157 16.9738 9 16.1454 9C15.3169 9 14.6454 9.67157 14.6454 10.5C14.6454 11.3284 15.3169 12 16.1454 12ZM16.1454 11C16.4215 11 16.6454 10.7761 16.6454 10.5C16.6454 10.2239 16.4215 10 16.1454 10C15.8692 10 15.6454 10.2239 15.6454 10.5C15.6454 10.7761 15.8692 11 16.1454 11Z"
      fill="#2B3B46"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.3339 9.79294C10.1623 9.79294 10.8339 9.12137 10.8339 8.29294C10.8339 7.46452 10.1623 6.79295 9.3339 6.79295C8.50548 6.79295 7.8339 7.46452 7.8339 8.29294C7.8339 9.12137 8.50548 9.79294 9.3339 9.79294ZM9.3339 8.79294C9.61005 8.79294 9.8339 8.56909 9.8339 8.29294C9.8339 8.0168 9.61005 7.79295 9.3339 7.79295C9.05776 7.79295 8.8339 8.0168 8.8339 8.29294C8.8339 8.56909 9.05776 8.79294 9.3339 8.79294Z"
      fill="#2B3B46"
    />
  </svg>
);

export default ScatterChartIcon;
