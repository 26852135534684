import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { Heading } from "@instructure/ui";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useAuth } from "../../../context";
import { usePinboard } from "../../../context/pinboard";
import { Pin } from "../../../context/pinboard/pinboard.i";
import { useUI } from "../../../context/ui";
import { ViewEnum } from "../../../context/ui/ui.i";
import { focusById } from "../../../helpers/actionById";
import { CreateModal } from "../UI/Modals/Create";
import { DeleteModal } from "../UI/Modals/Delete";
import { filterSearch } from "./helpers";
import { PinboardEmpty } from "./PinboardEmpty/PinboardEmpty";
import { PinboardGridDnd } from "./PinboardGrid/PinboardGridDnd";
import { PinboardListDnd } from "./PinboardList/PinboardListDnd";
import { selectNextBoard, selectPreviousBoard } from "./PinboardTab.helper";
import { PinboardPresent } from "./PinFullScreenView/components/PinboardPresent";
import { SelectionAndSearch } from "./SelectionAndSearch/SelectionAndSearch";

export type PinView = "grid" | "list";

export const PinboardTab = () => {
  const { _ } = useLingui();
  const {
    currBoardId,
    pinboardResults,
    boards,
    loading,
    setCurrBoardId,
    addNewBoard,
    deleteBoard,
    setPinboardResults,
  } = usePinboard();
  const { setView } = useUI();
  const { featureFlags } = useAuth();

  const [search, setSearch] = useState("");
  const [filteredPinboardResults, setFilteredPinboardResults] = useState<Pin[]>([]);
  const [pinView, setPinView] = useState<PinView>("grid");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isPresentMode, setIsPresentMode] = useState(false);
  const [currentChartToPresent, setCurrentChartToPresent] = useState(0);

  useEffect(() => {
    const storedPinView = localStorage.getItem(`pinView-${currBoardId}`) as PinView | null;
    setPinView(storedPinView ?? "grid");
    setCurrentChartToPresent(0);
  }, [currBoardId]);

  const modalsClosed = !createModalOpen && !deleteModalOpen && !isPresentMode;
  useHotkeys("CTRL+SHIFT+1", () => setView(ViewEnum.CHAT_VIEW), { enabled: modalsClosed });
  useHotkeys("CTRL+ALT+1", () => focusById("pinboard-search-input"), { enabled: modalsClosed });
  useHotkeys("CTRL+ALT+2", () => setCreateModalOpen(true), { enabled: modalsClosed });
  useHotkeys("CTRL+ALT+3", () => selectPreviousBoard(currBoardId, boards, setCurrBoardId), {
    enabled: modalsClosed,
  });
  useHotkeys("CTRL+ALT+4", () => selectNextBoard(currBoardId, boards, setCurrBoardId), {
    enabled: modalsClosed,
  });

  const clearSearch = () => setSearch("");

  useEffect(() => {
    const filteredBoards = filterSearch(search, pinboardResults);

    setFilteredPinboardResults(filteredBoards);
  }, [search, pinboardResults]);

  const isEmpty = boards?.size === 0 ? true : false;

  return (
    <>
      <div className="chat-desktop:hidden">
        <Heading>
          <Trans>Pinboard</Trans>
        </Heading>
      </div>
      {isEmpty ? (
        <PinboardEmpty setCreateModalOpen={setCreateModalOpen} />
      ) : (
        <div className="flex h-full flex-col overflow-y-auto overflow-x-hidden">
          <SelectionAndSearch
            search={search}
            setSearch={setSearch}
            setPinView={setPinView}
            hasData={!!pinboardResults.length}
            setCreateModalOpen={setCreateModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            setIsPresentMode={setIsPresentMode}
          />
          {pinView === "grid" ? (
            <PinboardGridDnd
              loading={loading}
              currBoardId={currBoardId!}
              hasSearch={!!search.length}
              pinboardResults={pinboardResults}
              filteredPinboardResults={filteredPinboardResults}
              setFilteredPinboardResults={setFilteredPinboardResults}
              setPinboardResults={setPinboardResults}
              clearSearch={clearSearch}
            />
          ) : (
            <PinboardListDnd
              loading={loading}
              currBoardId={currBoardId!}
              hasSearch={!!search.length}
              pinboardResults={pinboardResults}
              filteredPinboardResults={filteredPinboardResults}
              setFilteredPinboardResults={setFilteredPinboardResults}
              setPinboardResults={setPinboardResults}
              clearSearch={clearSearch}
            />
          )}
        </div>
      )}
      {createModalOpen && (
        <CreateModal
          open={createModalOpen}
          setOpen={setCreateModalOpen}
          name={_(msg`Pinboard`)}
          action={addNewBoard}
          maxLength={80}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          name={_(msg`Pinboard`)}
          action={() => (deleteBoard as any)(currBoardId)}
          text={_(
            msg`Are you sure you want to delete this pinboard and all of its saved pins? This action can not be undone.`
          )}
        />
      )}
      {isPresentMode && (
        <PinboardPresent
          open={isPresentMode}
          setOpen={setIsPresentMode}
          charts={pinboardResults}
          currentChartToPresent={currentChartToPresent}
          setCurrentChartToPresent={setCurrentChartToPresent}
        />
      )}
    </>
  );
};
