/* eslint-disable lingui/no-unlocalized-strings */
export class AnswerVizError extends Error {
  name: string;
  code: string | number;

  constructor(message) {
    super(message);

    this.name = "AnswerVizError";
    this.code = "ANSWER_VIZ_ERROR";
  }
}
