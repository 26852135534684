// components/PinFullViewDrawer.tsx
import React, { FC, PropsWithChildren } from "react";
import { Tray } from "@instructure/ui";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import { PinViewContainerProps } from "../types";

/**
 * Drawer component for mobile view of the pin.
 */
export const PinFullViewDrawer: FC<PropsWithChildren<PinViewContainerProps>> = ({
  children,
  isOpen,
  handleClose,
}) => {
  const { _ } = useLingui();
  const [header, body, footer] = React.Children.toArray(children);
  return (
    <Tray
      label={_(msg`Pinboard full drawer`)}
      open={isOpen}
      onDismiss={handleClose}
      size="large"
      placement="bottom"
      id="full-screen-pin"
    >
      <div className="h-screen pt-16">
        <div className="relative flex h-full flex-col justify-between gap-6">
          <div className="p-5">
            <div className="mr-5 mt-[-6px] block">{header}</div>
            <div className="my-6"></div>
            <div>{body}</div>
          </div>
          <div className="flex justify-end bg-[#F5F5F5] p-4">{footer}</div>
        </div>
      </div>
    </Tray>
  );
};
