import React, { FC } from 'react';

const HashIcon: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M14.7703 0.00056243C14.484 0.0056243 14.2438 0.196288 14.1657 0.457255L14.1645 0.461755L9.02673 17.1592C9.00873 17.2154 8.99805 17.2807 8.99805 17.3481C8.99805 17.703 9.28601 17.9904 9.64034 17.9904C9.92774 17.9904 10.1713 17.8015 10.2528 17.5416L10.254 17.5371L15.3917 0.839708C15.4114 0.780653 15.4227 0.712599 15.4227 0.642295C15.4227 0.287402 15.1353 0 14.7804 0C14.7765 0 14.7725 0 14.7686 0L14.7703 0.00056243Z" fill="#2D3B45"/>
<path d="M8.34839 0.00056243C8.06211 0.0056243 7.82195 0.196288 7.74378 0.457255L7.74265 0.461755L2.60486 17.1592C2.58686 17.2154 2.57617 17.2807 2.57617 17.3481C2.57617 17.703 2.86414 17.9904 3.21847 17.9904C3.50587 17.9904 3.7494 17.8015 3.83095 17.5416L3.83208 17.5371L8.96987 0.839708C8.98956 0.780653 9.00081 0.712599 9.00081 0.642295C9.00081 0.287402 8.71341 0 8.35851 0C8.35458 0 8.35064 0 8.3467 0L8.34839 0.00056243Z" fill="#2D3B45"/>
<path d="M0.651294 5.14648C0.648481 5.14648 0.645669 5.14648 0.642295 5.14648C0.287402 5.14648 0 5.43389 0 5.78878C0 6.14367 0.287964 6.43107 0.642295 6.43107C0.645669 6.43107 0.648481 6.43107 0.651856 6.43107H17.3487C17.3515 6.43107 17.3543 6.43107 17.3577 6.43107C17.7126 6.43107 18 6.14367 18 5.78878C18 5.43389 17.7126 5.14648 17.3577 5.14648C17.3543 5.14648 17.3515 5.14648 17.3481 5.14648H0.651294Z" fill="#2D3B45"/>
<path d="M0.651294 11.5684C0.648481 11.5684 0.645669 11.5684 0.642295 11.5684C0.287402 11.5684 0 11.8558 0 12.2107C0 12.5655 0.287964 12.8529 0.642295 12.8529C0.645669 12.8529 0.648481 12.8529 0.651856 12.8529H17.3487C17.3515 12.8529 17.3543 12.8529 17.3577 12.8529C17.7126 12.8529 18 12.5655 18 12.2107C18 11.8558 17.7126 11.5684 17.3577 11.5684C17.3543 11.5684 17.3515 11.5684 17.3481 11.5684H0.651294Z" fill="#2D3B45"/>
</svg>

);

export {HashIcon};
