import React from "react";

const PieChartIcon: React.FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
      fill="#2B3B46"
    />
    <path
      d="M9 9.5C8.72386 9.5 8.5 9.27614 8.5 9L8.5 0.500047C8.5 0.223905 8.72386 4.75539e-05 9 4.75419e-05C9.27614 4.75298e-05 9.5 0.223905 9.5 0.500048V9C9.5 9.27614 9.27614 9.5 9 9.5Z"
      fill="#2B3B46"
    />
    <path
      d="M8.50005 9C8.50005 8.72386 8.7239 8.5 9.00005 8.5H17.5C17.7761 8.5 18 8.72386 18 9C18 9.27614 17.7761 9.5 17.5 9.5H9.00005C8.7239 9.5 8.50005 9.27614 8.50005 9Z"
      fill="#2B3B46"
    />
    <path
      d="M2.63607 15.3639C2.44081 15.1687 2.44081 14.8521 2.63607 14.6568L8.64645 8.64645C8.84171 8.45118 9.15829 8.45118 9.35355 8.64645C9.54881 8.84171 9.54881 9.15829 9.35355 9.35355L3.34318 15.3639C3.14791 15.5592 2.83133 15.5592 2.63607 15.3639Z"
      fill="#2B3B46"
    />
  </svg>
);

export default PieChartIcon;
