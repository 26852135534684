import { FC, ReactNode } from "react";
import {
  AuthUserProvider as AppAuthProvider,
  AuthUserProvider as CanvasAuthProvider,
} from "../auth";

export const ConditionalAuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const currentPath = window.location.pathname;

  if (currentPath.startsWith("/canvas-ui")) {
    return <CanvasAuthProvider>{children}</CanvasAuthProvider>;
  }

  return <AppAuthProvider>{children}</AppAuthProvider>;
};
