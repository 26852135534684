import React, { createContext, useContext, useState, useEffect, useLayoutEffect, FC } from "react";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { detect, fromUrl, fromStorage } from "@lingui/detect-locale";

// can be a function with custom logic or just a string, `detect` method will handle it
const DEFAULT_FALLBACK = () => "en";

const defaultLocale = detect(fromUrl("lang"), fromStorage("lang"), DEFAULT_FALLBACK);

const loadLocale = async (locale: string) => {
  const { messages } = await import(`../../locales/${locale}/messages`).catch(() => {
    console.warn(`No locale file found for ${locale}`);
    return { messages: {} };
  });

  console.log(`Loading locale "${locale}"`);
  i18n.loadAndActivate({ locale, messages });
};

const LocaleContext = createContext({
  locale: defaultLocale,
  setLocale: (locale: string) => {},
  isRTL: false,
});

const rtlLanguages = ["ar", "he", "fa"];

export const LocaleProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [locale, setLocale] = useState(defaultLocale);
  const [dir, setDir] = useState<"ltr" | "rtl">(
    rtlLanguages.includes(defaultLocale) ? "rtl" : "ltr"
  );

  useEffect(() => {
    loadLocale(locale);
    const newDir = rtlLanguages.includes(locale) ? "rtl" : "ltr";
    setDir(newDir);
  }, [locale]);

  useLayoutEffect(() => {
    document.documentElement.lang = locale;
    document.documentElement.dir = dir;
    document.body.style.direction = dir;
  }, [locale, dir]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale, isRTL: dir === "rtl" }}>
      <I18nProvider i18n={i18n}>
        <div style={{ direction: dir }}>{children}</div>
      </I18nProvider>
    </LocaleContext.Provider>
  );
};
// Useful for accessing & setting locale from anywhere in the app
export const useLocaleContext = () => useContext(LocaleContext);
