export const getRecentMessage = ({ allResults, answer }) => {
  if (allResults && answer && allResults.length === answer.length) {
    return allResults.slice(-5).map((result, index) => {
      let answerIndex;
      if (allResults.length <= 5) {
        answerIndex = index;
      } else {
        answerIndex = allResults.length - 5 + index;
      }

      return {
        query: result.query,
        sql: result.sql || "",
        answer: answer[answerIndex] || "",
      };
    });
  }

  return [];
};

export const getRecentMessageRegenerate = ({ allResults, answer, index }) => {
  if (allResults && answer && allResults.length === answer.length) {
    const startIndex = Math.max(0, index - 5);
    const endIndex = Math.max(index, 0);

    return allResults.slice(startIndex, endIndex).map((result, sliceIndex) => {
      let answerIndex = startIndex + sliceIndex;
      return {
        query: result.query,
        sql: result.sql || "",
        answer: answer[answerIndex] || "",
      };
    });
  }

  return [];
};
