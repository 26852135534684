import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { arrayMove } from "@dnd-kit/sortable";
import {
  IconButton,
  IconDragHandleLine,
  IconMoveDownBottomLine,
  IconMoveDownLine,
  IconMoveUpLine,
  IconMoveUpTopLine,
  Menu,
  Tooltip,
} from "@instructure/ui";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useAlert } from "../../../../context/alert";
import { usePinboard } from "../../../../context/pinboard";
import { Pin } from "../../../../context/pinboard/pinboard.i";
import { savePinOrders } from "../../../../services/webserver/pinboards";

interface MovePopoverProps {
  pinId: string;
  filteredPinboardResults?: Pin[];
  setFilteredPinboardResults?: React.Dispatch<React.SetStateAction<Pin[]>>;
  setPinboardResults: React.Dispatch<React.SetStateAction<Pin[]>>;
  listeners?: SyntheticListenerMap;
  attributes?: DraggableAttributes;
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
}

const MovePopover = ({
  pinId,
  filteredPinboardResults,
  setFilteredPinboardResults,
  setPinboardResults,
  listeners,
  attributes,
  setActivatorNodeRef,
}: MovePopoverProps) => {
  const { currBoardId } = usePinboard();
  const { showAlert } = useAlert();
  const { _ } = useLingui();

  const isTop = filteredPinboardResults?.findIndex((r) => r.id === pinId) === 0;
  const isBottom =
    filteredPinboardResults?.findIndex((r) => r.id === pinId) ===
    filteredPinboardResults?.length - 1;
  const pinTitle = filteredPinboardResults?.find((r) => r.id === pinId)?.title;

  const movePin = (pinId, newIndex) => {
    setFilteredPinboardResults((results) => {
      const oldIndex = results.findIndex((r) => r.id === pinId);
      if (oldIndex === -1 || newIndex < 0 || newIndex >= results.length) return results;

      const newArray = arrayMove(results, oldIndex, newIndex);

      savePinOrders(
        currBoardId,
        newArray.map((r) => r.id)
      ).catch((error) => {
        console.error("Error saving pin order:", error);

        showAlert({
          variant: "error",
          message: _(msg`Failed to save the pin order. Please try again later.`),
        });
      });

      return newArray;
    });
    setPinboardResults((results: Pin[]): Pin[] => {
      const oldIndex = results.findIndex((r) => r.id === pinId);
      if (oldIndex === -1 || newIndex < 0 || newIndex >= results.length) return results;

      return arrayMove(results, oldIndex, newIndex);
    });
  };

  const handleMoveUp = () => {
    const oldIndex = filteredPinboardResults.findIndex((r) => r.id === pinId);
    movePin(pinId, oldIndex - 1);
  };

  const handleMoveDown = () => {
    const oldIndex = filteredPinboardResults.findIndex((r) => r.id === pinId);
    movePin(pinId, oldIndex + 1);
  };

  const handleMoveToTop = () => {
    movePin(pinId, 0);
  };

  const handleMoveToBottom = () => {
    movePin(pinId, filteredPinboardResults.length - 1);
  };

  return (
    <Menu
      trigger={
        <Tooltip renderTip={<Trans> Move pin</Trans>}>
          <IconButton
            renderIcon={IconDragHandleLine}
            {...listeners}
            {...attributes}
            elementRef={setActivatorNodeRef}
            screenReaderLabel={`Move pin: ${pinTitle}`}
            withBackground={false}
            withBorder={false}
          ></IconButton>
        </Tooltip>
      }
    >
      {!isTop && (
        <Menu.Item
          onSelect={() => {
            handleMoveToTop();
          }}
        >
          <span className="flex items-center  gap-2">
            <IconMoveUpTopLine />
            <Trans>Move to Top</Trans>
          </span>
        </Menu.Item>
      )}

      {!isTop && (
        <Menu.Item
          onSelect={() => {
            handleMoveUp();
          }}
        >
          <span className="flex items-center  gap-2">
            <IconMoveUpLine />
            <Trans>Move up</Trans>
          </span>
        </Menu.Item>
      )}
      {!isBottom && (
        <Menu.Item
          onSelect={() => {
            handleMoveDown();
          }}
        >
          <span className="flex items-center  gap-2">
            <IconMoveDownLine />
            <Trans>Move down</Trans>
          </span>
        </Menu.Item>
      )}
      {!isBottom && (
        <Menu.Item
          onSelect={() => {
            handleMoveToBottom();
          }}
        >
          <span className="flex items-center  gap-2">
            <IconMoveDownBottomLine />
            <Trans>Move to bottom</Trans>
          </span>
        </Menu.Item>
      )}
    </Menu>
  );
};

export { MovePopover };
