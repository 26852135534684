import {
  Flex,
  IconButton,
  IconCheckLine,
  IconEndLine,
  IconPlusLine,
  Link,
  ScreenReaderContent,
  Spinner,
  TextInput,
  Tooltip,
} from "@instructure/ui";
import { useEffect, useState } from "react";

import clsx from "clsx";
import { useAuth } from "../../../../../../context";
import { usePinboard } from "../../../../../../context/pinboard";
import { withSentry } from "../../../../../../helpers/wrapper";
import { useLingui } from "@lingui/react";
import { Trans, msg } from "@lingui/macro";

interface CreatePinboardSectionProps {
  setSelectedBoards: any; // ? type mismatch
  // setSelectedBoards: Dispatch<(currentState: string[]) => string[]>;
  creating: boolean;
  loading: boolean;
  setCreating: (loading: boolean) => void;
}

export const CreatePinboardSection = ({
  setSelectedBoards,
  loading,
  creating,
  setCreating,
}: CreatePinboardSectionProps) => {
  const { _ } = useLingui();
  const { authUser } = useAuth();
  const { addNewBoard } = usePinboard();

  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [isEditMode, setEditMode] = useState(false);

  useEffect(() => {
    setError("");
  }, [value]);

  const onEnter: any = withSentry(async (e: KeyboardEvent) => {
    if (e.key !== "Enter" || value.trim() === "") return;

    await addBoard();
  });

  const addBoard: any = withSentry(async () => {
    setError("");

    const finalValue = value.trim();

    if (finalValue === "") {
      setError(_(msg`Please enter a valid title`));
      return;
    }

    try {
      if (!authUser) throw new Error("User not found");

      setCreating(true);
      const pinboardId = await addNewBoard(finalValue);
      setCreating(false);

      selectCreatedPinboard(pinboardId);

      setValue("");
      setEditMode(false);
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(e.message);
      }

      throw e;
    } finally {
      setCreating(false);
    }
  });

  const selectCreatedPinboard = (key: string) => {
    setSelectedBoards((prevSelectedBoards) => {
      return [...prevSelectedBoards, key];
    });
  };

  const hasError = !!error;

  if (isEditMode) {
    return (
      <Flex>
        <TextInput
          renderLabel={
            <ScreenReaderContent>
              <Trans>New pinboard name</Trans>
            </ScreenReaderContent>
          }
          placeholder={_(msg`Enter a name for this pinboard`)}
          onKeyDown={onEnter}
          value={value}
          disabled={loading}
          onChange={(e) => setValue(e.target.value)}
          renderAfterInput={
            creating && <Spinner renderTitle={_(msg`Loading`)} size="x-small" margin="0" />
          }
          messages={error ? [{ type: "error", text: error }] : []}
        />
        <div className={clsx({ "mb-7": !!error })}>
          <Tooltip renderTip={_(msg`Cancel creating pinboard`)}>
            <IconButton
              margin="0 0 0 medium"
              renderIcon={IconEndLine}
              screenReaderLabel={_(msg`Cancel creating pinboard`)}
              disabled={loading}
              onClick={() => {
                setValue("");
                setEditMode(false);
              }}
            />
          </Tooltip>
        </div>
        <div className={clsx({ "mb-7": !!error })}>
          {!!value.trim().length ? (
            <Tooltip renderTip={_(msg`Add pinboard`)}>
              <IconButton
                margin="0 0 0 small"
                renderIcon={IconCheckLine}
                screenReaderLabel={_(msg`Add pinboard`)}
                onClick={addBoard}
                disabled={loading || hasError}
              />
            </Tooltip>
          ) : (
            <IconButton
              margin="0 0 0 small"
              renderIcon={IconCheckLine}
              screenReaderLabel={_(msg`Add pinboard`)}
              onClick={addBoard}
              disabled
            />
          )}
        </div>
      </Flex>
    );
  }
  return (
    <Link isWithinText={false} renderIcon={<IconPlusLine />} onClick={() => setEditMode(true)}>
      <Trans>Add New Pinboard</Trans>
    </Link>
  );
};
