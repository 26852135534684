import { Button, IconButton } from "@instructure/ui";
import {
  IconArrowOpenEndLine,
  IconArrowOpenStartLine,
  IconMoreLine,
  IconPinLine,
  IconStatsLine,
} from "@instructure/ui-icons";
import { FC } from "react";

import { ChartTitle } from "./ChartTitle/ChartTitle";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export const WhiteBoardHeaderDisabled: FC = () => {
  const { _ } = useLingui();
  return (
    <div className="flex items-center justify-between p-[6px]">
      <div className="flex">
        <IconButton
          size="small"
          margin="small"
          renderIcon={IconArrowOpenStartLine}
          withBackground={false}
          withBorder={false}
          screenReaderLabel={_(msg`Navigate Left`)}
          onClick={() => {}}
          disabled
        />

        <IconButton
          size="small"
          margin="small"
          renderIcon={IconArrowOpenEndLine}
          withBackground={false}
          withBorder={false}
          screenReaderLabel={_(msg`Navigate Right`)}
          onClick={() => {}}
          disabled
        />
      </div>
      <div className="flex flex-1">
        <ChartTitle results={null} updateTitle={() => {}} disabled />
      </div>
      <div className="flex">
        <Button renderIcon={<IconPinLine />} disabled color="primary" id="add-to-pinboard-button">
          <Trans>Pin It</Trans>
        </Button>
        <IconButton
          renderIcon={IconStatsLine}
          withBackground={false}
          withBorder={false}
          screenReaderLabel={_(msg`Select chart type`)}
          disabled
        />
        <IconButton
          renderIcon={IconMoreLine}
          withBackground={false}
          withBorder={false}
          screenReaderLabel={_(msg`More options`)}
          disabled
        />
      </div>
    </div>
  );
};
