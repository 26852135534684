import styled from "styled-components";

export const ChartWarning = styled.div`
  color: gray;
  font-size: 11px;
  position: absolute;
  bottom: 0px;
  left: 10px;
  z-index: 1001;
`;
