import { Flex, Text } from "@instructure/ui";

import { EMPTY_ROWS_MESSAGE, ERROR, ERROR_MESSAGE } from "../../../../Constants/constants";
import error from "./error.png";
import { useLingui } from "@lingui/react";

interface ErrorDisplayProps {
  version: "EMPTY_ROWS" | "ERROR";
}
export const ErrorDisplay = ({ version }: ErrorDisplayProps) => {
  const { _ } = useLingui();
  const errorMessage = version === ERROR ? ERROR_MESSAGE : EMPTY_ROWS_MESSAGE;
  const translatedErrorMessage = _(errorMessage);
  return (
    <Flex
      direction="column"
      justifyItems="center"
      alignItems="center"
      gap="large"
      height="100%"
      margin="large"
    >
      <Flex.Item>
        <img src={error} alt="ERROR" width={250} />
      </Flex.Item>
      <Flex.Item textAlign="center" width={450}>
        <Text>{translatedErrorMessage}</Text>
      </Flex.Item>
    </Flex>
  );
};
