import {
  IconButton,
  IconCheckLine,
  IconEndLine,
  ScreenReaderContent,
  Spinner,
  TextInput,
  Tooltip,
} from "@instructure/ui";
import { FC, useState } from "react";

import { usePinboard } from "../../../../../context/pinboard";
import { withSentry } from "../../../../../helpers/wrapper";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export const EditMode: FC<{ setEditMode: (value: boolean) => void }> = ({ setEditMode }) => {
  const { _ } = useLingui();
  const { boards, updateBoardName, currBoardId } = usePinboard();

  const [title, setTitle] = useState(boards?.get(currBoardId).name);
  const [loading, setLoading] = useState(false);

  const isTitleInvalid = title.trim() === "";

  const onConfirm: any = withSentry(async () => {
    const value = title.trim();

    if (value === "") {
      return;
    }

    if (!currBoardId) {
      throw new Error("Board not found");
    }

    setLoading(true);
    await updateBoardName(currBoardId, value);
    setLoading(false);

    setEditMode(false);
  });

  return (
    <>
      <div className="flex-1">
        <TextInput
          renderLabel={
            <ScreenReaderContent>
              <Trans>Type to change Pinboard name</Trans>
            </ScreenReaderContent>
          }
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          disabled={loading}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onConfirm();
            }
          }}
          maxLength={80}
          renderAfterInput={
            loading && <Spinner renderTitle={<Trans>Loading</Trans>} size="x-small" margin="0" />
          }
        />
      </div>
      <Tooltip renderTip={<Trans>Cancel</Trans>} on={["hover", "focus"]}>
        <IconButton
          renderIcon={<IconEndLine />}
          screenReaderLabel={_(msg`Cancel`)}
          disabled={loading}
          onClick={() => setEditMode(false)}
        />
      </Tooltip>
      <Tooltip renderTip={<Trans>Confirm</Trans>} on={["hover", "focus"]}>
        <IconButton
          renderIcon={<IconCheckLine />}
          screenReaderLabel={_(msg`Confirm`)}
          disabled={loading || isTitleInvalid}
          onClick={onConfirm}
        />
      </Tooltip>
    </>
  );
};
