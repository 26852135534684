/* eslint-disable lingui/no-unlocalized-strings */
export const NORMAL_OPERATIONS = "NORMAL_OPERATIONS";
export const DEGRADED_OPERATIONS = "DEGRADED_OPERATIONS";
export const OUTAGE = "OUTAGE";
export const UNDER_MAINTENANCE = "UNDER_MAINTENANCE";
export const MIAMI_UNIVERSITY = "9KESRqEjqqbV3Nj4dwkMar8VKzE5FDx63Oi9VTKR";
export const CORNELL_UNIVERSITY = "GTDkw0Eah6uohqhXZ7zSk8TH1zJG9mbz7wTVI1Si";
export const PROJECT_MAP = {
  "doowii-prod": "Americas",
  "doowii-prod-uk": "Europe",
  "doowii-prod-asia": "Asia",
  "doowii-prod-au": "Australia",
  "doowii-prod-ca": "Canada",
};
