import { AccessibleContent, Badge, Button, IconPinLine } from "@instructure/ui";
import React, { useEffect, useState } from "react";
import { usePinboard } from "../../../../../context/pinboard";
import { useChatData } from "../../../../../context/chat";
import { useChart } from "../../../../../context/chart";
import { PinboardForm } from "../Whiteboard/PinboardForm/PinboardForm";
import { Trans, plural } from "@lingui/macro";
import { VisualizationTypesEnum } from "../../../../../context/pinboard/pinboard.i";

type PinButtonProps = {
  full?: boolean;
  chartType: VisualizationTypesEnum;
  disabled?: boolean;
  isOpen: boolean;
  onClick: () => void;
  setIsOpen: (value: boolean) => void;
};

const PinButton = ({ full, disabled, chartType, isOpen, onClick, setIsOpen }: PinButtonProps) => {
  const { boards } = usePinboard();
  const [totalPins, setTotalPins] = useState(0);
  const { currentResult: result } = useChatData();
  const { selectedColumns, charts } = useChart();

  const countBoardsWithPin = (boards: any, pinId: string): number => {
    if (pinId === undefined || pinId === null) return 0;
    let count = 0;
    boards.forEach((board) => {
      if (board.pins && board.pins.includes(pinId)) {
        count++;
      }
    });
    return count;
  };

  useEffect(() => {
    setTotalPins(countBoardsWithPin(boards, result?.id));
  }, [boards, result]);

  return (
    <>
      <Badge
        display={full ? "block" : "inline-block"}
        count={totalPins}
        margin="0"
        themeOverride={{
          color: "#0476b5",
          colorPrimary: "#e6f1f8",
        }}
        formatOutput={function (formattedCount) {
          return (
            <AccessibleContent
              alt={plural(totalPins, {
                one: `This chat has been pinned to # board`,
                other: `This chat has been pinned to # boards`,
              })}
            >
              {formattedCount}
            </AccessibleContent>
          );
        }}
      >
        <Button
          display={full ? "block" : "inline-block"}
          renderIcon={<IconPinLine />}
          onClick={onClick}
          disabled={disabled}
          color="primary"
          id="add-to-pinboard-button"
        >
          <Trans>Pin It</Trans>
        </Button>
      </Badge>

      <PinboardForm
        open={isOpen}
        setOpen={setIsOpen}
        visualisation={chartType}
        charts={charts}
        selectedColumns={selectedColumns}
      />
    </>
  );
};

export default PinButton;
