import useBreakpoint from "../../../../../../../../hooks/useBreakpoint";
import { useLocaleContext } from "../../../../../../../../context/locale";

interface BubbleArrowProps {
  isUser: boolean;
  selected: boolean;
}

export const BubbleArrow = ({ isUser, selected }: BubbleArrowProps) => {
  const isSelected = isUser && selected;
  const { isChatDesktop } = useBreakpoint();
  const { isRTL } = useLocaleContext();

  const applySelectionStyle = isSelected && isChatDesktop;

  const MobileArrow = (
    <svg
      width="12"
      height="11.146"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        top: applySelectionStyle ? "-9px" : "-10px",
        [isRTL ? "right" : "left"]: "40px",
        transform: isRTL ? "scaleX(-1)" : "none",
      }}
      className="lg:hidden"
    >
      <path d="M1 3L12 15H1V3Z" fill={isUser ? "#E6F1F8" : "#fff"} />
      <path
        d="M0 0.429199L13.5 15H12H8H0V0.429199ZM1 3V15H12L1 3Z"
        fill={applySelectionStyle ? "#0374B5" : "#C7CDD1"}
        strokeWidth={applySelectionStyle ? 1 : 0}
        stroke="#0374B5"
      />
    </svg>
  );

  const DesktopArrow = (
    <svg
      width="12"
      height="11.146"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        top: "20px",
        [isRTL ? "right" : "left"]: applySelectionStyle ? "-10px" : "-11px",
        transform: isRTL ? "scaleX(-1)" : "none",
      }}
      className="hidden lg:block"
    >
      <path
        d="M 13.93 2.322 L 13.784 13.468 L 2.637 2.322 L 13.93 2.322 Z"
        stroke={applySelectionStyle ? "#0374B5" : "#C7CDD1"}
        strokeWidth={applySelectionStyle ? 2 : 1}
        fill={isUser ? "#E6F1F8" : "#fff"}
      />
    </svg>
  );

  return (
    <>
      {MobileArrow}
      {DesktopArrow}
    </>
  );
};
