/* eslint-disable lingui/no-unlocalized-strings */
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { statusColorMappings, styles } from "./styles";
import { OperationStatus } from "./interface";
import { PROJECT_MAP } from "./constants";
import "./status.css";

interface StatusTableProps {
  statuses: Record<string, Record<string, OperationStatus>>;
  projects: string[];
}

export default function StatusTable({ statuses, projects }: StatusTableProps) {
  const services = ["Client", "Backend", "Data Pipeline"];

  const getServiceStatus = (project: string, service: string) => {
    switch (service) {
      case "Data Pipeline":
        return statuses[project]?.pipeline_status;
      case "Backend":
        return statuses[project]?.sequalizer_status;
      default:
        return statuses[project]?.frontend_status;
    }
  };

  return (
    <TableContainer component={Paper} style={{ marginTop: "20px" }}>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#3CA0C3" }}>
            <TableCell sx={{ color: "white", fontWeight: "bold" }}>Service</TableCell>
            {projects.map((project) => (
              <TableCell key={project} align="center" sx={{ color: "white", fontWeight: "bold" }}>
                {PROJECT_MAP[project]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((service) => (
            <TableRow key={service}>
              <TableCell>{service}</TableCell>
              {projects.map((project) => (
                <TableCell key={project} align="center">
                  {getServiceStatus(project, service) ? (
                    <span
                      style={styles.dot(statusColorMappings[getServiceStatus(project, service)])}
                      title={getServiceStatus(project, service)}
                    ></span>
                  ) : (
                    <span className="pulsing-dot" title={"Loading..."}></span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
