import { OperationStatus } from "./interface";

export const styles = {
  dot: (color) => ({
    height: "15px",
    width: "15px",
    backgroundColor: color,
    borderRadius: "50%",
    display: "inline-block",
    margin: "0 8px 0 0",
  }),
  pulsingDot: {
    height: "15px",
    width: "15px",
    backgroundColor: "grey",
    borderRadius: "50%",
    display: "inline-block",
    margin: "0 8px 0 0",
  },
  statusItem: {
    display: "flex",
    alignItems: "center",
    marginRight: "20px",
  },
};

export const statusColorMappings: Record<OperationStatus, string> = {
  NORMAL_OPERATIONS: "green",
  DEGRADED_OPERATIONS: "#F5CC61",
  OUTAGE: "#D42828",
  UNDER_MAINTENANCE: "#3CA0C3",
};
