export class SequalizerError extends Error {
  name: string;
  code: string | number;

  constructor(message, name, code) {
    super(message);

    this.name = name;
    this.code = code;
  }
}
