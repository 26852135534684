import { FC, ReactElement } from "react";

import happyPanda from "./waving.png";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

export const HappyPanda: FC<{ children: ReactElement | ReactElement[] }> = ({ children }) => {
  const { _ } = useLingui();
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-4">
      <img src={happyPanda} alt={_(msg`No results`)} />
      <div className="flex flex-col items-center text-center">{children}</div>
    </div>
  );
};
