import React, { createContext, useContext, useState, ReactNode } from "react";

type Drawers = {
  result: boolean;
  pin: boolean;
  summary: boolean;
};

type DrawerContextType = {
  drawers: Drawers;
  setDrawerState: (drawer: keyof Drawers, isOpen: boolean) => void;
  toggleDrawer: (drawer: keyof Drawers) => void;
};

const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

export const useDrawer = (): DrawerContextType => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error("useDrawer must be used within a DrawerProvider");
  }
  return context;
};

const DrawerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [drawers, setDrawers] = useState<Drawers>({
    result: false,
    pin: false,
    summary: false,
  });

  const setDrawerState = (drawer: keyof Drawers, isOpen: boolean) => {
    setDrawers((prevDrawers) => ({
      ...prevDrawers,
      [drawer]: isOpen,
    }));
  };

  const toggleDrawer = (drawer: keyof Drawers) => {
    setDrawers((prevDrawers) => {
      const isOpen = !prevDrawers[drawer];
      return {
        ...prevDrawers,
        [drawer]: { isOpen },
      };
    });
  };

  return (
    <DrawerContext.Provider value={{ drawers, setDrawerState, toggleDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
