/* eslint-disable lingui/no-unlocalized-strings */
import { useMediaQuery } from "@uidotdev/usehooks";
import screens from "../tailwind.screens";

type Breakpoints = keyof typeof screens;

const breakpoints: { [key in Breakpoints]: string } = {
  xs: `only screen and (min-width: ${screens.xs})`,
  sm: `only screen and (min-width: ${screens.sm})`,
  md: `only screen and (min-width: ${screens.md})`,
  "chat-desktop": `only screen and (min-width: ${screens["chat-desktop"]})`,
  lg: `only screen and (min-width: ${screens.lg})`,
  xl: `only screen and (min-width: ${screens.xl})`,
  "2xl": `only screen and (min-width: ${screens["2xl"]})`,
};

const useBreakpoint = () => {
  const isXs = useMediaQuery(breakpoints.xs);
  const isSm = useMediaQuery(breakpoints.sm);
  const isMd = useMediaQuery(breakpoints.md);
  const isChatDesktop = useMediaQuery(breakpoints["chat-desktop"]);
  const isLg = useMediaQuery(breakpoints.lg);
  const isXl = useMediaQuery(breakpoints.xl);
  const is2xl = useMediaQuery(breakpoints["2xl"]);

  return {
    isXs,
    isSm,
    isMd,
    isChatDesktop,
    isLg,
    isXl,
    is2xl,
  };
};

export default useBreakpoint;
