import { Button, Link, ScreenReaderContent, Text, TextArea } from "@instructure/ui";

import { useChatData } from "../../../../../context/chat";

import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { QUESTION_LENGTH_LIMIT } from "../../../../../Constants/constants";
import { useUI } from "../../../../../context/ui";
import { getRecentMessage } from "../../../../../helpers/getRecentMessage";
import { Doowii } from "../../../../../search/Doowii";
import { LightBulbIcon } from "./LightBulbIcon";
import TipsModal from "./TipsModal";

interface SearchBarProps {
  doowii: MutableRefObject<Doowii | null>;
  query: string;
  setQuery: any;
}

export default function SearchBar({ doowii, query, setQuery }: SearchBarProps) {
  const { model } = useUI();
  const { allResults, answer, loading } = useChatData();
  const { setTipsModalOpen } = useUI();
  const [message, setMessage] = useState([]);
  const { _ } = useLingui();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading || e.target.value.length > QUESTION_LENGTH_LIMIT) return;
    if (query) {
      doowii.current.chat({
        query,
        index: allResults.length,
        model,
        recent_messages: getRecentMessage({ allResults, answer }),
      });
      setQuery("");
    }
  };

  const placeholder = allResults?.length
    ? _(
        msg`Let me know if you would like to refine the results.  You can also start a new chat from My Chats above.`
      )
    : _(msg`Describe what you are looking for.`);

  const handleInputChange = (e) => {
    if (e.target.value.length > QUESTION_LENGTH_LIMIT) {
      setMessage([
        {
          text: _(msg`Please keep your question under ${QUESTION_LENGTH_LIMIT} characters.`),
          type: "error",
        },
      ]);
    } else {
      setMessage([]);
    }
    setQuery(e.target.value);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      <div
        className="flex flex-col items-end overflow-auto px-6 py-3"
        style={{ scrollbarGutter: "stable" }}
      >
        <TextArea
          label={
            <ScreenReaderContent>
              <Trans>User question</Trans>
            </ScreenReaderContent>
          }
          placeholder={placeholder}
          value={query}
          onChange={(e) => handleInputChange(e)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSubmit(e);
          }}
          disabled={loading}
          rows={3}
          maxHeight={100}
          id="chat-input"
          messages={message}
          ref={inputRef}
        />
        <div className="flex w-full justify-between pt-3">
          <Link onClick={() => setTipsModalOpen(true)} isWithinText={false} forceButtonRole={true}>
            <div className="flex items-center">
              <LightBulbIcon />
              <Text size="small">
                <Trans>Tips on effective prompt composition</Trans>
              </Text>
            </div>
          </Link>

          <Button
            type="submit"
            color="primary"
            disabled={loading || query.length > QUESTION_LENGTH_LIMIT}
            onClick={handleSubmit}
          >
            <Trans>Submit</Trans>
          </Button>
        </div>
      </div>
    </>
  );
}
