export const getBoardIndex = (boardId: string, boards: any) => {
  return boards.findIndex((option: any) => option.id === boardId);
};

export const selectPreviousBoard = (
  currentBoard: any,
  boards: any,
  selectBoard: (boardId: string) => void
) => {
  const boardsAsArray: any[] = Array.from(boards.values());
  const index = getBoardIndex(currentBoard, boardsAsArray);

  if (index === 0) {
    return;
  }

  const previousBoard = boardsAsArray[index - 1];
  (selectBoard as any)(previousBoard.id);
};

export const selectNextBoard = (
  currentBoard: any,
  boards: any,
  selectBoard: (boardId: string) => void
) => {
  const boardsAsArray: any[] = Array.from(boards.values());
  const index = getBoardIndex(currentBoard, boardsAsArray);

  if (index === boardsAsArray.length - 1) {
    return;
  }

  const nextBoard = boardsAsArray[index + 1];
  (selectBoard as any)(nextBoard.id);
};
