import { IconButton, IconEditLine, IconTrashLine, Tooltip } from "@instructure/ui";
import { FC } from "react";
import { CustomSelect } from "../../../UI/CustomSelect/CustomSelect";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

interface SelectModeProps {
  boards: Map<string, any>;
  currentBoardName: string;
  currBoardId: string;
  handlePinboardSelect: (value: any, id: any) => void;
  setEditMode: (value: boolean) => void;
  setDeleteModalOpen: (value: boolean) => void;
}

export const SelectMode: FC<SelectModeProps> = ({
  boards,
  currentBoardName,
  currBoardId,
  handlePinboardSelect,
  setEditMode,
  setDeleteModalOpen,
}) => {
  const { _ } = useLingui();
  return (
    <>
      <div className="flex-1">
        <CustomSelect
          options={boards}
          selected={{ label: currentBoardName, value: currBoardId }}
          onSelect={handlePinboardSelect}
          createNewLabel={_(msg`Create new pinboard`)}
        />
      </div>

      <div className="hidden md:block">
        <Tooltip renderTip={_(msg`Edit pinboard name`)} on={["hover", "focus"]}>
          <IconButton
            renderIcon={<IconEditLine />}
            withBackground={false}
            withBorder={false}
            screenReaderLabel={_(msg`Edit pinboard name`)}
            onClick={() => setEditMode(true)}
          />
        </Tooltip>
      </div>

      <div className="hidden md:block">
        <Tooltip renderTip={_(msg`Delete pinboard`)} on={["hover", "focus"]}>
          <IconButton
            renderIcon={<IconTrashLine />}
            withBackground={false}
            withBorder={false}
            screenReaderLabel={_(msg`Delete pinboard`)}
            onClick={() => setDeleteModalOpen(true)}
          />
        </Tooltip>
      </div>
    </>
  );
};
