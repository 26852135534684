import { Flex, IconButton, Menu, Tooltip } from "@instructure/ui";
import {
  IconCheckMarkSolid,
  IconEditLine,
  IconEndLine,
  IconMoreLine,
  IconTrashLine,
} from "@instructure/ui-icons";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ParentDocTypeEnum } from "../../../../../api/retriever.i";
import { CHAT_VIEW, SINGLE_METRIC } from "../../../../../Constants/constants";
import { useAuth } from "../../../../../context";
import { useAlert } from "../../../../../context/alert";
import { useChart } from "../../../../../context/chart";
import { usePinboard } from "../../../../../context/pinboard";
import { Pin } from "../../../../../context/pinboard/pinboard.i";
import { useUI } from "../../../../../context/ui";
import { downloadCSV } from "../../../../../helpers/downloadCSV";
import { withSentry } from "../../../../../helpers/wrapper";
import useBreakpoint from "../../../../../hooks/useBreakpoint";
import { Analytics } from "../../../../../services/analytics/Analytics";

interface ActionButtonsProps {
  value: string;
  pinResult: Pin;
  readOnly: boolean;
  setDeleteChartModalOpen: (value: boolean) => void;
  setReadOnly: (value: boolean) => void;
  updateTitle: (pinboardId: string, pinId: string, title: string) => Promise<any>;
  setValue: (value: string) => void;
}

export const ActionButtons = ({
  value,
  setValue,
  readOnly,
  setReadOnly,
  setDeleteChartModalOpen,
  updateTitle,
  pinResult,
}: ActionButtonsProps) => {
  const { _ } = useLingui();
  const { view } = useUI();
  const { currBoardId } = usePinboard();
  const { baseURL, featureFlags } = useAuth();
  const { exportChart } = useChart();
  const { isChatDesktop } = useBreakpoint();
  const { alertPromise } = useAlert();

  const handleEndClick = () => {
    setReadOnly(true);
    setValue(pinResult?.title);
  };

  const handleCheckClick: any = withSentry(async () => {
    view === CHAT_VIEW
      ? await (updateTitle as any)(value) // ?? why doesn't it use all arguments?
      : await updateTitle(currBoardId!, pinResult?.id, value);

    setReadOnly(true);
  });

  const handleDownloadPDF = withSentry(() => {
    alertPromise({
      promise: () =>
        exportChart({
          pins: [pinResult],
          baseURL,
          fileName: `${pinResult.title}.pdf`,
          type: "PDF",
          onComplete: () => {
            Analytics.track("Downloaded Pin PDF");
          },
        }),
      loading: _(msg`Preparing Pinboard PDF...`),
      success: _(msg`Pinboard PDF prepared successfully`),
      error: _(msg`Pinboard PDF preparation failed`),
    });
  });

  const handleDownloadCSV = withSentry(() => {
    alertPromise({
      promise: () =>
        downloadCSV({
          doc_id: pinResult?.id,
          parent_doc_id: currBoardId!,
          parent_doc_type: ParentDocTypeEnum.PINBOARD,
          filename: `${pinResult?.title}.csv`,
        }),
      loading: _(msg`Preparing CSV...`),
      success: _(msg`CSV prepared successfully`),
      error: _(msg`CSV preparation failed`),
    });
  });

  const handleDownloadPNG = withSentry(() => {
    alertPromise({
      promise: () =>
        exportChart({
          pins: [pinResult],
          type: "PNG",
          pinId: pinResult.id,
          onComplete: () => {
            Analytics.track("Downloaded Pin PNG");
          },
        }),
      loading: _(msg`Preparing Pinboard PNG...`),
      success: _(msg`Pinboard PNG prepared successfully`),
      error: _(msg`PNG preparation failed`),
    });
  });

  return (
    <>
      {readOnly ? (
        <Menu
          trigger={
            <Tooltip renderTip={<Trans>More Options</Trans>}>
              <IconButton
                withBackground={false}
                withBorder={false}
                screenReaderLabel={_(msg`More options`)}
              >
                <IconMoreLine />
              </IconButton>
            </Tooltip>
          }
        >
          <Menu.Item onSelect={() => setReadOnly(false)}>
            <Flex alignItems="center" gap="x-small">
              <IconEditLine />
              <Trans>Edit name</Trans>
            </Flex>
          </Menu.Item>
          <Menu.Item onSelect={() => setDeleteChartModalOpen(true)}>
            <Flex alignItems="center" gap="x-small">
              <IconTrashLine />
              <Trans>Delete</Trans>
            </Flex>
          </Menu.Item>
          {isChatDesktop && (
            <Menu label={_(msg`Download`)}>
              <Menu.Group label={_(msg`Download as`)}>
                <Menu.Separator />
                <Menu.Item onSelect={handleDownloadCSV}>
                  <Trans>Comma Separated values (.csv)</Trans>
                </Menu.Item>
                <Menu.Item
                  onSelect={handleDownloadPNG}
                  disabled={
                    pinResult?.visualisation === "TABLE" ||
                    pinResult?.visualisation === SINGLE_METRIC
                  }
                >
                  <Trans>PNG image (.png)</Trans>
                </Menu.Item>
                <Menu.Item onSelect={handleDownloadPDF}>
                  <Trans>PDF Document (.pdf)</Trans>
                </Menu.Item>
              </Menu.Group>
            </Menu>
          )}
        </Menu>
      ) : (
        <>
          <Tooltip renderTip={<Trans>Confirm</Trans>}>
            <IconButton
              margin="xx-small"
              withBackground={false}
              withBorder={false}
              onClick={handleCheckClick}
              screenReaderLabel={_(msg`Submit new name`)}
            >
              <IconCheckMarkSolid color="success" />
            </IconButton>
          </Tooltip>
          <Tooltip renderTip={<Trans>Cancel</Trans>}>
            <IconButton
              margin="xx-small"
              withBackground={false}
              withBorder={false}
              onClick={handleEndClick}
              screenReaderLabel={_(msg`Cancel edit name`)}
            >
              <IconEndLine color="error" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
};
