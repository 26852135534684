/* eslint-disable lingui/no-unlocalized-strings */
import { styles } from "./styles";
import { Typography, Box } from "@mui/material";

export default function StatusDots() {
  return (
    <Box display="flex" justifyContent="start" alignItems="center" mt={2}>
      <div style={styles.statusItem}>
        <span style={styles.dot("green")}></span>
        <Typography variant="body2">Normal Operations</Typography>
      </div>
      <div style={styles.statusItem}>
        <span style={styles.dot("#F5CC61")}></span>
        <Typography variant="body2">Degraded Operations</Typography>
      </div>
      <div style={styles.statusItem}>
        <span style={styles.dot("#D42828")}></span>
        <Typography variant="body2">Outage</Typography>
      </div>
      <div style={styles.statusItem}>
        <span style={styles.dot("#3CA0C3")}></span>
        <Typography variant="body2">Under Maintenance</Typography>
      </div>{" "}
      <div style={styles.statusItem}>
        <span style={styles.pulsingDot}></span>
        <Typography variant="body2">Loading</Typography>
      </div>
    </Box>
  );
}
