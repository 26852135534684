import axios from "axios";

import { AxiosInstance, AxiosRequestConfig } from "axios";
import { getAuth } from "firebase/auth";

export const getCurrentUserAuthorization = async (): Promise<string> => {
  const currentUser = getAuth().currentUser;
  if (currentUser) {
    const token = await currentUser.getIdToken(true);
    return token;
  } else {
    throw new Error("No user is logged in");
  }
};

const getAuthHeader = async (): Promise<AxiosRequestConfig> => {
  try {
    const token = await getCurrentUserAuthorization();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } catch (error) {
    throw new Error("Error getting the auth token");
  }
};

const sequalizerUrl = process.env.REACT_APP_SEQUALIZER_API_URL;

export const sequalizerApi: AxiosInstance = axios.create({
  baseURL: sequalizerUrl,
  headers: { "Content-Type": "application/json" },
  validateStatus: function (status) {
    return status === 200;
  },
});

export const sequalizerPost = async (path: string, data: object) => {
  const authHeader = await getAuthHeader();
  return sequalizerApi.post(path, data, authHeader);
};

export const retrieverApi = axios.create({
  baseURL: process.env.REACT_APP_RETRIEVER_API_URL,
  headers: { "Content-Type": "application/json" },
});

export const retrieverPost = async (path: string, data: object) => {
  const authHeader = await getAuthHeader();
  return retrieverApi.post(path, data, authHeader);
};
