import { View } from "@instructure/ui";
import { MutableRefObject } from "react";
import { Doowii } from "../../../../../../../../search/Doowii";
import { BotTextArea } from "./BotTextArea";
import { BubbleArrow } from "./BubbleArrow";
import { UserTextArea } from "./UserTextArea";

interface TextAreaProps {
  index: number;
  isUser: boolean;
  text: string;
  loading: boolean;
  selected?: boolean;
  first?: boolean;
  single: boolean;
  doowii: MutableRefObject<Doowii | null>;
  onSelectChat?: () => void;
  setQuery?: (value: string) => void;
  followUpQuestions?: string[];
}

export const TextArea = ({
  index,
  isUser,
  text,
  loading,
  selected,
  first,
  onSelectChat,
  setQuery,
  followUpQuestions,
  single,
  doowii,
}: TextAreaProps) => {
  const content = isUser ? (
    <UserTextArea text={text} loading={loading} selected={selected} onSelectChat={onSelectChat} />
  ) : (
    <BotTextArea
      index={index}
      text={text}
      loading={selected && loading}
      first={first}
      setQuery={setQuery}
      followUpQuestions={followUpQuestions}
      single={single}
      doowii={doowii}
      onSelectChat={onSelectChat}
    />
  );

  return (
    <View position="relative">
      {content}
      <BubbleArrow isUser={isUser} selected={selected} />
    </View>
  );
};
