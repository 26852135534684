import { FC, ReactNode, createContext, useContext, useEffect, useState, useCallback } from "react";
import { PreRenderCharts } from "./PreRenderCharts";
import { Pin } from "../pinboard/pinboard.i";
import { ChartContextI, ChartType, TableColumns } from "./chart.i";
import { useChatData } from "../chat";
import { availableCharts } from "../../helpers";
import { Portal } from "@instructure/ui";
import { handleDownloadPNGFunc } from "../../helpers/exportPNG";
import { jsPDF } from "jspdf";
import { generatePDF } from "../../helpers/exportPDF";
import { useLingui } from "@lingui/react";

const MAX_TABLE_ROWS = 50;

const initialState: ChartContextI = {
  selectedColumns: {},
  setSelectedColumns: (selectedColumns: TableColumns) => {},
  charts: [],
  setCharts: (charts: ChartType[]) => {},
  clearCharts: () => {},
  chartInstances: [],
  setChartInstances: (instances: ChartContextI["chartInstances"]) => {},
  exportChart: async () => {},
};

const ChartContext = createContext(initialState);

export function useChart() {
  return useContext(ChartContext);
}

export interface PDFExportOptions {
  type: "PDF";
  pins: Pin[];
  baseURL: string;
  fileName?: string;
  onComplete?: () => void;
  onError?: (error: Error) => void;
}

export interface PNGExportOptions {
  type: "PNG";
  pinId: string;
  pins: Pin[];
  onComplete?: () => void;
  onError?: (error: Error) => void;
}

export type ExportOptions = PDFExportOptions | PNGExportOptions;

export const ChartProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedColumns, setSelectedColumns] = useState<TableColumns>({});
  const [charts, setCharts] = useState<ChartType[]>([]);
  const [chartInstances, setChartInstances] = useState<ChartContextI["chartInstances"]>([]);
  const [isPreRendering, setIsPreRendering] = useState(false);
  const [exportConfig, setExportConfig] = useState<ExportOptions | null>(null);
  const { dataMap, fetchSummaryFromPin, currentResult } = useChatData();
  const { _ } = useLingui();

  const clearCharts = () => {
    setSelectedColumns({});
    setCharts([]);
    setChartInstances([]);
  };

  useEffect(() => {
    clearCharts();
  }, [currentResult?.id]);

  useEffect(() => {
    let charts = [];
    charts = availableCharts(selectedColumns);
    setCharts(charts);
  }, [selectedColumns]);

  const handleExport = useCallback(async () => {
    if (!exportConfig) return;

    try {
      if (exportConfig.type === "PDF") {
        const doc = new jsPDF();

        for (let i = 0; i < exportConfig.pins.length; i++) {
          const pinResult = exportConfig.pins[i];
          const chartInstance = chartInstances.find((c) => c.id === pinResult.id);
          const resultObject = dataMap?.[pinResult.id];
          const chatSummary = await fetchSummaryFromPin(pinResult);

          if (i > 0) doc.addPage();

          await generatePDF({
            doc,
            result: pinResult,
            chartInstance,
            baseURL: exportConfig.baseURL,
            pageData: {
              // Limit the number of rows to 50 to prevent unusably large PDFs
              rows: Object.values(resultObject.rows).slice(0, MAX_TABLE_ROWS),
              pageInfo: resultObject.pageInfo,
            },
            chatSummary,
            _,
          });
        }

        doc.save(exportConfig.fileName || `${currentResult?.title}.pdf`);
      } else if (exportConfig.type === "PNG") {
        const chartInstance = chartInstances.find((c) => c.id === exportConfig.pinId);
        if (chartInstance) {
          handleDownloadPNGFunc({
            chart: chartInstance.chart,
            _,
          });
        }
      }

      exportConfig.onComplete?.();
    } catch (error) {
      exportConfig.onError?.(error as Error);
    } finally {
      setIsPreRendering(false);
      setExportConfig(null);
    }
  }, [exportConfig, chartInstances]);

  const exportChart = useCallback((options: ExportOptions): Promise<void> => {
    return new Promise((resolve, reject) => {
      setExportConfig({
        ...options,
        onComplete: () => {
          options.onComplete?.();
          resolve();
        },
        onError: (error) => {
          options.onError?.(error);
          reject(error);
        },
      });
      setIsPreRendering(true);
    });
  }, []);

  const value = {
    selectedColumns,
    setSelectedColumns,
    charts,
    setCharts,
    clearCharts,
    chartInstances,
    setChartInstances,
    exportChart,
  };

  return (
    <ChartContext.Provider value={{ ...value, exportChart }}>
      {children}
      <Portal open={isPreRendering && Boolean(exportConfig)}>
        {isPreRendering && exportConfig && (
          <PreRenderCharts pins={exportConfig.pins} onComplete={handleExport} />
        )}
      </Portal>
    </ChartContext.Provider>
  );
};
