import { HighchartsWithAccessibility, sharedChartOptions } from "../helpers";
import HighchartsReact from "highcharts-react-official";
import { memo, useRef, useEffect } from "react";
import { CHART_DATA_LIMIT } from "../../../Constants/constants";
import { Loading } from "../../LoadingSpinner/Loading";
import { ChartErrorState } from "../ChartErrorState/ChartErrorState";
import ChartWarning from "../ChartWarning/ChartWarning";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";
import { ChartHProps, ChartData } from "../chart.i";

interface ScatterChartProps extends ChartHProps {
  data: ChartData & {
    xColumn: string;
    yColumn: string;
    series: Highcharts.SeriesOptionsType[];
  };
}

const ScatterChartH = ({
  data,
  height,
  width,
  chartConfig,
  loading,
  error,
  totalRows,
  title,
  onChartInstance,
}: ScatterChartProps) => {
  const { _ } = useLingui();
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      const cleanup = onChartInstance(chartRef.current.chart);
      return cleanup;
    }
  }, [onChartInstance]);

  // Identify which columns to use for x and y axes
  if (loading || !chartConfig || !chartConfig.columns || !data) {
    return <Loading />;
  }
  if (error) {
    return <ChartErrorState />;
  }

  const { xColumn, yColumn, series } = data;

  const options: HighchartsWithAccessibility.Options = {
    ...sharedChartOptions,
    chart: {
      ...sharedChartOptions.chart,
      type: "scatter" as const,
      height,
      width,
    },
    title: {
      ...sharedChartOptions.title,
      text: title,
    },
    xAxis: {
      title: {
        text: xColumn ? xColumn : _(msg`X-Axis Data`),
      },
      crosshair: chartConfig.x_axis_crosshair ?? true,
    },
    yAxis: {
      title: {
        text: yColumn ? yColumn : _(msg`Y-Axis Data`),
      },
      crosshair: chartConfig.y_axis_crosshair ?? true,
    },
    legend: {
      layout: chartConfig.legend_layout || "vertical",
      align: "right",
      verticalAlign: chartConfig.legend_vertical_align || "top",
    },
    series,
    credits: {
      enabled: false,
    },
    tooltip: {
      headerFormat: "<b>{series.name}</b><br>",
      // eslint-disable-next-line lingui/no-unlocalized-strings
      pointFormat: `${xColumn}: {point.x}<br>${yColumn}: {point.y} `,
      enabled: chartConfig.tooltip ?? true,
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={HighchartsWithAccessibility} options={options} ref={chartRef} />
      {totalRows > CHART_DATA_LIMIT && <ChartWarning />}
    </div>
  );
};

const MemoizedScatterChartH = memo(ScatterChartH, (prevProps, nextProps) => {
  return (
    prevProps.data === nextProps.data &&
    prevProps.height === nextProps.height &&
    prevProps.width === nextProps.width &&
    prevProps.chartConfig === nextProps.chartConfig &&
    prevProps.totalRows === nextProps.totalRows &&
    prevProps.onChartInstance === nextProps.onChartInstance
  );
});

export default MemoizedScatterChartH;
