import CircularProgress from "@mui/material/CircularProgress";

import * as s from "./Loading.s";

export const Loading = () => {
  return (
    <s.Loading>
      <CircularProgress />
    </s.Loading>
  );
};
