import { Trans } from "@lingui/macro";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";

export const ErrorPage = () => {
  useEffect(() => {
    Sentry.withScope((scope) => {
      scope.setTag("error_type", "UI Crash");
      Sentry.captureException("Error page rendered");
    });
  }, []);

  return (
    <div>
      <Trans>Oops.. You've hit an error page!..</Trans>
    </div>
  );
};
