export const PlusIcon = ({ fill = "#425F6A" }: { fill?: string }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 6.25H0V7.75H6.25V14H7.75V7.75H14V6.25H7.75V0H6.25V6.25Z"
      fill={fill}
    />
  </svg>
);
