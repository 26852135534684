import {
  IconArrowOpenDownLine,
  IconArrowOpenEndLine,
  Link,
  Text,
  ToggleDetails,
} from "@instructure/ui";
import { Trans } from "@lingui/macro";
import { Skeleton } from "@mui/material";
import * as Sentry from "@sentry/react";
import { MutableRefObject } from "react";
import { useAuth } from "../../../../../../../../../context/auth";
import { useChatData } from "../../../../../../../../../context/chat";
import { useUI } from "../../../../../../../../../context/ui";
import { getRecentMessage } from "../../../../../../../../../helpers/getRecentMessage";
import { Doowii } from "../../../../../../../../../search/Doowii";
import { QuestionTypeEnum } from "../../../../../../../../../search/Doowii.i";

interface SuggestionsProps {
  doowii: MutableRefObject<Doowii | null>;
}

export const Suggestions = ({ doowii }: SuggestionsProps) => {
  const { allResults, answer, dynamicCache, loading } = useChatData();
  const { userDocument } = useAuth();
  const { model } = useUI();

  const pending = !dynamicCache || dynamicCache.length === 0;

  const handleClick = (suggestion: string) => {
    Sentry.withScope((scope) => {
      scope.setTag("log-tag", "SuggestedQuestionClicked");
      scope.setTag("user_id", userDocument?.email);
      Sentry.captureMessage(
        `User: ${userDocument?.email} clicked suggested question: ${suggestion}`
      );
    });

    doowii.current.chat({
      query: suggestion,
      index: allResults.length,
      model: model,
      recent_messages: getRecentMessage({ allResults, answer }),
      questionType: QuestionTypeEnum.SUGGESTION,
    });
  };

  return (
    <div className="flex flex-col rounded-bl-lg rounded-br-lg border-t border-[#C7CDD1] bg-white p-3">
      <ToggleDetails
        summary={<Trans>Suggestions:</Trans>}
        size="small"
        themeOverride={{
          fontWeight: 700,
          iconMargin: "10px",
        }}
        icon={() => (
          <IconArrowOpenEndLine
            size="small"
            themeOverride={{
              sizeSmall: "12px",
            }}
          />
        )}
        iconExpanded={() => (
          <IconArrowOpenDownLine
            size="small"
            themeOverride={{
              sizeSmall: "12px",
            }}
          />
        )}
        defaultExpanded
      >
        <div className="ml-2 flex flex-col">
          {pending && (
            <>
              <Skeleton variant="text" width="80%" height={25} />
              <Skeleton variant="text" width="80%" height={25} />
              <Skeleton variant="text" width="80%" height={25} />
            </>
          )}
          {!pending &&
            dynamicCache.map((suggestion, i) => (
              <Link
                isWithinText={false}
                key={i}
                forceButtonRole
                onClick={() => {
                  handleClick(suggestion);
                }}
                disabled={loading}
                className="ml-[9px]"
                data-testid="suggestion-link"
              >
                <span>
                  <Text size="small" weight="bold" className="text-start">
                    {suggestion}
                  </Text>
                </span>
              </Link>
            ))}
        </div>
      </ToggleDetails>
    </div>
  );
};
