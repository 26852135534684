import { Spinner, Text } from "@instructure/ui";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC } from "react";

export const Loader: FC<{ message: string }> = ({ message }) => {
  const { _ } = useLingui();
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="m-auto flex max-w-[450px] flex-1 flex-col items-center justify-center gap-5 text-center">
        <Spinner renderTitle={_(msg`Loading`)} size="large" margin="0 0 0 medium" />
        <Text>{message}</Text>
      </div>
    </div>
  );
};
