import cx from "clsx";
import { FC, ReactNode, useState } from "react";

const CloseIcon: FC<{ onClick: any; className: any }> = ({ onClick, className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.42579 21.1095H0V4H1.42579V21.1095ZM10.6646 20.188L3.03093 12.5543L10.6646 4.92205L11.6726 5.93008L5.75988 11.8428H24V13.2672H5.75988L11.6726 19.1799L10.6646 20.188Z"
      fill="white"
    />
  </svg>
);

interface CanvasSkeletonProps {
  children: ReactNode;
}

export const CanvasSkeleton: FC<CanvasSkeletonProps> = ({ children }) => {
  const [menuExpanded, setMenuExpanded] = useState(true);

  return (
    <div className="flex h-screen">
      <div
        className={`hidden shrink-0 cursor-pointer items-end justify-center bg-[#394B59] pb-2 md:flex ${cx(
          {
            "w-[84px]": menuExpanded,
            "w-[54px]": !menuExpanded,
          }
        )}`}
      >
        <CloseIcon
          className={cx({
            "rotate-180": !menuExpanded,
          })}
          onClick={() => setMenuExpanded(!menuExpanded)}
        />
      </div>
      <div className="flex max-h-full flex-1 flex-col">{children}</div>
    </div>
  );
};
