import { IconButton, IconMoreLine, Menu, Tooltip } from "@instructure/ui";
import {
  IconCheckMarkSolid,
  IconEditLine,
  IconEndLine,
  IconShareLine,
  IconTrashLine,
} from "@instructure/ui-icons";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ParentDocTypeEnum } from "../../../../../api/retriever.i";
import { CHAT_VIEW, SINGLE_METRIC } from "../../../../../Constants/constants";
import { PINBOARD_UPGRADES } from "../../../../../Constants/features";
import { useAuth } from "../../../../../context";
import { useAlert } from "../../../../../context/alert";
import { useChart } from "../../../../../context/chart";
import { usePinboard } from "../../../../../context/pinboard";
import { useUI } from "../../../../../context/ui";
import { downloadCSV } from "../../../../../helpers/downloadCSV";
import { withSentry } from "../../../../../helpers/wrapper";
import useBreakpoint from "../../../../../hooks/useBreakpoint";
import { Analytics } from "../../../../../services/analytics/Analytics";

interface ActionButtonsProps {
  value: string;
  pinResult: any;
  readOnly: boolean;
  setDeleteChartModalOpen: (value: boolean) => void;
  setReadOnly: (value: boolean) => void;
  updateTitle: (pinboardId: string, pinId: string, title: string) => Promise<any>;
  setValue: (value: string) => void;
}

export const ActionButtons = ({
  value,
  setValue,
  readOnly,
  setReadOnly,
  setDeleteChartModalOpen,
  updateTitle,
  pinResult,
}: ActionButtonsProps) => {
  const { _ } = useLingui();
  const { view } = useUI();
  const { currBoardId } = usePinboard();
  const { featureFlags, baseURL } = useAuth();
  const { exportChart } = useChart();
  const { isChatDesktop } = useBreakpoint();
  const { alertPromise } = useAlert();

  const handleEndClick = () => {
    setReadOnly(true);
    setValue(pinResult?.title);
  };

  const handleCheckClick: any = withSentry(async () => {
    view === CHAT_VIEW
      ? await (updateTitle as any)(value)
      : await updateTitle(currBoardId!, pinResult?.id, value);

    setReadOnly(true);
  });

  const handleDownloadPDF = withSentry(() => {
    alertPromise({
      promise: () =>
        exportChart({
          pins: [pinResult],
          baseURL,
          fileName: `${pinResult.title}.pdf`,
          type: "PDF",
          onComplete: () => {
            Analytics.track("Downloaded Pin PDF");
          },
        }),
      loading: _(msg`Preparing Pinboard PDF...`),
      success: _(msg`Pinboard PDF prepared successfully`),
      error: _(msg`Pinboard PDF preparation failed`),
    });
  });

  const handleDownloadCSV = withSentry(() => {
    alertPromise({
      promise: () =>
        downloadCSV({
          doc_id: pinResult?.id,
          parent_doc_id: currBoardId!,
          parent_doc_type: ParentDocTypeEnum.PINBOARD,
          filename: `${pinResult?.title}.csv`,
        }),
      loading: _(msg`Preparing CSV...`),
      success: _(msg`CSV prepared successfully`),
      error: _(msg`CSV preparation failed`),
    });
  });

  const handleDownloadPNG = withSentry(() => {
    alertPromise({
      promise: () =>
        exportChart({
          pins: [pinResult],
          type: "PNG",
          pinId: pinResult.id,
          onComplete: () => {
            Analytics.track("Downloaded Pin PNG");
          },
        }),
      loading: _(msg`Preparing Pinboard PNG...`),
      success: _(msg`Pinboard PNG prepared successfully`),
      error: _(msg`PNG preparation failed`),
    });
  });

  return (
    <>
      {featureFlags.includes(PINBOARD_UPGRADES) && (
        <Tooltip renderTip={<Trans>Share</Trans>}>
          <IconButton
            margin="x-small"
            withBackground={false}
            withBorder={false}
            onClick={() => {}}
            screenReaderLabel={_(msg`Share`)}
          >
            <IconShareLine />
          </IconButton>
        </Tooltip>
      )}
      {readOnly && (
        <Tooltip renderTip={<Trans>Edit name</Trans>}>
          <IconButton
            margin="x-small"
            withBackground={false}
            withBorder={false}
            onClick={() => setReadOnly(false)}
            screenReaderLabel={_(msg`Edit name`)}
          >
            <IconEditLine />
          </IconButton>
        </Tooltip>
      )}
      {!readOnly && (
        <>
          <Tooltip renderTip={<Trans>Confirm</Trans>}>
            <IconButton
              margin="xx-small"
              withBackground={false}
              withBorder={false}
              onClick={handleCheckClick}
              screenReaderLabel={_(msg`Submit new name`)}
            >
              <IconCheckMarkSolid color="success" />
            </IconButton>
          </Tooltip>
          <Tooltip renderTip={<Trans>Cancel</Trans>}>
            <IconButton
              margin="xx-small"
              withBackground={false}
              withBorder={false}
              onClick={handleEndClick}
              screenReaderLabel={_(msg`Cancel edit name`)}
            >
              <IconEndLine color="error" />
            </IconButton>
          </Tooltip>
        </>
      )}

      <Tooltip renderTip={<Trans>Remove Chart from Pinboard</Trans>}>
        <IconButton
          margin="xx-small"
          withBackground={false}
          withBorder={false}
          onClick={() => setDeleteChartModalOpen(true)}
          screenReaderLabel={_(msg`Remove Pin`)}
        >
          <IconTrashLine />
        </IconButton>
      </Tooltip>

      {isChatDesktop && (
        <Menu
          trigger={
            <Tooltip renderTip={<Trans>More Options</Trans>}>
              <IconButton
                withBackground={false}
                withBorder={false}
                screenReaderLabel={_(msg`Download options`)}
              >
                <IconMoreLine />
              </IconButton>
            </Tooltip>
          }
        >
          <Menu.Group label={_(msg`Download as`)}>
            <Menu.Separator />
            <Menu.Item onSelect={handleDownloadCSV}>
              <Trans>Comma Separated values (.csv)</Trans>
            </Menu.Item>
            <Menu.Item
              onSelect={handleDownloadPNG}
              disabled={
                pinResult?.visualisation === "TABLE" || pinResult?.visualisation === SINGLE_METRIC
              }
            >
              <Trans>PNG image (.png)</Trans>
            </Menu.Item>
            <Menu.Item onSelect={handleDownloadPDF}>
              <Trans>PDF Document (.pdf)</Trans>
            </Menu.Item>
          </Menu.Group>
        </Menu>
      )}
    </>
  );
};
