import * as Sentry from "@sentry/react";
import { get } from "./base";
import { operations } from "../../types/WebserverInterfaces.gen";

type FeatureFlagsResponse =
  operations["provisioned_flags_api_feature_flags_get"]["responses"]["200"]["content"]["application/json"];

export const fetchFeatureFlags = async (): Promise<FeatureFlagsResponse | undefined> => {
  try {
    const response = await get("/feature_flags");
    return response.data;
  } catch (error) {
    console.error("Error fetching feature flags", error);

    Sentry.withScope((scope) => {
      scope.setLevel("warning");
      Sentry.captureException(error);
    });
  }
};
