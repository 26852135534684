export enum ViewEnum {
  FAVORITE_VIEW = "favorite",
  CHAT_VIEW = "chat",
  PINBOARD_VIEW = "pinboard",
  USER_MANAGEMENT_VIEW = "USER_MANAGEMENT",
  SETTINGS_VIEW = "SETTINGS",
  INTEGRATIONS_VIEW = "INTEGRATIONS",
  QUERY_MODEL = "Query",
  PREDICT_MODEL = "Predict",
  CANVAS_USAGE = "CANVAS_USAGE"
}

export interface UIContextI {
  view: ViewEnum;
  model: ViewEnum;
  chatDrawerOpen: boolean;
  searchBarOpen: boolean;
  setView: (view: ViewEnum) => void;
  setModel: (model: ViewEnum) => void;
  setChatDrawerOpen: (open: boolean) => void;
  setSearchBarOpen: (open: boolean) => void;
  ui: "canvas" | "core";
  availableDataModalOpen: boolean;
  setAvailableDataModalOpen: (open: boolean) => void;
  aboutModalOpen: boolean;
  setAboutModalOpen: (open: boolean) => void;
  tipsModalOpen: boolean;
  setTipsModalOpen: (open: boolean) => void;

}
