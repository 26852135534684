import { IconButton } from "@instructure/ui";
import { IconXSolid } from "@instructure/ui-icons/es/IconXSolid";
import { MutableRefObject } from "react";
import { Doowii } from "../../../../../../search/Doowii";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export default function CancelButton({ doowii }: { doowii: MutableRefObject<Doowii | null> }) {
  const { _ } = useLingui();
  return (
    <IconButton
      shape="circle"
      screenReaderLabel={_(msg`Cancel the query`)}
      withBackground={false}
      withBorder={false}
      cursor="pointer"
      margin="0 0"
      size="small"
      onClick={() => {
        doowii.current.cancel_query();
      }}
    >
      <IconXSolid />
    </IconButton>
  );
}
