export const ChatBubbleIcon = ({ fill = "#425F6A" }: { fill?: string }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ display: "flex", margin: "auto" }}
  >
    <g clipPath="url(#clip0_442_23764)">
      <path
        d="M16.2 0H1.8C0.81 0 0 0.81 0 1.8V18L3.6 14.4H16.2C17.19 14.4 18 13.59 18 12.6V1.8C18 0.81 17.19 0 16.2 0ZM15.3 12.6H3.6L1.8 14.4V2.7C1.8 2.205 2.205 1.8 2.7 1.8H15.3C15.795 1.8 16.2 2.205 16.2 2.7V11.7C16.2 12.195 15.795 12.6 15.3 12.6Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_442_23764">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
