export const capitalize = (str) => {
  if (!str) return;

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const fixUserName = (userDocument) => {
  const firstName = userDocument?.firstName ? capitalize(userDocument.firstName) : "";
  const lastName = userDocument?.lastName ? capitalize(userDocument.lastName) : "";

  return {
    firstName,
    lastName,
  };
};